import { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import { mapListsData } from '../../../config/formFields/magazzino/magazzino-dispositivi';
import { ILicenzaForm, licenzeFormFields, tipologieProdotti } from '../../../config/formFields/magazzino/magazzino-licenze';
import { IFormField, ISelectOption } from '../../../helpers/interfaces/generic';
import { ILicenzaBody } from '../../../helpers/interfaces/magazzino/magazzino-licenze';
import { IMagazzinoProdotto } from '../../../helpers/interfaces/magazzino/magazzino-prodotti';
import licenzeService from '../../../services/api/magazzino/magazzino-licenze.service';
import magazzinoLicenzeProdottiService from '../../../services/api/magazzino/magazzino-licenze-prodotti.service';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import DatePickerForm from '../../../common/DatePickerForm';

type State = {
    prodotti: IMagazzinoProdotto[],
    tipologie: ISelectOption[],
    formFields: IFormField[],
    loading: boolean,
    formInitialValues: ILicenzaForm
};

export default class MagazzinoNuovaLicenza extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            prodotti: [],
            tipologie: tipologieProdotti,
            formFields: [],
            loading: false,
            formInitialValues: {} as ILicenzaForm
        }

        this.handleAddLicenza = this.handleAddLicenza.bind(this)
        this.setValidations = this.setValidations.bind(this)
    }

    // method: initializing the form values
    initFormValues(): ILicenzaForm {
        return {
            tipologia_nome: "",
            prodotto_nome: "",
            identificativo: "n.d.",
            data_fine: "",
            data_inizio: "",
            note: "n.d.",
            prezzo: "n.d."
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento licenza in corso...' });

        // calling the needed data
        await magazzinoLicenzeProdottiService.getAll()
            .then(
                prodotti => {
                    this.setState(
                        {
                            prodotti
                        },
                        () => {
                            const tipologie = this.state.tipologie
                            const prodotti = mapListsData(this.state.prodotti)

                            this.setState(
                                {
                                    formFields: licenzeFormFields(tipologie, prodotti),
                                    formInitialValues: this.initFormValues()
                                },
                                () => {
                                    EventBus.dispatch("hideLoader");
                                }
                            );
                        }
                    )
                },
                error => {
                    EventBus.dispatch("hideLoader");
                }
            );
    }

    // method: handling add licence actions
    async handleAddLicenza(values: ILicenzaForm) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        const tipologia = this.state.tipologie.find(tipologia => tipologia.value === values.tipologia_nome)
        const prodotto = this.state.prodotti.find(prodotto => prodotto.nome === values.prodotto_nome)

        let licenzaBody: ILicenzaBody = {
            tipologia: tipologia ? Number(tipologia.key) : 0,
            prodotto_id: prodotto ? prodotto.id : 0,
            identificativo: values.identificativo,
            data_inizio: values.data_inizio,
            data_fine: values.data_fine,
            note: values.note,
            prezzo: values.prezzo,
        }

        // handle add call
        await licenzeService.add(licenzaBody).then(
            response => {
                this.setState(
                    {
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");
                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );

                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }


    render() {
        const { loading, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof ILicenzaForm])

        return <div className="container py-3">
            {
                formFields.length > 0 && <React.Fragment>

                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleAddLicenza}
                    >
                        {({ setFieldValue }) => (
                            <Form className="card">
                                <div className="card-body">
                                    <div className='col-12 pb-2'>
                                        <h3>Nuova licenza</h3>
                                    </div>

                                    {formFields.map(
                                        (field: IFormField, key) => {
                                            return <div className="mb-3" key={key}>
                                                <div className='col-12'>
                                                    <label className="form-label">{field.label}</label>
                                                    {
                                                        field.type === 'select' ?
                                                            <Field as={field.type} name={field.name} className={field.class}>
                                                                <option key={''} value={''}>---</option>
                                                                {field.values?.map((item: ISelectOption) => {
                                                                    return <option key={item.key} value={item.value}>{item.value}</option>
                                                                })}
                                                            </Field>
                                                            :
                                                            (
                                                                field.type === 'date' ?
                                                                    <DatePickerForm name={field.name} className={field.class} onChange={(state) => setFieldValue(field.name, state.toSend)} />
                                                                    : <Field name={field.name} type={field.type} className={field.class} />
                                                            )
                                                    }
                                                    <ErrorMessage
                                                        name={field.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    )}

                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {
                                                loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                            }
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </React.Fragment>
            }

        </div>

    }
}