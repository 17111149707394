import { Component } from "react";
import PersonService from "../../services/api/person.service";
import EventBus from "../../common/EventBus";
import InfoAccessoComponent from './dettaglio/InfoAccessoComponent';
import InfoDipendenteComponent from './dettaglio/InfoDipendenteComponent';
import AnagraficaComponent from './dettaglio/AnagraficaComponent';
import ContrattiComponent from './dettaglio/ContrattiComponent';
import DocumentiComponent from './dettaglio/DocumentiComponent';
import AdempimentiComponent from './dettaglio/AdempimentiComponent';
import RuoliComponent from './dettaglio/RuoliComponent';
import AttestatiComponent from './dettaglio/AttestatiComponent';
import CommesseComponent from './dettaglio/CommesseComponent';
import ServiziComponent from './dettaglio/ServiziComponent';
import LicenzeComponent from './dettaglio/LicenzeComponent';
import DispositiviComponent from './dettaglio/DispositiviComponent';
import { Tab } from "../../common/TabComponent";
import InfoVariabileComponent from "./dettaglio/InfoVariabileComponent";
import PermessiFerieComponent from "./dettaglio/PermessiFerieComponent/PermessiFerieComponent";
import NoteComponent from "./dettaglio/NoteComponent";

type Props = {
  history: {
    push(url: string): void;
  }
  match: {
    params: {
      id: string
    };
  }
};

type State = {
  data: any,
  note: string,
}

export default class DettaglioPersonale extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: null,
      note: ''
    }
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento Profilo in corso...' });
    const userId = this.props.match.params.id;

    if (!parseInt(userId)) {
      this.props.history.push('/');
    }

    await PersonService.getEmpoyee(parseInt(userId)).then(
      (response) => {
        this.setState({
          data: response,
          note: response.note
        });
      },
    );

    EventBus.dispatch("hideLoader");
  }

  render() {
    const { data } = this.state;

    return (
      <div className="custom-container">
        {data &&
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="col-12 col-md-6 col-lg-3">
              <InfoDipendenteComponent person={data} />
              <div className="mt-2">
                <InfoAccessoComponent person={data} />
              </div>
              <div className="mt-2">
                <InfoVariabileComponent contrattoAttivo={data.contratto_attivo[0]} />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-9">
              <div className="card mt-2 ms-md-2 mt-md-0">
                <div className="card-body">
                  <Tab
                    useState={true}
                    tabs={[
                      {
                        id: 'anagrafica',
                        label: 'Anagrafica',
                        content: <AnagraficaComponent anagrafica={data.anagrafica} />,
                        isActive: true,
                      },
                      {
                        id: 'contratti',
                        label: 'Contratti',
                        content: <ContrattiComponent
                          history={this.props.history}
                          contratti={data.contratti}
                          contrattoAttivo={data.contratto_attivo ? data.contratto_attivo[0] : []}
                          personId={data.id}
                        />,
                      },
                      {
                        id: 'documenti',
                        label: 'Documenti',
                        content: <DocumentiComponent documenti={data.documenti} personId={data.id} />,
                      },
                      {
                        id: 'adempimenti',
                        label: 'Adempimenti',
                        content: <AdempimentiComponent adempimenti={data.adempimenti} personId={data.id} />,
                      },
                      {
                        id: 'ruoli',
                        label: 'Ruoli',
                        content: <RuoliComponent ruoli={data.ruoliaziendali} personId={data.id} />,
                      },
                      {
                        id: 'attestati',
                        label: 'Attestati',
                        content: <AttestatiComponent attestati={data.attestati} personId={data.id} />,
                      },
                      {
                        id: 'commesse',
                        label: 'Commesse',
                        content: <CommesseComponent commesse={data.personjoborders} personId={data.id} />,
                      },
                      {
                        id: 'dispositivi',
                        label: 'Dispositivi',
                        content: <DispositiviComponent
                          dispositivi={data.dispositivi}
                          personId={data.id}
                          history={this.props.history}
                          match={this.props.match}
                        />,
                      },
                      {
                        id: 'licenze',
                        label: 'Licenze',
                        content: <LicenzeComponent licenze={data.licenze} personId={data.id} />,
                      },
                      {
                        id: 'servizi',
                        label: 'Servizi',
                        content: <ServiziComponent servizi={data.servizi} personId={data.id} />,
                      },
                      {
                        id: 'permessi-ferie',
                        label: 'Permessi e ferie',
                        content: <PermessiFerieComponent personId={data.id} />,
                      },
                      {
                        id: 'note',
                        label: 'Note',
                        content: <NoteComponent
                          note={this.state.note}
                          personId={data.id}
                          updateCallback={async (note: string) => {
                            this.setState({note})
                          }}
                        />,
                      },
                    ]} />
                </div>
              </div>
            </div>
          </div>}
      </div>
    );
  }
}