import React, { Component } from 'react';
import AutoNumeric from 'autonumeric';
import DOMPurify from 'dompurify';
import $ from 'jquery';

declare var bootstrap: any;

type Props = {
    id?: string;
    title: string;
    subtitle?: string;
    content: any;
    type: string;
    showModal: boolean;
    closeCallback: CallableFunction;
};

export default class Modal extends Component<Props> {
    innerRefs: Array<{ [key: string]: any }>;
    existFile: boolean;
    modal: any;

    constructor(props: Props) {
        super(props);
        this.innerRefs = [];
        this.existFile = false;

        this.modal = null;
    }

    componentDidUpdate(prevProps: any) {
        const { id } = this.props;
        if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
            const modalAction = document.getElementById('modal-' + (id ? id : 'action'));
            if (modalAction) {
                const _self = this;
                modalAction.addEventListener('hidden.bs.modal', function () {
                    _self.props.closeCallback();
                });

                modalAction.addEventListener('shown.bs.modal', function () {
                    if (!AutoNumeric.isManagedByAutoNumeric(modalAction)) {
                        const autoNumericOptionsEuro: any = {
                            digitGroupSeparator: '.',
                            decimalCharacter: ',',
                            decimalCharacterAlternative: '.',
                            currencySymbol: '€',
                        };
                        AutoNumeric.multiple('#modal-' + (id ? id : 'action') + ' .autoNumeric', autoNumericOptionsEuro);
                    }
                });

                this.modal = new bootstrap.Modal(modalAction);
                this.modal.show();
            }
        }
    }

    componentWillUnmount(): void {
        $('.modal-backdrop').remove();
    }

    render() {
        const { showModal, title, subtitle, content, type } = this.props;

        return showModal && <React.Fragment>
            <div className="modal modal-blur fade" id={"modal-" + (this.props.id ? this.props.id : "action")} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className={"modal-body py-3 gb-" + type}>
                            <h3>{title}</h3>
                            <div className="text-muted">{subtitle}</div>
                            {type === 'html' ? <div className='border border-1 p-3' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></div> : content}
                        </div>
                        <div className="modal-footer">
                            <div className="w-100">
                                <div className="row justify-content-end">
                                    <div className="col">
                                        <button type="button" className="btn" data-bs-dismiss="modal">
                                            Chiudi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}
