import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormFields } from "../../helpers/interfaces/generic";

export function getRefusePermessiFerieFormFields(): Array<FormFields> {
    return [
      {
        'label': 'Nota',
        'name': 'nota',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      }
    ];
  }