import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormFields } from "../../helpers/interfaces/generic";

export function getEditContattiFormFields(clienti: any): Array<FormFields> {
  return [
    {
      'label': 'Cliente',
      'name': 'clientiId',
      'type': 'select',
      'values': clienti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'E-mail 1',
      'name': 'email1',
      'type': 'email',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'E-mail 2',
      'name': 'email2',
      'type': 'email',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Cognome',
      'name': 'lastName',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Cellulare',
      'name': 'phoneCell',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Note',
      'name': 'notes',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ]
}

export function getAddContattiFormFields(clienti: any): Array<FormFields> {
  return [
    {
      'label': 'Cliente',
      'name': 'clientiId',
      'type': 'select',
      'values': clienti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'E-mail 1',
      'name': 'email1',
      'type': 'email',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'E-mail 2',
      'name': 'email2',
      'type': 'email',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Cognome',
      'name': 'lastName',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Cellulare',
      'name': 'phoneCell',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Note',
      'name': 'notes',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}