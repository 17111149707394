import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../common/EventBus";
import { checkFestivo, getNomeMese, getMesiAbbreviati, getGiornoSettimana, giorniLavorativi } from './../../helpers/calendar';
import RendicontazioniService from "../../services/api/rendicontazioni.service";
import PersonService from "../../services/api/person.service";
import CausaliService from "../../services/api/causali.service";
import { reportings } from "../organico/rendicontazione/helper/app";
import DocumentiService from "../../services/api/documenti.service";
import { openBase64NewTab } from '../../common/Base64';
import ModalForm from '../../common/ModalForm';
import { getModalRendicontazioneFields } from "../../config/formFields";
import authService from "../../services/auth.service";
import 'font-awesome/css/font-awesome.min.css';
import { FormFields } from "../../helpers/interfaces/generic";
import { PersonJoborder } from "../../helpers/interfaces/person";
import moment from "moment";
import 'moment/locale/it';
import '../organico/rendicontazione/style.css';
import { FormikProps, FormikValues } from "formik";

const $ = require('jquery');

type Props = {
    match: any
};

type State = {
    currentUser: any,
    year: number,
    month: number,
    giorniSettimana: any,
    giorniMese: any,
    giorniVuoti: any,
    oreMese: number,
    showModal: boolean,
    modalTitle: string,
    modalType: string,
    formFields: Array<FormFields>,
    formInitialValues: { [key: string]: any },
    dropdownYears: boolean
}

export default class RendicontazioneDipendente extends Component<Props, State> {
    settimana: string[];
    apiSubmit: any;
    nowYear: number;
    nowMonth: number;
    nowDay: number;

    constructor(props: Props) {
        super(props);

        this.settimana = [
            'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'
        ];

        this.state = {
            currentUser: null,
            year: 0,
            month: 0,
            giorniVuoti: [],
            giorniSettimana: [],
            giorniMese: [],
            oreMese: 0,
            showModal: false,
            modalTitle: 'Rendicontazione',
            modalType: 'add',
            formFields: [],
            formInitialValues: {},
            dropdownYears: false,
        }

        this.apiSubmit = null; //AttestatiService.personAdd;

        const date = new Date();
        this.nowYear = date.getFullYear();
        this.nowMonth = date.getMonth() + 1;
        this.nowDay = date.getDate();
        reportings.setPageView('dipendente');
    }

    mapCommesse = (joborders: any, parent: any = null) => {
        let results: any[] = [];

        let subRes: any = [];
        joborders.forEach((item: any) => {
            const lvl = typeof item.lvl == 'undefined' ? 0 : item.lvl;
            const char = "----".repeat(lvl);
            const name = typeof item.name == 'undefined' ? item.jobordername : item.name;
            results.push({ id: item.joborderid, value: char + ' ' + name, parent: parent });
            if (typeof item.childs !== 'undefined' && item.childs.length > 0) {

                subRes = this.mapCommesse(item.childs, { id: item.joborderid, value: name });

                subRes.forEach((item: any) => {
                    results.push(item);
                });
            }
        });
        return results;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const currentUser: any = await authService.getCurrentUser();

        if (currentUser) {
            this.setState({ currentUser });
            const personId = currentUser.id;

            await this.getMonth(personId, this.nowYear, this.nowMonth);

            $('#addFile').click(() => {
                $('#templateFile').clone().attr('id', '').removeClass('hide').appendTo('#documents');
                $('.removeAttachment').off('click');
                $('.removeAttachment').click((event: any) => {
                    $(event.target).parent().remove();
                });
            });
        }

        EventBus.dispatch("hideLoader");
    }

    async getMonth(personId: number, year: number, month: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const data = moment(year + '-' + month + '-01');
        const giornoSettimana = data.day();
        const giorni1 = this.settimana.slice(0, giornoSettimana);
        const giorni2 = this.settimana.slice(giornoSettimana, this.settimana.length);

        const daysInMonth = data.daysInMonth();

        const dettaglio: any = RendicontazioniService.getDipendenteRendicontazione(year, month);
        const permessiStudioEsami = RendicontazioniService.getPermessiStudioEsami();

        const workHours: number | false = await RendicontazioniService.getDipendenteWorkHours(year, month)
        .then((data: {
                workhours: number;
            }) => {
            return data.workhours;
        })
        .catch(() => {
            return false;
        });

        await Promise.all([dettaglio, permessiStudioEsami])
        .then(
            data => {
                let dettaglio = { ...data[0], permessiStudioEsami: { ...data[1] } }

                if (!Array.isArray(data[0])) {
                    this.setState({
                        year,
                        month,
                        giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                        giorniSettimana: [...giorni2, ...giorni1],
                        giorniMese: Array.from(Array(daysInMonth).keys()),
                        oreMese: workHours !== false ? workHours : giorniLavorativi(
                            year,
                            month,
                            (dettaglio.firstday ?? 0),
                            (dettaglio.lastday ?? daysInMonth)
                        ) * 8
                    }, () => {
                        reportings.load(dettaglio, this);
                        if (data[0] && data[0].length === 0) {
                            reportings.setPerson(this.state.currentUser.id, this.state.currentUser.name, '', '', '', 'E', data[1]);
                            reportings.setCurrentDate(String(year), String(month));
                        }
                        EventBus.dispatch("hideLoader");
                    });
                } else {
                    this.setState({
                        year,
                        month,
                        giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                        giorniSettimana: [...giorni2, ...giorni1],
                        giorniMese: Array.from(Array(daysInMonth).keys()),
                        oreMese: workHours !== false ? workHours : giorniLavorativi(year, month, 0, daysInMonth) * 8
                    }, () => {
                        reportings.load(dettaglio, this);
                        if (data[0] && data[0].length === 0) {
                            reportings.setPerson(this.state.currentUser.id, this.state.currentUser.name, '', '', '', 'E', data[1]);
                            reportings.setCurrentDate(String(year), String(month));
                        }
                        EventBus.dispatch("hideLoader");
                    })
                }
            },
        )
    }

    async previousMonth() {
        const { currentUser, month, year } = this.state

        const personId: number | null = currentUser?.id ?? null;
        let newMonth: number = month - 1;
        let newYear: number = year;
        if (newMonth < 1) {
            newMonth = 12;
            newYear = year - 1;
        }

        if (personId) {
            this.getMonth(personId, newYear, newMonth);
        }
    }

    async nextMonth() {
        const { currentUser, month, year } = this.state

        const personId: number | null = currentUser?.id ?? null;
        let newMonth: number = month + 1;
        let newYear: number = year;
        if (newMonth > 12) {
            newMonth = 1;
            newYear = year + 1;
        }

        if (personId) {
            this.getMonth(personId, newYear, newMonth);
        }
    }

    async downloadDocument(idDocumento: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento del documento in corso...' });

        const documento: any = await DocumentiService.downloadDipendente(idDocumento);

        if (typeof documento.body !== 'undefined') {
            openBase64NewTab(documento);
        }

        EventBus.dispatch("hideLoader");
    }

    async deleteDocument(idDocumento: number) {
        EventBus.dispatch("showLoader", { text: 'Cancellazione del documento in corso...' });

        await DocumentiService.deleteDipendente(idDocumento).then(
            async () => {
                const year = Number(reportings.getCurrentDate().year);
                const month = Number(reportings.getCurrentDate().month);
                const personId = Number(reportings.getPersonId());

                await this.getMonth(personId, year, month);

                Swal.fire({
                    title: 'Cancellazione avvenuta con successo',
                    icon: 'success',
                    timer: 3000,
                });
            },
            () => Swal.fire({
                title: 'Si è verificato un errore imprevisto durante la cancellazione del tuo documento',
                icon: 'error',
                timer: 3000,
            })
        );

        EventBus.dispatch("hideLoader");
    }

    async editRendicontazione(status: string) {
        const year = Number(reportings.getCurrentDate().year);
        const month = Number(reportings.getCurrentDate().month);
        const personId = Number(reportings.getPersonId());
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

        const edit = await reportings.editRendicontazione(status).then(
            async () => {
                EventBus.dispatch("hideLoader");

                if (status === 'C') reportings.setStato({ code: 'C', name: 'Confermato' });
                $('#documents > input').value = '';
                $('#documents > div') && $('#documents > div').remove();
                await this.getMonth(personId, year, month);
            },
            () => {
                EventBus.dispatch("hideLoader");
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la conferma del mese',
                    icon: 'error',
                    timer: 3000,
                })
            }
        );

        return edit;
    }

    openModalRendicontazione = async (year: number, month: number, day: number) => {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        reportings.setSelectedDay(year, month, day);
        const person = await PersonService.getProfile();

        if (typeof person.error !== 'undefined') {
            Swal.fire(
                'errore generico',
                '',
                'error'
            );
        }

        const causali_all = await CausaliService.getAllDipendente();
        const causali: any = causali_all.map((item: any) => { return { key: item.id + '||' + item.name, value: item.name } });

        const date = moment(year.toString() + '/' + month.toString() + '/' + day.toString());

        const joborders_active = this.mapCommesse(person.personjoborders.active);
        let joborders_expired: Array<{ id: number, value: string, parent: Array<any> | null }> = [];
        person.personjoborders.expired.length > 0 && person.personjoborders.expired.forEach((joborder: PersonJoborder) => {
            const startDate = moment(joborder.startdate);
            const endDate = moment(joborder.enddate);
            if (date.isBetween(startDate, endDate, 'days', "[]")) {
                joborders_expired.push({ id: joborder.joborderid, value: joborder.name, parent: null });
            }
        });

        const joborders_all = joborders_active.concat(joborders_expired);
        const joborders: any = joborders_all.map((item: any) => { return { key: item.id + '||' + item.value, value: item.value } });

        const days = $('#rendicontazione .day.selected').length;

        let modalTitle = 'Rendicontazione (';
        if (days === 0) {
            modalTitle += ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        } else if (days === 1) {
            modalTitle += days + ' giorno';
        } else {
            modalTitle += days + ' giorni';
        }
        modalTitle += ')'

        this.setState({ formFields: getModalRendicontazioneFields(joborders, causali), formInitialValues: { ore: '00', minuti: '00' } }, () => {
            this.setState({ showModal: true, modalTitle, modalType: 'edit' });
        });

        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    submitModal = (formData: FormikValues, formik: FormikProps<FormikValues>) => {
        const ore = Number(formData.ore);
        const minuti = Number(formData.minuti);
        const { tipologia } = formData;

        if (typeof formData[tipologia] == 'undefined') {
            formik.setErrors({ [tipologia]: "required" });
        }

        if (ore === 0 && minuti === 0) {
            formik.setErrors({ minuti: "hours or minutes are required" });
            return false;
        } else {
            reportings.newRendicontazione(formData);
        }

        return true;
    }

    openCloseYears = () => this.setState({ dropdownYears: !this.state.dropdownYears });

    render() {
        const { giorniSettimana, giorniMese, giorniVuoti, year, month, oreMese, showModal, modalTitle, modalType, formFields, formInitialValues } = this.state;
        const statoColor: any = { 'O': { color: 'bg-cyan', text: 'Aperto' }, 'C': { color: 'bg-azure', text: 'Confermato' }, 'A': { color: 'bg-green', text: 'Accettato' }, 'R': { color: 'bg-red', text: 'Rifiutato' } };

        const statorendicontazione = {
            color: reportings.getStatoCode() ? statoColor[reportings.getStatoCode()].color : '',
            text: reportings.getStatoCode() ? statoColor[reportings.getStatoCode()].text : '',
        }

        const yearsClass = `dropdown-menu${this.state.dropdownYears ? " show" : ""}`;
        const years = [];
        for (let i = this.nowYear; i >= 2016; i--) {
            years.push(i);
        }

        const permessiStudioEsami = reportings.getPersonPermessiStudioEsami()

        return <React.Fragment>
            <div className="card" id="rendicontazione">
                <ModalForm showModal={showModal} title={modalTitle} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} submitCallback={this.submitModal} closeCallback={this.closeModal.bind(this)} />
                {
                    permessiStudioEsami && <div className='card-body d-flex flex-column flex-lg-row align-items-center justify-content-start'>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi esami degli ultimi 3 anni</strong> <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overesame ? 'text-danger' : 'text-success')}>{permessiStudioEsami.oreesame} / {permessiStudioEsami.limiteesame}:00 ore</h3>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi studio dell'ultimo anno</strong> <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overstudio ? 'text-danger' : 'text-success')}>{permessiStudioEsami.orestudio} / {permessiStudioEsami.limitestudio}:00 ore</h3>
                        </div>
                    </div>
                }
                <div className="card-body">
                    <div className="col-12 d-flex justify-content-between align-items-center px-2 pt-2 pb-4">
                        <div className="col-3">
                            <span id="stato" className={"badge " + statorendicontazione.color}>{statorendicontazione.text}</span>
                        </div>
                        <div className="col-5 d-flex justify-content-between align-items-center">
                            <button className="btn btn-outline-primary me-3" onClick={() => year > 2016 || (year === 2016 && month > 1) ? this.previousMonth() : false}>
                                {
                                    year > 2016 || (year === 2016 && month > 1) ? <i style={{ fontSize: "18px" }} className="fa fa-angle-left" aria-hidden="true" /> : ''
                                }
                            </button>
                            <div className="d-flex flex-column flex-lg-row align-items-center">
                                <h2 className="mb-0 me-1">Rendicontazione</h2>
                                <div id="yearsDropdown" className="nav-item dropdown" onClick={this.openCloseYears}>
                                    <button className="d-flex btn-link border0" type="button" id="dropdownYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <h2 className="mb-0">{getNomeMese(month - 1)} {year}</h2>
                                    </button>
                                    <div className={yearsClass} aria-labelledby="dropdownYears">
                                        {
                                            years.map((value: number, index: number) => {
                                                return <span key={index} className={"dropdown-item" + (year === value ? " active" : "")} onClick={() => { value !== year && this.getMonth(this.state.currentUser.id, value, month) }}>
                                                    {getNomeMese(month - 1)} {value}
                                                </span>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                            <button className="btn btn-outline-primary ms-3" onClick={() => this.nextMonth()}>
                                <i style={{ fontSize: "18px" }} className="fa fa-angle-right" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <button className="btn btn-link" onClick={() => this.getMonth(this.state.currentUser.id, this.nowYear, this.nowMonth)}>Vai al mese corrente</button>
                        </div>
                    </div>
                    <div id="mesi" className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <div className="row">
                                    {getMesiAbbreviati().map((mese, index) => {
                                        return <div key={index} className="col-12 col-sm-2 col-lg-1 text-center">
                                            <button
                                                className={"btn-block radius12" + (month === (index + 1) ? " active" : "")}
                                                onClick={() => month !== (index + 1) && this.state.currentUser?.id && this.getMonth(this.state.currentUser.id, year, (index + 1))}
                                            >
                                                {mese}
                                            </button>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <header>
                                    <div className="row d-sm-flex text-white days-week">
                                        {giorniSettimana.map((giorno: string) => {
                                            return <h5 key={'g' + giorno} className="col-sm p-1 text-center">{giorno}</h5>
                                        })}
                                    </div>
                                </header>
                                <div className="row border border-right-0 border-bottom-0 newbg">
                                    {giorniMese.map((index: string) => {
                                        const giorno: number = Number(index + 1);
                                        const festivo: boolean = checkFestivo(year, month, giorno);
                                        const corrente: boolean = year === this.nowYear && month === this.nowMonth && giorno === this.nowDay;
                                        const classNameContainer: string[] = ["position-relative", "d" + giorno, (corrente ? 'corrente' : ''), "day col-sm p-2 border border-left-0 border-top-0", (festivo ? 'festivo' : '')];
                                        const classNameDay: string[] = ["day-number col-2", (festivo ? 'festivo' : '')];
                                        return <React.Fragment key={"d" + giorno}><div id={"d" + giorno} className={classNameContainer.join(" ")}>
                                            <h5 className="row align-items-center">
                                                {(['O', 'R'].includes(reportings.getStatoCode()) || typeof reportings.getStatoCode() === 'undefined') &&
                                                    <div onClick={() => this.openModalRendicontazione(year, month, giorno)}>
                                                        <i style={{ fontSize: "18px" }} className="fa fa-plus-circle icon text-primary" aria-hidden="true"></i>
                                                    </div>
                                                }
                                                <span className={classNameDay.join(" ")}>{giorno}</span>
                                                <small className="col d-sm-none text-center text-muted">{getGiornoSettimana(year, month, giorno)}</small>
                                                <span className="col-1"></span>
                                            </h5>
                                            <div id={"giorno-" + giorno}></div>
                                            <span id={"totale-giorno-" + giorno} className="totale-ore-giorno"></span>
                                        </div>
                                            {giorno % 7 === 0 ? <div className="w-100"></div> : ''}
                                        </React.Fragment>
                                        //return <div>{giorno+1}</div>
                                    })}
                                    {giorniVuoti.map((index: string) => {
                                        return <div key={"subD" + index} className="day col-sm p-2 border border-left-0 border-top-0 text-truncate ">
                                            <h5 className="row align-items-center">
                                                <span className="date col-1"></span>
                                                <small className="col d-sm-none text-center text-muted"></small>
                                                <span className="col-1"></span>
                                            </h5>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="mt-3 card-body">
                                <label><strong>Documenti Allegati:</strong></label>
                                <div>
                                    {reportings.getAttachements()?.length > 0 ?
                                        reportings.getAttachements().map((item: any, index: number) => {
                                            return <div key={index}>
                                                <i onClick={() => this.downloadDocument(item.id)} className="fa fa-download action-icon downloadAttachment" aria-hidden="true" />
                                                {['O', 'R'].includes(reportings.getStatoCode()) && (
                                                    <React.Fragment>
                                                        {' '}<i onClick={(event) => {
                                                            Swal.fire({
                                                                title: 'Sei sicuro di voler cancellare questo documento?',
                                                                icon: 'question',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#85d630',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Conferma',
                                                                cancelButtonText: 'Annulla'
                                                            }).then(async (result) => {
                                                                if (result.isConfirmed) await this.deleteDocument(item.id)
                                                            })
                                                        }} className="fa fa-trash action-icon removeAttachment" aria-hidden="true" />
                                                    </React.Fragment>
                                                )}
                                                {' '}{item.path}
                                            </div>
                                        })
                                        : <div><strong>Nessuno</strong></div>
                                    }
                                </div>
                                <br />
                                {['O', 'R'].includes(reportings.getStatoCode()) && (
                                    <React.Fragment>
                                        <div id="documents">
                                            <input type="file" name="attachment" className="attachment" />
                                        </div>
                                        <button type="button" className="btn btn-primary mt-2" id="addFile">Aggiungi Documento</button>
                                        <div id="templateFile" className="hide">
                                            <input type="file" name="attachment" className="attachment" />
                                            <i className="fa fa-trash action-icon removeAttachment" aria-hidden="true" />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title totale-ore-title">Totale ore <span id="totale-ore">00:00</span> / <span className="ore_mese">{oreMese}:00</span></h3>
                                    </div>
                                    <div className="card-body p-0 mt-0">
                                        <div>
                                            <table id="rendicontazione-totale-ore" className="table nodefault">
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 offset-md-1 mt-4">
                            {['O', 'R'].includes(reportings.getStatoCode()) &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="btn btn-block btn-warning" onClick={() => {
                                            if (reportings.checkTotaleOreMaggioreOreMese() !== -1)
                                                Swal.fire({
                                                    title: 'Sei sicuro di voler confermare la rendicontazione?',
                                                    text: reportings.checkTotaleOreStraordinario() !== 0 ? 'Note: risultano registrate ' + reportings.checkTotaleOreStraordinario() + ' ore di straordinario totali' : '',
                                                    icon: 'question',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#85d630',
                                                    cancelButtonColor: '#d33',
                                                    confirmButtonText: 'Conferma',
                                                    cancelButtonText: 'Annulla'
                                                }).then(async (result) => {
                                                    if (result.isConfirmed)
                                                        this.editRendicontazione('C').then(
                                                            () => Swal.fire({
                                                                title: 'Mese confermato con successo',
                                                                icon: 'success',
                                                                timer: 3000,
                                                            }),
                                                            () => Swal.fire({
                                                                title: 'Si è verificato un errore imprevisto durante la conferma del mese',
                                                                icon: 'error',
                                                                timer: 3000,
                                                            })
                                                        )
                                                });
                                            else
                                                Swal.fire({
                                                    title: 'Le ore rendicontate non corrispondono alle ore mensili previste!',
                                                    icon: 'error',
                                                    timer: 3000,
                                                })
                                        }}>
                                            Conferma mese
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn btn-block btn-success" onClick={() => {
                                            Swal.fire({
                                                title: 'Sei sicuro di voler salvare la rendicontazione?',
                                                icon: 'question',
                                                showCancelButton: true,
                                                confirmButtonColor: '#85d630',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Salva',
                                                cancelButtonText: 'Annulla'
                                            }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                    this.editRendicontazione('').then(
                                                        () => Swal.fire({
                                                            title: 'Mese salvato con successo',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                            timer: 3000,
                                                        }),
                                                        () => Swal.fire({
                                                            title: 'Si è verificato un errore imprevisto durante il salvataggio del mese',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                            timer: 3000,
                                                        })
                                                    )
                                                }
                                            });
                                        }}>
                                            Salva mese
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}