import { statusColors } from "../../helpers/settings/status-settings";

export const listPermessiFerieColumns = [
    { title: "Azioni", data: "id" },
    { title: "Dipendente", data: "dipendente" },
    { title: "Dal", data: "inizio" },
    { title: "Al", data: "fine" }
];

export const listPermessiStudioEsamiColumns = [
    { title: "Azioni", data: "id" },
    { title: "Disponibilità", data: "status" },
    { title: "Dipendente", data: "dipendente" },
    { title: "Ore già rendicontate", data: "ore" },
    { title: "Ore totali", data: "limit" },
    { title: "Dal", data: "inizio" },
    { title: "Al", data: "fine" }
]

export const listPermessiFerieConfermateColumns = [
    { title: "Azioni", data: "id" },
    { title: "Dipendente", data: "dipendente" },
    { title: "Causale", data: "causale.nome" },
    { title: "Dal", data: "inizio" },
    { title: "Al", data: "fine" }
];

export function getListPermessiFerieColumnDefs() {
    return [
        {
            targets: 0,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                const confirm = `<a class="confirm_btn custom-icon btn btn-outline-success rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-check" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + trash + confirm + '</div>';
            },
        },
    ];
}

export function getListPermessiFerieConfermateColumnDefs() {
    return [
        {
            targets: 0,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + trash + '</div>';
            },
        },
    ];
}

export function getListPermessiStudioEsamiColumnDefs() {
    return [
        {
            targets: 0,
            render: (data: any, type: any, row: any) => {
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                const confirm = `<a class="confirm_btn custom-icon btn btn-outline-success rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-check" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + trash + confirm + '</div>';
            },
        },
        {
            targets: 1,
            render: (data: any, type: any, row: any) => {
                switch (row.status) {
                    case true:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }
            },
        },
        {
            targets: 4,
            render: (data: any, type: any, row: any) => {
                return row.limit + ':00'
            },
        }
    ];
}