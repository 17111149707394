export const listAdempimentiColumns = [
    { "title": "Azioni", "data": "id" },
    { "title": "Nome", "data": "nome" }
];

export function getListAdempimentiColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
    ];
}