import { AxiosResponse } from 'axios';
import {
  NomineInviaRequest,
  NomineInviaResponse,
  NomineInviaSollecitoRequest,
  NomineInviaSollecitoResponse,
  NomineList,
  NomineSollecitiList
} from '../../helpers/interfaces/nomine';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class NomineService {
  async getList(): Promise<Array<NomineList>> {
    return await axios.get(API_URL + 'datore/privacy')
    .then((response: AxiosResponse<Array<NomineList>>) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async send(data: NomineInviaRequest): Promise<NomineInviaResponse> {
    return await axios.post(API_URL + 'datore/privacy/invio', data)
    .then((response: any) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async getSollecitiList(): Promise<Array<NomineSollecitiList>> {
    return await axios.get(API_URL + 'datore/privacy/sollecito/list')
    .then((response: AxiosResponse<Array<NomineSollecitiList>>) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async sendSollecito(data: NomineInviaSollecitoRequest): Promise<NomineInviaSollecitoResponse> {
    return await axios.post(API_URL + 'datore/privacy/sollecito/send', data)
    .then((response: any) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }
}

export default new NomineService();