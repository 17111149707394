import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import authService from "../services/auth.service";

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

type State = {
  content: string;
}

export default class Home extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      content: ""
    };

    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      const roles = currentUser.roles
      roles.forEach(
        (role: { name: string, code: string }) => {
          if (role.code !== "dipendente") {
            this.props.history.push("/amministrazione");
          } else {
            this.props.history.push("/dipendente/home");
          }
        }
      )
    }
  }

  render() {
    return (
      <div className="custom-container">
        <header className="jumbotron">
          <h3>{this.state.content}</h3>
        </header>
      </div>
    );
  }
}