import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getContrattoFormFields(tipologiacontratti: any, tutors: any, finecontratti: any): Array<FormFields> {
  return [
    {
      label: 'Tipologia Contratto',
      name: 'tipo_contratto_id',
      type: 'select',
      values: tipologiacontratti,
      class: 'form-select',
      validation: Yup.number().required("Campo obbligatorio")
    }, {
      label: 'Mansione',
      name: 'mansione',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Sede Operativa',
      name: 'sede_operativa',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Sede Contrattuale',
      name: 'sede_contrattuale',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Data Assunzione',
      name: 'data_assunzione',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().required("Campo obbligatorio")
    },
    {
      label: 'Data Fine Rapporto',
      name: 'data_fine_rapporto',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().notRequired()
    },
    {
      label: 'Ufficio',
      name: 'ufficio',
      type: 'select',
      values: [{ key: 0, value: 'NO' }, { key: 1, value: 'SI' }],
      class: 'form-select',
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Tutors',
      name: 'tutor_id',
      type: 'select',
      values: tutors,
      class: 'form-select',
      validation: Yup.number().positive().integer().notRequired()
    },
    {
      label: 'Numero Proroghe',
      name: 'numero_proroghe',
      type: 'text',
      class: 'form-control',
      validation: Yup.number().notRequired()
    },
    {
      label: 'CCNL',
      name: 'ccnl',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Protetta',
      name: 'protetta',
      type: 'select',
      values: [{ key: 0, value: 'NO' }, { key: 1, value: 'SI' }],
      class: 'form-select',
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Ticket',
      name: 'ticket',
      type: 'select',
      values: [{ key: 0, value: 'NO' }, { key: 1, value: 'SI' }],
      class: 'form-select',
      validation: Yup.number().notRequired()
    },
    {
      label: 'Valore Ticket',
      name: 'ticket_value',
      type: 'text',
      class: 'form-control',
      validation: Yup.number().notRequired()
    },
    {
      label: 'Fine Contratto',
      name: 'fine_contratto_id',
      type: 'select',
      values: finecontratti,
      class: 'form-select',
      validation: Yup.number().positive().integer().notRequired()
    },
    {
      label: 'Nota',
      name: 'nota',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().notRequired()
    },
    {
      label: '',
      name: 'person_id',
      type: 'hidden',
      class: 'form-control',
      validation: Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}