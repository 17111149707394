import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class AttestatiService {
  getAll() {
    return axios.get(API_URL + 'datore/attestati')
    .then(response => { return Array.isArray(response.data) ? response.data : []; })
    .catch((error) => { return Promise.reject(); });
  }
  dashboard() {
    return axios.get(API_URL + 'datore/attestati/dashboard')
    .then(response => { return typeof response.data == 'object' ? response.data : null; })
    .catch((error) => { return Promise.reject(); });;
  }
  get(id:any) {
    return axios.get(API_URL + 'datore/attestati/'+id)
    .then(response => { return typeof response.data == 'object' ? response.data : null; })
    .catch((error) => { return Promise.reject(); });;
  }
  put(id:any, data:any) {
    return axios.put(API_URL + 'datore/attestati/'+id, {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }
  add(data:any) {
    return axios.post(API_URL + 'datore/attestati', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }
  personAdd(data:any) {
    return axios.post(API_URL + 'datore/person/attestati', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }
  personDelete(id:any) {
    return axios.delete(API_URL + 'datore/person/attestati/' + id)
    .then(response => { return response.data; })
    .catch((error) => { return error.response.data; });
  }
  personGet(id:any) {
    return axios.get(API_URL + 'datore/person/attestati/'+id)
    .then(response => { return typeof response.data == 'object' ? response.data : null; })
    .catch((error) => { return Promise.reject(); });;
  }
  personPut(id:any, data:any) {
    return axios.put(API_URL + 'datore/person/attestati/'+id, {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }
}
export default new AttestatiService();