import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getAddAttestatiFormFields(attestati: any, personId: number): Array<FormFields> {
  return [
    {
      'label': 'Attestati',
      'name': 'attestati_id',
      'type': 'select',
      'values': attestati,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Data assegnazione',
      'name': 'data_assegnazione',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data scadenza',
      'name': 'data_scadenza',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'value': personId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}