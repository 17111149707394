import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class ReportsService {
  monthly(data: any) {
    return axios.post(API_URL + 'datore/report/mese', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  yearly(anno: any, personId: number) {
    return axios.get(API_URL + 'datore/report/anno/' + anno + '/' + personId)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  joborder(year: number, month: number, joborder: number) {
    let url = 'datore/report/commesse/anno/' + year;
    if (month) { url += '/mese/' + month; }
    if (joborder) { url += '/' + joborder; }

    return axios.get(API_URL + url)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  material(stato: any) {
    let url = 'datore/report/dispositivi';
    if (stato) { url += '/' + stato; }

    return axios.get(API_URL + url)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  registry(stato: any,) {
    let url = 'datore/report/anagrafica';
    if (stato) { url += '/' + stato; }

    return axios.get(API_URL + url)
      .then(response => { return response.data; })
      .catch((error) => { return null });
  }
  ral() {
    return axios.get(API_URL + 'datore/report/ral')
      .then(response => response.data)
      .catch(error => null)
  }
}
export default new ReportsService();