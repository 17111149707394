import { Profilo } from '../../helpers/interfaces/user';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class UserService {

  getProfile(): Promise<Profilo> {
    return axios.get(API_URL + "dipendente/profilo")
      .then((response: any) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

}

export default new UserService();
