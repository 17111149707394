import * as Yup from "yup";
import { FormFields } from "../../helpers/interfaces/generic";

export function getEditClienteFormFields(): Array<FormFields> {
  return [
    {
      'label': 'Ragione sociale',
      'name': 'ragionesociale',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Indirizzo',
      'name': 'indirizzo',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Telefono',
      'name': 'telefono',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}

export function getAddClienteFormFields(): Array<FormFields> {
    return [
      {
        'label': 'Ragione sociale',
        'name': 'ragionesociale',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      },
      {
        'label': 'Indirizzo',
        'name': 'indirizzo',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      },
      {
        'label': 'Telefono',
        'name': 'telefono',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      }
    ];
  }