import { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { history } from '../helpers/history';
import EventBus from "../common/EventBus";
import CssLoader from "../common/cssloader/CssLoader";
import "../App.css";
import NavigationBar from "../components/NavigationBar";
import AuthService from "../services/auth.service";
import route from './routes.json';
import navBar from './navbar.json';
import dipendenteNavbar from './dipendente-navbar.json'
import '@tabler/core/dist/css/tabler.min.css'
import '@tabler/core/dist/js/tabler.min.js'
import Breadcrumb from '../components/Breadcrumb';

// import components
import Home from '../pages/home';
import Profile from '../pages/profile';
import Login from '../pages/login';
import NotFound from '../pages/notfound';
import ElencoPersonale from '../pages/organico/elenco-personale';
import DettaglioPersonale from '../pages/organico/dettaglio-personale';
import NuovoPersonale from '../pages/organico/nuovo-personale';
import Tutor from '../pages/organico/tutor';
import Attestati from '../pages/organico/attestati';
import Clienti from '../pages/contratti/clienti';
import TipologiaContratti from '../pages/contratti/tipologia-contratti';
import FineContratti from '../pages/contratti/fine-contratti';
import Commesse from '../pages/contratti/commesse';
import Contatti from '../pages/contratti/contatti';
import PermessiFerie from '../pages/organico/permessi-ferie';
import PermessiFerieComponent from '../pages/organico/dettaglio/PermessiFerieComponent/PermessiFerieComponent';
import CalendarioComponent from '../pages/organico/dettaglio/PermessiFerieComponent/CalendarioComponent';
import DaValutareComponent from '../pages/organico/dettaglio/PermessiFerieComponent/DaValutareComponent';
import ConfermateComponent from '../pages/organico/dettaglio/PermessiFerieComponent/ConfermateComponent';
import StatoRendicontazione from '../pages/organico/rendicontazione/stato-rendicontazione';
import ElencoRendicontazione from '../pages/organico/rendicontazione/elenco';
import DettaglioRendicontazione from '../pages/organico/rendicontazione/dettaglio';
import OreSettimanali from '../pages/organico/ore-settimanali';
import Ruoli from '../pages/database/ruoli';
import TitoliStudio from '../pages/database/titoli-studio';
import Adempimenti from '../pages/database/adempimenti';
import Causali from '../pages/database/causali';
import Comuni from '../pages/database/comuni';
import BusinessUnit from '../pages/database/businessunit';
import Reports from '../pages/reports';
import Scadenzario from '../pages/scadenzario/scadenzario';
import ElencoGruppo from '../pages/gruppi/elenco-gruppi';
import NuovoGruppo from '../pages/gruppi/nuovo-gruppo';
import HomeDipendente from '../pages/dipendenti/home-dipendente';
import RendicontazioneDipendente from '../pages/dipendenti/rendicontazione';
import PermessiFerieDipendente from '../pages/dipendenti/permessi-ferie';
import SignDocument from '../pages/signDocument';
import MagazzinoHome from '../pages/magazzino/magazzino-home';
import MagazzinoDispositivi from '../pages/magazzino/dispositivi/magazzino-dispositivi';
import MagazzinoDettaglioDispositivo from '../pages/magazzino/dispositivi/magazzino-dettaglio-dispositivo';
import MagazzinoNuovoDispositivo from '../pages/magazzino/dispositivi/magazzino-nuovo-dispositivo';
import MagazzinoContratti from '../pages/magazzino/contratti/magazzino-contratti';
import MagazzinoNuovoContratto from '../pages/magazzino/contratti/magazzino-nuovo-contratto';
import MagazzinoDettaglioContratto from '../pages/magazzino/contratti/magazzino-dettaglio-contratto';
import MagazzinoServizi from '../pages/magazzino/servizi/magazzino-servizi';
import MagazzinoNuovoServizio from '../pages/magazzino/servizi/magazzino-nuovo-servizio';
import MagazzinoDettaglioServizio from '../pages/magazzino/servizi/magazzino-dettaglio-servizio';
import MagazzinoLicenze from '../pages/magazzino/licenze/magazzino-licenze';
import MagazzinoNuovaLicenza from '../pages/magazzino/licenze/magazzino-nuova-licenza';
import MagazzinoDettaglioLicenza from '../pages/magazzino/licenze/magazzino-dettaglio-licenza';
import MagazzinoProdotti from '../pages/magazzino/prodotti/magazzino-prodotti';
import MagazzinoNuovoProdotto from '../pages/magazzino/prodotti/magazzino-nuovo-prodotto';
import MagazzinoDettaglioProdotto from '../pages/magazzino/prodotti/magazzino-dettaglio-prodotto';
import MagazzinoStatoDispositivi from '../pages/magazzino/stato-dispositivi/magazzino-stato-dispositivi';
import MagazzinoNuovoStato from '../pages/magazzino/stato-dispositivi/magazzino-nuovo-stato';
import MagazzinoDettaglioStato from '../pages/magazzino/stato-dispositivi/magazzino-dettaglio-stato';
import MagazzinoTipologiaDispositivi from '../pages/magazzino/tipologie-dispositivi/magazzino-tipologia-dispositivi';
import MagazzinoNuovaTipologia from '../pages/magazzino/tipologie-dispositivi/magazzino-nuova-tipologia';
import MagazzinoDettaglioTipologia from '../pages/magazzino/tipologie-dispositivi/magazzino-dettaglio-tipologia';
import ChangePassword from '../pages/change-password';
import HomeAmministrazione from '../pages/home-amministrazione/home-amministrazione';
import ElencoNomine from '../pages/nomine/elenco-nomine';
import InviaNomina from '../pages/nomine/invia-nomine';
import ElencoSollecitiNomine from '../pages/nomine/elenco-solleciti';
import ElencoHRDocuments from '../pages/HRDocuments/elenco-documenti';
import InviaHRDocouments from '../pages/HRDocuments/invia-documento';
import ElencoSollecitiHRDocuments from '../pages/HRDocuments/elenco-solleciti';
import HomeAdmin from '../pages/admin/home-admin';
import ElencoUtenti from '../pages/admin/elenco-utenti';
import ElencoTipiUtente from '../pages/admin/elenco-tipi-utente';
import ElencoRotte from '../pages/admin/elenco-rotte';
import SincronizzaRotte from '../pages/admin/sincronizza-rotte';
import ElencoAutorizzazioni from '../pages/admin/elenco-autorizzazioni';
import Notifiche from '../pages/notifiche';
import authService from '../services/auth.service';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}

    render={props => {
      const currentUser = AuthService.getCurrentUser();

      if (rest.protected) {
        const routes = currentUser ? currentUser.routes : [];

        if (!currentUser) {
          return <Redirect to={'/login'} />;
        } else {
          if (rest.alias && !routes.includes(rest.alias))
            return <Redirect to={'/'} />;
        }
      }

      return <>
        {rest.protected && <Breadcrumb route={rest} />}
        <Component {...props} routeParams={rest} to={rest.to ? rest.to : '/'} />
      </>;
    }}
  />
);

class Routes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      loaderText: ''
    }

    this.data = route;

    navBar.forEach((item) => {
      item?.elements?.length > 0 && this.getNavbarElements(item.elements);

      this.data.push({
        component: "Redirect",
        name: item.name,
        path: item.path,
        protected: true,
        to: item.to,
      });
    });

    // Home Route
    this.data.push({
      component: "Home",
      path: "/",
      exact: false,
      protected: true,
      name: "home",
    });

    // Route not found
    this.data.push({
      component: "NotFound",
      path: "*",
      exact: false,
      protected: false,
      name: "not-found",
    });

    // add components
    this.components = {
      Home,
      HomeAmministrazione,
      HomeDipendente,
      Profile,
      Login,
      ChangePassword,
      NotFound,
      ElencoPersonale,
      DettaglioPersonale,
      PermessiFerieComponent,
      ConfermateComponent,
      DaValutareComponent,
      CalendarioComponent,
      NuovoPersonale,
      Tutor,
      Attestati,
      Ruoli,
      TitoliStudio,
      Adempimenti,
      Causali,
      Comuni,
      BusinessUnit,
      Clienti,
      TipologiaContratti,
      FineContratti,
      Commesse,
      Contatti,
      Reports,
      PermessiFerie,
      StatoRendicontazione,
      ElencoRendicontazione,
      DettaglioRendicontazione,
      OreSettimanali,
      ElencoGruppo,
      NuovoGruppo,
      Scadenzario,
      RendicontazioneDipendente,
      PermessiFerieDipendente,
      SignDocument,
      MagazzinoHome,
      MagazzinoDispositivi,
      MagazzinoDettaglioDispositivo,
      MagazzinoNuovoDispositivo,
      MagazzinoContratti,
      MagazzinoNuovoContratto,
      MagazzinoDettaglioContratto,
      MagazzinoServizi,
      MagazzinoNuovoServizio,
      MagazzinoDettaglioServizio,
      MagazzinoLicenze,
      MagazzinoNuovaLicenza,
      MagazzinoDettaglioLicenza,
      MagazzinoProdotti,
      MagazzinoNuovoProdotto,
      MagazzinoDettaglioProdotto,
      MagazzinoStatoDispositivi,
      MagazzinoNuovoStato,
      MagazzinoDettaglioStato,
      MagazzinoTipologiaDispositivi,
      MagazzinoNuovaTipologia,
      MagazzinoDettaglioTipologia,
      ElencoNomine,
      InviaNomina,
      ElencoSollecitiNomine,
      ElencoHRDocuments,
      InviaHRDocouments,
      ElencoSollecitiHRDocuments,
      HomeAdmin,
      ElencoUtenti,
      ElencoTipiUtente,
      ElencoRotte,
      SincronizzaRotte,
      ElencoAutorizzazioni,
      Notifiche,
      Redirect
    };

    EventBus.on("showLoader", this.showLoader);
    EventBus.on("hideLoader", this.hideLoader);
  }

  parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };


  componentDidMount() {
    history.listen(() => {
      const user = authService.getCurrentUser();

      if (user) {
        const decodedToken = this.parseJwt(user.token);

        if (decodedToken.exp * 1000 < Date.now()) {
          authService.logout();
        }
      }
    });
  }

  getNavbarElements = (elements) => {
    elements.forEach(item => {
      item?.elements?.length > 0 && this.getNavbarElements(item.elements);

      if (item.isDropend) {
        this.data.push({
          component: "Redirect",
          name: item.name,
          path: item.path,
          protected: true,
          to: item.to,
        });
      }
    });
  }

  showLoader = (data) => { this.setState({ showLoader: true, loaderText: data.detail.text }); }
  hideLoader = () => { this.setState({ showLoader: false }); }

  render() {
    const { showLoader, loaderText } = this.state;

    let isAdmin = false
    let currentUser = AuthService.getCurrentUser()
    currentUser?.roles?.forEach(role => {
      if (role.code !== 'dipendente')
        isAdmin = true
    })

    return <Router history={history}>
      {
        currentUser && <NavigationBar navBar={isAdmin ? navBar : dipendenteNavbar} />
      }
      <div className="container-fluid py-3" style={{ minHeight: "calc(100% - 112px)" }}>
        <CssLoader active={showLoader} text={loaderText} />
        <Switch>
          {this.data.map((page, key) => (
            <PrivateRoute key={key} {...page} component={this.components[page.component]} />
          ))}
        </Switch>
      </div>
      {
        currentUser ? <footer className="navbar navbar-dark bg-primary">
          <div className='d-flex align-items-center mx-auto'>
            <span>Powered by</span>
            <a href="http://www.cgmconsulting.it" target="blank">
              <img src="/assets/CGM-logo-bianco.svg" alt="logo" height="40px" />
            </a>
          </div>
        </footer> : ''
      }
    </Router>
  }
}

export default Routes;
