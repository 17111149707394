import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class ComuniService {

  getAll() {
    return axios.get(API_URL + 'datore/comune')
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getAllPatrono(): Promise<{ id: number, nome: string }[]> {
    return axios.get<{ id: number, nome: string }[]>(API_URL + 'datore/comune/patrono')
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  get(id: any) {
    return axios.get(API_URL + 'datore/comune/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }

  put(id: any, data: any) {
    return axios.put(API_URL + 'datore/comune/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  add(data: any) {
    return axios.post(API_URL + 'datore/comune', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

}
export default new ComuniService();