import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class VariabiliContrattoService {
  personDelete(id: any) {
    return axios.delete(API_URL + 'datore/person/variabilecontratti/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }
  personAdd(data: any) {
    return axios.post(API_URL + 'datore/person/variabilecontratti', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }
  personGet(id: any) {
    return axios.get(API_URL + 'datore/person/variabilecontratti/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }
  personPut(id: any, data: any) {
    return axios.put(API_URL + 'datore/person/variabilecontratti/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }
}
export default new VariabiliContrattoService();