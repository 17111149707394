import { generatePath } from "react-router";

export const listAttestatiColumns = [
    { "title": "Azioni", "data": "id" },
    { "title": "Nome", "data": "nome" },
    { "title": "Dipendenti", "data": "dipendentiCount" }
];

export function getListAttestatiColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;
            },
        },
    ];
}

export const AttestatiDashboardCompletoColumns = [
    { "title": "Azioni", "data": "person_id" },
    {
        "title": "Dipendente", "data": null, render: (data: any, type: any, row: any) => {
            return row.person_lastname + ' ' + row.person_name;
        }
    },
    { "title": "Cell", "data": "person_phone" },
    { "title": "E-mail", "data": "person_email" },
    { "title": "Sede contrattuale", "data": "sede_contrattuale" },
    { "title": "Tipo contratto", "data": "tipo_contratto" },
    { "title": "Tipo attestato", "data": "tipo_attestato" },
    { "title": "Data assegnazione", "data": "data_assegnazione" },
    { "title": "Data scadenza", "data": "data_scadenza" }
];

export function AttestatiDashboardCompletoColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const url = generatePath("/organico/dettaglio-personale/:id", {
                    id: data
                });
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" href="${url}">
                    <i style="font-size: 18px" class="fa fa-user" aria-hidden="true"></i>
                </a>`;
            },
        },
    ];
}

export const AttestatiDashboardScadenzaColumns = [
    { "title": "Azioni", "data": "id" },
    {
        "title": "Dipendente", "data": null, render: (data: any, type: any, row: any) => {
            return row.lastname + ' ' + row.name;
        }
    },
    { "title": "Attestato", "data": "attestato" },
    { "title": "Data assegnazione", "data": "data_assegnazione" },
    { "title": "Data scadenza", "data": "data_scadenza" }
];

export const AttestatiDashboardMancantiColumns = [
    { "title": "Azioni", "data": "person_id" },
    {
        "title": "Dipendente", "data": null, render: (data: any, type: any, row: any) => {
            return row.person_lastname + ' ' + row.person_name;
        }
    },
    { "title": "E-mail", "data": "person_email" },
    { "title": "Telefono", "data": "anagrafica.telefono" },
    { "title": "CF", "data": "anagrafica.cf" },
    { "title": "Data Nascita", "data": "anagrafica.data_nascita" },
    { "title": "Luogo Nascita", "data": "anagrafica.luogo_nascita" },
    { "title": "Provincia Nascita", "data": "anagrafica.provincia_nascita" },
    { "title": "Codice Catastale", "data": "anagrafica.codice_catastale_comune_nascita" },
    { "title": "Comune Residenza", "data": "anagrafica.comune_residenza" },
    { "title": "Provincia Residenza", "data": "anagrafica.provincia_residenza" },
    { "title": "CAP Residenza", "data": "anagrafica.cap_residenza" },
    { "title": "Indirizzo Residenza", "data": "anagrafica.indirizzo_residenza" },
    { "title": "Nazionalità", "data": "anagrafica.nazionalita" },
    { "title": "Titolo Studio", "data": "anagrafica.titolo_studio_nome" },
    { "title": "Permesso Soggiorno", "data": "anagrafica.permesso_soggiorno" },
    { "title": "Comune Domicilio", "data": "anagrafica.comune_domicilio" },
    { "title": "Provincia Domicilio", "data": "anagrafica.provincia_domicilio" },
    { "title": "CAP Domicilio", "data": "anagrafica.cap_domicilio" },
    { "title": "Indirizzo Domicilio", "data": "anagrafica.indirizzo_domicilio" }
];