import { generatePath } from "react-router-dom";
import { ListColumns } from "../../../helpers/interfaces/generic";
import { ILicenzaDaDisabilitare, IServizioDaDisabilitare } from "../../../helpers/interfaces/magazzino/magazzino-home";
import { statusColors } from "../../../helpers/settings/status-settings";

// table columns definitions
export const listServiziDaDisabilitareColumns: ListColumns[] = [
    { title: "Azioni", data: "person_id" },
    { title: "Stato", data: "status" },
    { title: "Dipendente", data: "person" },
    { title: "Servizio", data: "servizio" }
];

export const listLicenzeDaDisabilitareColumns: ListColumns[] = [
    { title: "Azioni", data: "person_id" },
    { title: "Stato", data: "status" },
    { title: "Dipendente", data: "person" },
    { title: "Licenza", data: "licenze" },
    { title: "Prodotto", data: "prodotto" },
    { title: "Produttore", data: "produttore" }
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export function getServiziDaDisabilitareColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IServizioDaDisabilitare) => {
                const url = generatePath("/organico/dettaglio-personale/:id/#servizi", { id: row.person_id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
        {
            targets: 1,
            render: (id: number, type: string, row: IServizioDaDisabilitare) => {
                switch (row.status.toLowerCase()) {
                    case "s":
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                }
            },
        },
    ];
}

export function getLicenzeDaDisabilitareColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: ILicenzaDaDisabilitare) => {
                const url = generatePath("/organico/dettaglio-personale/:id/#licenze", { id: row.person_id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
        {
            targets: 1,
            render: (id: number, type: string, row: ILicenzaDaDisabilitare) => {
                switch (row.status.toLowerCase()) {
                    case "s":
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                }
            },
        },
    ];
}