import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from 'react';
import { Component } from 'react'
import Swal from 'sweetalert2';
import DatePickerForm from '../../../common/DatePickerForm';
import EventBus from '../../../common/EventBus';
import { mapListsData } from '../../../config/formFields/magazzino/magazzino-dispositivi';
import { ILicenzaForm, licenzeFormFields, tipologieProdotti } from '../../../config/formFields/magazzino/magazzino-licenze';
import { IFormField, ISelectOption } from '../../../helpers/interfaces/generic';
import { ILicenzaBody, IMagazzinoLicenza } from '../../../helpers/interfaces/magazzino/magazzino-licenze';
import { IMagazzinoProdotto } from '../../../helpers/interfaces/magazzino/magazzino-prodotti';
import licenzeService from '../../../services/api/magazzino/magazzino-licenze.service';
import magazzinoLicenzeProdottiService from '../../../services/api/magazzino/magazzino-licenze-prodotti.service';
import * as Yup from 'yup';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    licenza: IMagazzinoLicenza,
    prodotti: IMagazzinoProdotto[],
    tipologie: ISelectOption[]
    formFields: IFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: ILicenzaForm
};

export default class MagazzinoDettaglioLicenza extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            licenza: {} as IMagazzinoLicenza,
            prodotti: [],
            tipologie: tipologieProdotti,
            formFields: [],
            loading: false,
            disabledForm: true,
            formInitialValues: {} as ILicenzaForm
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateLicenza = this.handleUpdateLicenza.bind(this);
    }

    // method: initializing the form values
    initFormValues(): ILicenzaForm {
        let tipologia = this.state.tipologie.find(tipologia => tipologia.key === this.state.licenza.tipologia)
        let prodotto = this.state.prodotti.find(prodotto => prodotto.id === this.state.licenza.prodotto_id)

        return {
            tipologia_nome: tipologia ? tipologia.value : "",
            prodotto_nome: prodotto ? prodotto.nome : "",
            identificativo: this.state.licenza.identificativo,
            data_inizio: this.state.licenza.data_inizio ? this.state.licenza.data_inizio : "",
            data_fine: this.state.licenza.data_fine ? this.state.licenza.data_fine : "",
            note: this.state.licenza.note,
            prezzo: this.state.licenza.prezzo
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento licenza in corso...' });

        const licenzaPromise = licenzeService.getOne(this.props.match.params.id)
        const prodottiPromise = magazzinoLicenzeProdottiService.getAll();

        // calling the needed data
        await Promise.all([licenzaPromise, prodottiPromise])
            .then(
                values => {
                    this.setState(
                        {
                            licenza: values[0],
                            prodotti: values[1]
                        },
                        () => {
                            const tipologie = this.state.tipologie
                            const prodotti = mapListsData(this.state.prodotti)

                            this.setState({
                                formFields: licenzeFormFields(tipologie, prodotti),
                                formInitialValues: this.initFormValues()
                            }, () => EventBus.dispatch("hideLoader"));
                        }
                    )
                },
                error => {
                    EventBus.dispatch("hideLoader");
                }
            );
    }

    // method: handling edit licence actions
    async handleUpdateLicenza(values: ILicenzaForm) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        const tipologia = this.state.tipologie.find(tipologia => tipologia.value === values.tipologia_nome)
        const prodotto = this.state.prodotti.find(prodotto => prodotto.nome === values.prodotto_nome)

        let licenzaBody: ILicenzaBody = {
            tipologia: tipologia ? Number(tipologia.key) : 0,
            prodotto_id: prodotto ? prodotto.id : 0,
            identificativo: values.identificativo,
            data_inizio: values.data_inizio,
            data_fine: values.data_fine,
            note: values.note,
            prezzo: values.prezzo,
        }

        // handle edit call
        await licenzeService.edit(this.props.match.params.id, licenzaBody).then(
            response => {
                this.setState(
                    {
                        licenza: response,
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");

                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );
                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof ILicenzaForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleUpdateLicenza}
                    >
                        {({ setFieldValue }) => (
                            <Form className="card">
                                <div className="card-body">
                                    <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                        <h3>Dettaglio licenza</h3>
                                        <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                            {
                                                disabledForm ?
                                                    <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                    <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                            }
                                        </button>
                                    </div>

                                    {formFields.map(
                                        (field: IFormField, key) => {
                                            return <div className="mb-3" key={key}>
                                                <div className='col-12'>
                                                    <label className="form-label">{field.label}</label>
                                                    {
                                                        field.type === 'select' ?
                                                            <Field as={field.type} name={field.name} className={field.class} disabled={disabledForm}>
                                                                <option key={''} value={''}>---</option>
                                                                {field.values?.map((item: ISelectOption) => {
                                                                    return <option key={item.key} value={item.value}>{item.value}</option>
                                                                })}
                                                            </Field>
                                                            :
                                                            (
                                                                field.type === 'date' ?
                                                                    <DatePickerForm name={field.name} className={field.class} readValue={String(field.value)} disabled={disabledForm} onChange={(state) => setFieldValue(field.name, state.toSend)} />
                                                                    : <Field name={field.name} type={field.type} className={field.class} disabled={disabledForm} />
                                                            )
                                                    }
                                                    <ErrorMessage
                                                        name={field.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    )}

                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                            {
                                                loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                            }
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}