import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Component } from 'react'
import Swal from 'sweetalert2'
import EventBus from '../../../common/EventBus'
import { IStatoDispositiviForm, statoDispositiviFormFields } from '../../../config/formFields/magazzino/magazzino-stato-disposiviti'
import { IFormField, ISelectOption } from '../../../helpers/interfaces/generic'
import { IMagazzinoStatoDispositivo, IStatoDispositivoBody } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo'
import statodispositiviService from '../../../services/api/magazzino/magazzino-stato-dispositivi.service'
import * as Yup from 'yup';

type State = {
    stato: IMagazzinoStatoDispositivo,
    formFields: IFormField[],
    loading: boolean,
    formInitialValues: IStatoDispositiviForm
}

export default class MagazzinoNuovoStato extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            stato: {} as IMagazzinoStatoDispositivo,
            formFields: [],
            loading: false,
            formInitialValues: {} as IStatoDispositiviForm
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleAddStato = this.handleAddStato.bind(this)
    }

    // method: initializing the form values
    initFormValues(): IStatoDispositiviForm {
        return {
            nome: "",
            censimento: "",
        }
    }

    componentDidMount(): void {
        this.setState(
            {
                formFields: statoDispositiviFormFields(),
                formInitialValues: this.initFormValues()
            },
            () => {
                EventBus.dispatch("hideLoader");
            }
        );
    }

    // method: handling add state actions
    async handleAddStato(stato: IStatoDispositiviForm) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        let statoBody: IStatoDispositivoBody = {
            nome: stato.nome,
            censimento: stato.censimento === "Sì" ? true : (stato.censimento === "No" ? false : null)
        }

        // handle add call
        await statodispositiviService.add(statoBody).then(
            response => {
                this.setState(
                    {
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");
                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );

                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IStatoDispositiviForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleAddStato}
                    >
                        <Form className="card">
                            <div className="card-body">
                                <div className='col-12 pb-2'>
                                    <h3>Nuovo stato dispositivi</h3>
                                </div>

                                {formFields.map(
                                    (field: IFormField, key) => {
                                        return <div className="mb-3" key={key}>
                                            <div className='col-12'>
                                                <label className="form-label">{field.label}</label>
                                                {
                                                    field.type === 'select' ?
                                                        <Field as={field.type} name={field.name} className={field.class}>
                                                            <option key={''} value={''}>---</option>
                                                            {field.values?.map((item: ISelectOption) => {
                                                                return <option key={item.key} value={item.value}>{item.value}</option>
                                                            })}
                                                        </Field>
                                                        : <Field name={field.name} type={field.type} className={field.class} />
                                                }
                                                <ErrorMessage
                                                    name={field.name}
                                                    component="div"
                                                    className="alert alert-danger"
                                                />
                                            </div>
                                        </div>
                                    }
                                )}

                                <div className="col-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {
                                            loading && <span className="spinner-border spinner-border-sm me-1"></span>
                                        }
                                        <span>Salva</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </React.Fragment>
            }
        </div>

    }
}