import { AxiosResponse } from 'axios';
import { IMagazzinoTipologiaDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-dispositivi';
import axios from '../../api';
const API_URL = process.env.REACT_APP_API_URL;

class TipologiaDispositiviService {

  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  getAll(): Promise<IMagazzinoTipologiaDispositivo[]> {
    this.controller = new AbortController();
    return axios.get<IMagazzinoTipologiaDispositivo[]>(API_URL + 'magazzino/dispositivi/tipologia', {
      signal: this.controller.signal
    })
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getOne(id: string): Promise<IMagazzinoTipologiaDispositivo> {
    return axios.get<IMagazzinoTipologiaDispositivo>(API_URL + 'magazzino/dispositivi/tipologia/' + id)
      .then((response: AxiosResponse<IMagazzinoTipologiaDispositivo>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  add(body: { nome: string }): Promise<IMagazzinoTipologiaDispositivo> {
    return axios.post<{ nome: string }, AxiosResponse<IMagazzinoTipologiaDispositivo>>(API_URL + 'magazzino/dispositivi/tipologia', body)
      .then((response: AxiosResponse<IMagazzinoTipologiaDispositivo>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  edit(id: string, body: { nome: string }): Promise<IMagazzinoTipologiaDispositivo> {
    return axios.put<{ nome: string }, AxiosResponse<IMagazzinoTipologiaDispositivo>>(API_URL + 'magazzino/dispositivi/tipologia/' + id, body)
      .then((response: AxiosResponse<IMagazzinoTipologiaDispositivo>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  abort() {
    this.controller.abort();
  }

}

export default new TipologiaDispositiviService();