import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Table from "../../common/TableComponent";
import Swal from "sweetalert2";
import { HRDocumentsList } from "../../helpers/interfaces/HRDocuments";
import HRDocumentsService from "../../services/api/HRDocuments.service";
import { listSollecitiHRDocumentsColumns, getSollecitiHRDocumentsColumnDefs } from "../../config/tableColumns/HRDocuments";

type Props = {};

type State = {
  solleciti: Array<HRDocumentsList>;
}

export default class ElencoSollecitiHRDocuments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      solleciti: [],
    }
  }

  async componentDidMount() {
    this.updateHandler();
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco solleciti in corso...' });

    await HRDocumentsService.getSollecitiList()
      .then((solleciti: any) => {
        if (solleciti && typeof solleciti.error !== 'undefined') {
          return Swal.fire(
            "Errore",
            solleciti.error,
            'error'
          )
        }

        this.setState({ solleciti });
      }, (error: string) => {
        Swal.fire({
          title: 'Errore',
          text: error ?? 'A causa di un errore non è stato possibile caricare la lista dei solleciti.',
          icon: 'error',
        })
      })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista dei solleciti.',
          icon: 'error',
        })
      });

    eventBus.dispatch("hideLoader");
  }

  render(): JSX.Element {
    const { solleciti } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Elenco solleciti</h3>
            <Table id="table_solleciti" columns={listSollecitiHRDocumentsColumns} columnDefs={getSollecitiHRDocumentsColumnDefs()} datas={solleciti} buttons={null} />
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}