import { AxiosResponse } from 'axios';
import { IMagazzinoStatoDispositivo, IStatoDispositivoBody } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo';
import axios from '../../api';
const API_URL = process.env.REACT_APP_API_URL;

class StatoDispositiviService {

  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  getAll(): Promise<IMagazzinoStatoDispositivo[]> {
    this.controller = new AbortController();
    return axios.get<IMagazzinoStatoDispositivo[]>(API_URL + 'magazzino/dispositivi/stato', {
      signal: this.controller.signal
    })
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getOne(id: string): Promise<IMagazzinoStatoDispositivo> {
    return axios.get<IMagazzinoStatoDispositivo>(API_URL + 'magazzino/dispositivi/stato/' + id)
      .then((response: AxiosResponse<IMagazzinoStatoDispositivo>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  add(body: IStatoDispositivoBody): Promise<IMagazzinoStatoDispositivo> {
    return axios.post<IStatoDispositivoBody, AxiosResponse<IMagazzinoStatoDispositivo>>(API_URL + 'magazzino/dispositivi/stato', body)
      .then((response: AxiosResponse<IMagazzinoStatoDispositivo>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  edit(id: string, body: IStatoDispositivoBody): Promise<IMagazzinoStatoDispositivo> {
    return axios.put<IStatoDispositivoBody, AxiosResponse<IMagazzinoStatoDispositivo>>(API_URL + 'magazzino/dispositivi/stato/' + id, body)
      .then((response: AxiosResponse<IMagazzinoStatoDispositivo>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  abort() {
    this.controller.abort();
  }

}

export default new StatoDispositiviService();