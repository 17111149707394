import React, { Component } from 'react'
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import { IMaterialeNonRiconsegnato } from '../../../helpers/interfaces/home-amministrazione';
import homeAmministrazioneService from '../../../services/api/home-amministrazione.service';
import * as homeSettings from '../../../config/tableColumns/home-amministrazione';

type Props = {};

type State = {
  materialeMancante: IMaterialeNonRiconsegnato[];
}

export class HomeMaterialeTab extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      materialeMancante: []
    }
  }

  async componentDidMount(): Promise<void> {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    await homeAmministrazioneService.getAllMissingMaterials()
      .then(
        materialeMancante => this.setState(
          {
            materialeMancante
          },
          () => {
            EventBus.dispatch("hideLoader")
          }
        ),
        error => EventBus.dispatch("hideLoader")
      )
  }

  render() {
    const { materialeMancante } = this.state
    const currentDom = "t<'d-flex justify-content-between align-items-center m-2'lip>"

    return (
      <div className='d-flex flex-wrap'>
        {
          materialeMancante.length > 0 && <div className='col-12 py-3 col-md-6 p-md-3'>
            <h4 className='mb-3'>Materiale non riconsegnato</h4>
            <Table id='tab_home_amministrazione_materiale_mancante' columns={homeSettings.listMaterialeNonRiconsegnatoColumns} columnDefs={homeSettings.getMaterialeColumnDefs()} datas={materialeMancante} dom={currentDom} buttons={[]} lengthMenu={[5, 10]} />
          </div>
        }
      </div>
    )
  }
}

export default HomeMaterialeTab