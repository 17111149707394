import React from "react";
import { Component } from "react";
import EventBus from "../../../../common/EventBus";
import { Tab, TabProp } from "../../../../common/TabComponent";
import permessiferieService from "../../../../services/api/permessiferie.service";
import statipermessiferieService from "../../../../services/api/statipermessiferie.service";
import CalendarioComponent from "./CalendarioComponent";
import ConfermateComponent from "./ConfermateComponent";
import DaValutareComponent from "./DaValutareComponent";

type Props = {
    personId: number
};

type State = {
    dataForTabs: { [key: string]: any[] },
    all: any[]
}

class PermessiFerieComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            dataForTabs: {},
            all: []
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const permessiFerie = await permessiferieService.getAllForDipendente(this.props.personId)
        const stati = await statipermessiferieService.getAll()
        let dataForTabs = {}
        stati.forEach(stato => {
            Object.assign(dataForTabs, { ...dataForTabs, [stato.nome]: permessiFerie.filter(ferie => ferie.stato.nome === stato.nome) })
        })
        this.setState({ dataForTabs, all: permessiFerie })
        EventBus.dispatch("hideLoader");
    }

    render() {
        const { dataForTabs, all } = this.state
        // set tabs config
        let gestioneTabs: TabProp[] = [
            {
                id: 'tab_permessi_da_valutare',
                label: `Da valutare`,
                hasBadge: false,
                content: <DaValutareComponent data={dataForTabs['nuova']} />,
                isActive: false
            },
            {
                id: 'tab_permessi_confermate',
                label: `Confermate`,
                hasBadge: false,
                content: <ConfermateComponent data={dataForTabs['confermata']} />,
                isActive: false
            },
            {
                id: 'tab_permessi_calendario',
                label: `Calendario`,
                hasBadge: false,
                content: <CalendarioComponent data={all} personId={this.props.personId} />,
                isActive: false
            }
        ]

        gestioneTabs[0].isActive = true

        return <div className="custom-container">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Gestione permessi e ferie</h2>
                    <Tab useState={true} tabs={gestioneTabs}></Tab>
                </div>
            </div>
        </div>
    }
}
export default PermessiFerieComponent;