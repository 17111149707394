import { AxiosResponse } from 'axios';
import { SignDocument } from '../../helpers/interfaces/generic';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class SignDocumentService {
  async getDocument(token: string): Promise<SignDocument> {
    return await axios.get(API_URL + 'documenttosign/' + token)
      .then((response: AxiosResponse<SignDocument>) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  async sendOTP(token: string, channel: string) {
    return await axios.get(API_URL + 'sendOtp/' + token + '/' + channel)
      .then(response => {
        return response.data;
      })
      .catch((error) => { 
        return error.response.data;
      });
  }

  async checkOTP(otp: string, token: string, fields: string) {
    return await axios.post(API_URL + 'checkOtp', {
      otp,
      tag: token,
      fields
    })
      .then(response => {
        return response.data;
      })
      .catch((error) => { 
        return error.response.data;
      });
  }
}

export default new SignDocumentService();