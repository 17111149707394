import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getAddAdempimentoFormFields(adempimenti: any, status: any, personId: number): Array<FormFields> {
  return [
    {
      'label': 'Adempinenti',
      'name': 'adempimenti_id',
      'type': 'select',
      'values': adempimenti,
      'class': 'form-select',
      'validation': Yup.string()
    },
    {
      'label': 'Stato',
      'name': 'status',
      'type': 'select',
      'values': status,
      'class': 'form-select',
      'validation': Yup.string()
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'value': personId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}