import React, { Component } from 'react';
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
import 'datatables.net-responsive'
import StatsService from "../services/stats.service";


type Props = {

};
type State = {
  data: any
};
class StatsEmployeesComponent extends Component<Props, State> {
  // State array variable to save and show data
  constructor(props: Props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    StatsService.getStatsEmployees().then(
      (response) => {
        this.setState({ data: response.data });
      },
      (error: any) => { }
    );
  }

  render() {
    const { data } = this.state;
    return <div className="row row-deck row-cards">{Object.keys(data).length > 0 ? Object.keys(data).map((item: any, key: any) => {
      const diffRange = data[item].totalPersons - data[item].primoRangePersons;
      let classIcon = "d-inline-flex align-items-center lh-1";
      classIcon += diffRange > 0 ? ' text-green' : ' text-red';
      return <div className="col-sm-6 col-lg-3" key={key}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="subheader">{item}</div>
            </div>
            <div className="h1 mb-3">{data[item].totalPersons}</div>
            <div className="d-flex mb-2">
              <div>Rispetto anno precedente</div>
              <div className="ms-auto">
                <span className={classIcon}>
                  {diffRange}
                  {diffRange > 0 ? <React.Fragment>
                    <i style={{ fontSize: "18px" }} className="fa fa-line-chart text-success ms-2" aria-hidden="true"></i>
                  </React.Fragment> : <React.Fragment>
                    <i style={{ transform: "scaleY(-1)", fontSize: "18px" }} className="fa fa-line-chart text-danger ms-2" aria-hidden="true"></i>
                  </React.Fragment>
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    }) : <div>Caricamento statistiche in corso...</div>}
    </div>
  }
}
export default StatsEmployeesComponent;