export const listVariabiliContrattiColumns = [
    {
        title: 'Azioni',
        data: 'id'
    },
    {
        title: 'Liv.',
        width: 'auto',
        data: 'livello'
    },
    {
        title: 'Ral',
        width: 'auto',
        data: null, render: (data: any, type: any, row: any) => {
            return '<span class="autoNumeric">' + row.ral + '</span>';
        }
    }, {
        title: 'Inden.',
        width: 'auto',
        data: null, render: (data: any, type: any, row: any) => {
            return '<span class="autoNumeric">' + row.indennita + '</span> (' + row.tipo_indennita + ')';
        }
    },
    {
        title: 'Premio',
        width: 'auto',
        data: 'premio'
    }, {
        title: 'Data Agg.',
        width: 'auto',
        data: 'data_aggiornamento'
    }, {
        title: 'Comune Patrono',
        width: 'auto',
        data: 'comune_patrono'
    }];

export function getListVariabiliContrattiColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </a>`;

                const edit = `<a class="edit_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
    ];
}