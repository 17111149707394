import React, { Component } from "react";

import RapportiniMensile from './RapportiniMensile';
import RapportiniAnnuale from './RapportiniAnnuale';
import RapportiniCommesse from './RapportiniCommesse';
import RapportiniMateriale from './RapportiniMateriale';
import RapportiniAnagrafica from './RapportiniAnagrafica';
import { Field, FormikProps } from "formik";
import EventBus from "../../common/EventBus";
import PersonService from "../../services/api/person.service";
import CommesseService from "../../services/api/commesse.service";
import reportsService from "../../services/api/reports.service";
import { openBase64NewTab } from "../../common/Base64";
import Swal from "sweetalert2";
import { Tab } from "../../common/TabComponent";
import { FormFields, FormFieldsValue, ISelectOption } from "../../helpers/interfaces/generic";
import { JoborderObject, PersonJoborders } from "../../helpers/interfaces/user";

type Props = {};

type State = {
    persons: FormFieldsValue[],
    joborders: FormFieldsValue[],
    joborders_all: JoborderObject[],
    loading: boolean
}
export default class Reports extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            persons: [],
            joborders: [],
            joborders_all: [],
            loading: false
        }

        this.handleGenerateRal = this.handleGenerateRal.bind(this)
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const person_all = await PersonService.getAllForSearch();
        let persons = person_all.data.map(item => { return { key: item.id, value: item.nominativo } });

        const joborders_get_all: PersonJoborders = await CommesseService.getAll();
        let joborders_active: ISelectOption[] = joborders_get_all.active.map((item: any) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } }).sort(
            (j1: ISelectOption, j2: ISelectOption) => j1.value.localeCompare(j2.value)
        );
        let joborders_expired: ISelectOption[] = joborders_get_all.expired.map((item: any) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } }).sort(
            (j1: ISelectOption, j2: ISelectOption) => j1.value.localeCompare(j2.value)
        );

        const joborders = joborders_active.concat(joborders_expired);
        const joborders_all = joborders_get_all.active.concat(joborders_get_all.expired);

        this.setState({ persons, joborders, joborders_all });
        EventBus.dispatch("hideLoader");
    }

    renderField(item: FormFields, key: number, formik: FormikProps<any>) {
        const displayItem = ((item.showFromFieldName && item.showFromFieldValue && item.showFromFieldValue.includes(Number(formik.values[item.showFromFieldName]))) || typeof item.showFromFieldName == 'undefined');

        switch (item.type) {
            case 'select':
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field as={item.type} name={item.name} className={item.class} onChange={async (event: any) => {
                            formik.setFieldValue(item.name, event.target.value);
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.target.value);
                            }
                        }}>
                            <option key={''} value={''}>{item.value ?? 'Tutti'}</option>
                            {item.values && item.values.map((option: FormFieldsValue) => {
                                return <option key={option.key} value={option.key}>{option.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>
            case 'file':
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <input name={item.name} type={item.type} className={item.class} onChange={(event: any) => {
                            formik.setFieldValue(item.name, event.currentTarget.files[0]);
                        }} />
                    </div>
                </React.Fragment>
            case 'radio':
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        {item.values && item.values.map((value: FormFieldsValue) => {
                            return <label key={value.key}>
                                <Field type="radio" name={item.name} value={value} />
                                {value}
                            </label>
                        })}
                    </div>
                </React.Fragment>
            case 'checkbox':
                return displayItem && <label className="form-check form-switch">
                    <Field name={item.name} type={item.type} className={item.class} />
                    <span className="form-check-label form-label" style={{ 'textAlign': 'left' }}>{item.label}</span>
                </label>
            default:
                //return <Field innerRef={(el: any) => this.innerRefs[item.name] = el} name={item.name} type={item.type} className={item.class} />
                return displayItem && <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field name={item.name} type={item.type} className={item.class} />
                    </div>
                </React.Fragment>
        }
    }

    handleGenerateRal() {
        this.setState(
            {
                loading: true
            },
            async () => {
                EventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });
                const report: any = await reportsService.ral();
                if (typeof report?.body !== 'undefined') {
                    openBase64NewTab(report);
                    this.setState({
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                    }, () => {
                        Swal.fire({
                            title: 'Errore',
                            text: 'Si è verificato un errore durante la generazione del report.',
                            icon: 'error',
                        });
                    });
                }
                EventBus.dispatch("hideLoader");
            })
    }

    render() {
        const { persons, joborders, joborders_all, loading } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">
                            Reports
                        </h2>
                        <Tab useState={true} tabs={[
                            {
                                id: 'rapportini_mensile',
                                label: 'Rapportini mensile',
                                content: <RapportiniMensile persons={persons} renderField={this.renderField} />,
                                isActive: true,
                            },
                            {
                                id: 'rapportini_annuale',
                                label: 'Rapportini annuale',
                                content: <RapportiniAnnuale persons={persons} renderField={this.renderField} />,
                            },
                            {
                                id: 'commesse',
                                label: 'Commesse',
                                content: <RapportiniCommesse persons={persons} joborders={joborders} joborders_all={joborders_all} renderField={this.renderField} />,
                            },
                            {
                                id: 'materiale_assegnato',
                                label: 'Materiale assegnato',
                                content: <RapportiniMateriale renderField={this.renderField} />,
                            },
                            {
                                id: 'anagrafica',
                                label: 'Anagrafica',
                                content: <RapportiniAnagrafica renderField={this.renderField} />,
                            },
                            {
                                id: 'ral_costi',
                                label: 'RAL e costi',
                                content: (
                                    <div className="px-3 pt-3 d-flex justify-content-end">
                                        <button className="btn btn-primary" disabled={loading} onClick={() => this.handleGenerateRal()}>
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm me-1"></span>
                                            )}
                                            <span>Genera</span>
                                        </button>
                                    </div>
                                )
                            },
                        ]} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}