import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Swal from "sweetalert2";
import Select from 'react-select'
import personService from "../../services/api/person.service";
import $ from 'jquery';
import HRDocumentsService from "../../services/api/HRDocuments.service";
import { HRDocumentsInviaResponse, HRDocumentsTemplate } from "../../helpers/interfaces/HRDocuments";
type Props = {};

type State = {
  personOptions: Array<Option>;
  templateOptions: Array<Option>;
  person: Option | null;
  template: Option | null;
  loading: boolean;
};

type Option = {
  value: any,
  label: string
};

export default class InviaHRDocument extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      personOptions: [],
      templateOptions: [],
      person: null,
      template: null,
      loading: false,
    }
  }

  async componentDidMount() {
    this.updateHandler();

    $('div#form-send').on('click', 'input#submit', async (e) => {
      this.setState({ loading: true });

      const { person, template } = this.state;

      if (!person || !template) {
        this.setState({ loading: false });
        return Swal.fire(
          'Campi non compilati',
          'Per favore compila tutti i campi obbligatori del modulo.',
          'warning'
        )
      }

      await HRDocumentsService.send({
        person_id: person.value,
        template_id: template.value
      }).then(
        (result: HRDocumentsInviaResponse) => {
          Swal.fire({
            title: 'documento inviato',
            text: 'Il documento è stato inviato con successo.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              await this.updateHandler();
              this.setState({ person: null, template: null });
              $('input#document').val('');
            }
          });
        },
        (error) => {
          Swal.fire({
            title: 'Errore',
            text: error ?? 'A causa di un errore non è stato possibile inviare il documento.',
            icon: 'error',
          });
        }
      ).catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile inviare il documento.',
          icon: 'error',
        });
      })

      this.setState({ loading: false });
      return false;
    });
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento delle informazioni in corso...' });
    this.setState({ loading: true });

    await personService.getAllForSearch()
      .then((result) => {
        let personOptions: Array<Option> = [];
        result.data.forEach(element => {
          return personOptions.push({ value: element.id, label: element.lastname + ' ' + element.name });
        });

        this.setState({ personOptions });
      }, (error: string) => {
        Swal.fire({
          title: 'Errore',
          text: error ?? 'A causa di un errore non è stato possibile caricare la lista del personale.',
          icon: 'error',
        })
      })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista del personale.',
          icon: 'error',
        })
      });

    await HRDocumentsService.getDocumentTemplates()
      .then((result: Array<HRDocumentsTemplate> & { error: string }) => {
        if (result && typeof result.error !== 'undefined') {
          return Swal.fire(
            "Errore",
            result.error,
            'error'
          )
        }

        let templateOptions: Array<Option> = [];
        result.forEach(element => {
          return templateOptions.push({ value: element.id, label: element.description });
        });

        this.setState({ templateOptions });
      }, (error: string) => {
        Swal.fire({
          title: 'Errore',
          text: error ?? 'A causa di un errore non è stato possibile caricare la lista dei template.',
          icon: 'error',
        })
      })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista dei template.',
          icon: 'error',
        })
      });

    this.setState({ loading: false });
    eventBus.dispatch("hideLoader");
  }

  render(): JSX.Element {
    const { personOptions, templateOptions, person, template, loading } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Invia un documento</h3>
            <br />
            <div id="form-send">
              <h4>Seleziona personale: *</h4>
              <Select
                id="person"
                name="person"
                options={personOptions}
                styles={{ container: (style) => ({ ...style, width: 'auto', minWidth: 0, maxWidth: 200 }) }}
                required={true}
                onChange={(input) => {
                  input?.label && this.setState({ person: { label: input?.label, value: input?.value } ?? '' })
                }}
                value={person}
              />
              <br />
              <h4>Seleziona il tipo di documento: *</h4>
              <Select
                id="template"
                name="template"
                options={templateOptions}
                styles={{ container: (style) => ({ ...style, width: 'auto', minWidth: 0, maxWidth: 200 }) }}
                required={true}
                onChange={(input) => {
                  input?.label && this.setState({ template: { label: input?.label, value: input?.value } ?? '' })
                }}
                value={template}
              />
              <br /><br />
              <input id="submit" className="btn btn-info" type="submit" value="Invia" disabled={loading} />
              <br /><br />
              <strong>* = Campi obbligatori</strong>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  }
}