import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormFields } from "../../helpers/interfaces/generic";

export function getEditCausaliFormFields(): Array<FormFields> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Codice',
      'name': 'identificationcode',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Scadenza',
      'name': 'expired',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
  ];
}

export function getAddCausaliFormFields(): Array<FormFields> {
    return [
      {
        'label': 'Nome',
        'name': 'name',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      },
      {
        'label': 'Codice',
        'name': 'identificationcode',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      },
      {
        'label': 'Scadenza',
        'name': 'expired',
        'type': 'date',
        'class': 'form-control',
        'validation': Yup.date().notRequired()
      }
    ];
  }