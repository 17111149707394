import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';
import $ from 'jquery';
import { getEditDispositivoFormFields, getAddDispositivoFormFields } from "./config/formFields";
import { listDispositiviColumns, getListDispositiviColumnDefs } from "./config/tableColumns";
import DispositiviService from "../../../services/api/dispositivi.service";
import StatoDispositiviService from "../../../services/api/magazzino/magazzino-stato-dispositivi.service";
import TipologiaDispositiviService from "../../../services/api/magazzino/magazzino-tipologia-dispositivi.service";
import { FormFields } from '../../../helpers/interfaces/generic';
import { Device } from '../../../helpers/interfaces/device';
import { getConsegnaDispositivoFormFields } from './config/formFields/dispositivo';
import personService from '../../../services/api/person.service';
import { openBase64NewTab } from '../../../common/Base64';


type Props = {
  history: {
    push(url: string): void;
  }
  match: {
    params: {
      id: string
    };
  }
  dispositivi: Array<Device>,
  personId: number
};

type State = {
  data: Array<Device>,
  showModal: boolean,
  modalType: string,
  modalTitle: string,
  formFields: Array<FormFields>,
  formInitialValues: FormFields | {},
}

class DispositiviComponent extends Component<Props, State> {
  buttons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => void; })[];
  apiSubmit: any;
  proprietadispositivo: string[];
  statodispositivi: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: props.dispositivi || [],
      showModal: false,
      modalType: 'add',
      modalTitle: 'Nuova assegnazione',
      formFields: [],
      formInitialValues: {},
    }

    this.apiSubmit = DispositiviService.personAdd;

    this.buttons = [];

    this.proprietadispositivo = [
      'Dispositivo aziendale',
      'Dispositivo del cliente',
      'Dispositivo personale'
    ];

    this.statodispositivi = {};
  }

  async componentDidMount() {
    await this.updateHandler();

    EventBus.dispatch("showLoader", { text: 'Caricamento dispositivi in corso...' });

    const statodispositivi_all = await StatoDispositiviService.getAll();
    this.statodispositivi = statodispositivi_all.map((item: any) => { return { key: item.id, value: item.nome } });

    EventBus.dispatch("hideLoader");

    $('table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento dispositivo in corso...' });

      const idDispositivo = $(e.currentTarget).data('id');
      const dispositivo = await DispositiviService.personGet(idDispositivo);

      if (dispositivo) {
        this.setState({ formFields: getEditDispositivoFormFields(this.statodispositivi, this.props.personId, idDispositivo), formInitialValues: dispositivo, }, () => {
          this.apiSubmit = DispositiviService.personPut;
          this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica assegnazione' });
        });
      }

      EventBus.dispatch("hideLoader");
    });

    $('table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi confermare l'eliminazione?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          const idLicenza = $(e.currentTarget).data('id');
          const licenza: any = await DispositiviService.personDelete(idLicenza);

          if (licenza && typeof licenza.error !== 'undefined') {
            Swal.fire(
              licenza.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess();
          }
        }
      });
    });

    $('table').on('click', '.device_consegna_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento in corso...' });

      const id = $(e.currentTarget).data('id');

      this.setState(
        {
          formFields: getConsegnaDispositivoFormFields(id),
          formInitialValues: { id },
        },
        () => {
          this.apiSubmit = DispositiviService.personConsegna;
          this.setState({ showModal: true, modalType: 'consegna', modalTitle: 'Consegna dispositivo assegnato' });
        }
      );

      EventBus.dispatch("hideLoader");
    });

    $('table').on('click', '.device_riconsegna_btn', async (e: any) => {
      e.preventDefault();

      const id = $(e.currentTarget).data('id');

      Swal.fire({
        title: 'Riconsegna dispositivo',
        text: "Sei sicuro di voler inviare la lettera di riconsegna?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Invia',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          EventBus.dispatch("showLoader", { text: 'Caricamento in corso...' });
          await DispositiviService.personRiconsegna(id);
          EventBus.dispatch("hideLoader");
          await this.updateHandler();
        }
      });
    });

    $('table').on('click', '.device_download_document_btn', async (e: any) => {
      e.preventDefault();

      const id = $(e.currentTarget).data('id');

      await DispositiviService.personDownloadEmail(id).then((response) => {
        openBase64NewTab(response);
      },
        () => {
          Swal.fire(
            'Operazione fallita',
            'Siamo spiacenti, non è stato possibile eliminare il documento associato a questo dispositivo',
            'error'
          )
        }).catch(() => {
          Swal.fire(
            'Operazione fallita',
            'Siamo spiacenti, a causa di un errore imprevisto non è stato possibile elaborare la sua richiesta',
            'error'
          )
        })
    });

    $('table').on('click', '.device_validate_email_btn', async (e: any) => {
      e.preventDefault();

      const id = $(e.currentTarget).data('id');
      const action = $(e.currentTarget).data('action');

      if (!action) {
        return Swal.fire('Nessuna azione disponibile per questo pulsante', '', 'error');
      }

      Swal.fire({
        title: action === 'assegnazione' ? "Vuoi confermare l'assegnazione?" : "Vuoi confermare la riconsegna?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          await DispositiviService.personValidateEmail(id).then(() => {
            Swal.fire({
              title: "Operazione completata con successo",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK',
            }).then(async (result: any) => {
              this.updateHandler();
            });
          },
            () => {
              Swal.fire(
                'Operazione fallita',
                'Siamo spiacenti, non è stato possibile validare il documento associato a questo dispositivo',
                'error'
              )
            }).catch(() => {
              Swal.fire(
                'Operazione fallita',
                'Siamo spiacenti, a causa di un errore imprevisto non è stato possibile elaborare la sua richiesta',
                'error'
              )
            })
        }
      });
    });
  }

  async updateHandler() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dispositivi in corso...' });

    const userId = this.props.match.params.id;

    if (!parseInt(userId)) {
      this.props.history.push('/');
    }

    await personService.getDevices(parseInt(userId)).then(
      (response: any) => {
        this.setState({ data: response });
      },
    );

    EventBus.dispatch("hideLoader");
  }

  async closeModal() {
    this.setState({ showModal: false });
  }

  async openAddModal() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    const dispositivi_all = await DispositiviService.getAll();
    let dispositivi: any = [];

    dispositivi_all.forEach((value: any, key: any) => {
      if (value.disponibile) {
        dispositivi.push({ key: value.id, value: value.tipologia_nome + ' ' + value.marca_modello + ' ' + value.serial_number });
      }
    });

    const tipologiadispositivi_all = await TipologiaDispositiviService.getAll();
    let tipologiadispositivi: any = tipologiadispositivi_all.map((item: any) => { return { key: item.id, value: item.nome } });

    const proprietadispositivo: any = this.proprietadispositivo.map((value: any, key: any) => { return { key, value } });

    EventBus.dispatch("hideLoader");

    this.apiSubmit = DispositiviService.personAdd;

    this.setState({
      showModal: true,
      modalType: 'add',
      modalTitle: 'Nuova assegnazione',
      formFields: getAddDispositivoFormFields(proprietadispositivo, dispositivi, tipologiadispositivi, this.props.personId),
      formInitialValues: {},
    })
  }

  handleSuccess() {
    window.location.hash = "#dispositivi";
    window.location.reload();
  }

  render() {
    const { showModal, formFields, formInitialValues, modalType, data, modalTitle } = this.state;

    return <div className="row p-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0">Dispositivi assegnati</h3>
        <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={this.openAddModal.bind(this)}>
          <span>Assegna dispositivo</span>
        </button>
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-md-2 d-flex align-items-center">
          <i className="fa fa-circle me-1" style={{ fontSize: '24px', color: '#ff0000' }}></i>Assente
        </div>
        <div className="col-md-2 d-flex align-items-center">
          <i className="fa fa-circle me-1" style={{ fontSize: '24px', color: '#666666' }}></i>Inviata
        </div>
        <div className="col-md-2 d-flex align-items-center">
          <i className="fa fa-circle me-1" style={{ fontSize: '24px', color: '#ffff00' }}></i>Ricevuta
        </div>
        <div className="col-md-2 d-flex align-items-center">
          <i className="fa fa-circle me-1" style={{ fontSize: '24px', color: '#00ff00' }}></i>Approvata
        </div>
      </div>
      <ModalForm
        showModal={showModal}
        title={modalTitle}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />
      {
        data.length > 0 ? <React.Fragment>
          <Table id="table_dispositivi" columns={listDispositiviColumns} columnDefs={getListDispositiviColumnDefs(this.proprietadispositivo)} datas={this.state.data} buttons={this.buttons} />
        </React.Fragment> : <p>Non sono presenti dispositivi</p>
      }
    </div>
  }
}

export default DispositiviComponent;