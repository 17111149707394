import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class ContrattiService {
  add(data:any) {
    return axios.post(API_URL + 'datore/person/contratto', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }
  put(id:any, data:any) {
    return axios.put(API_URL + 'datore/person/contratto/'+id, {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }
  get(id:any) {
    return axios.get(API_URL + 'datore/person/contratto/'+id)
    .then(response => { return typeof response.data == 'object' ? response.data : null; })
    .catch((error) => { return Promise.reject(); });;
  }
}
export default new ContrattiService();