export interface IMagazzinoContrattoNoleggio {
    codice: string;
    data_fine_noleggio: string;
    data_inizio_noleggio: string;
    id: number;
    nome: string;
    numero_dispositivi: number;
}

export function isMagazzinoContrattoNoleggio(object: any): object is IMagazzinoContrattoNoleggio {
    return 'codice' in object;
}

export function extractProperty<T, K extends keyof T>(data: T, property: K) {
    return data[property];
}