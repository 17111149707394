export const listLicenzeColumns = [
    {
        title: 'Azioni',
        data: 'id'
    },
    {
        title: 'Prodotto',
        width: 'auto',
        data: 'prodotto_nome'
    },
    {
        title: 'Identificativo',
        width: 'auto',
        data: 'identificativo'
    },
    {
        title: 'Data Consegna',
        width: 'auto',
        data: 'data_consegna'
    },
    {
        title: 'Data Restituzione',
        width: 'auto',
        data: 'data_restituzione'
    },
    {
        title: 'Note',
        width: 'auto',
        data: 'note'
    }
];

export function getListLicenzeColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle me-1"  data-id="${data}">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </a>`;

                const edit = `<a class="edit_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
    ];
}