import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class CausaliService {
  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  getAll() {
    this.controller = new AbortController();
    return axios.get(API_URL + 'datore/causali', {
      signal: this.controller.signal
    })
    .then(response => { return Array.isArray(response.data) ? response.data : []; })
    .catch((error) => { return Promise.reject(); });
  }

  get(id:any) {
    return axios.get(API_URL + 'datore/causali/'+id)
    .then(response => { return typeof response.data == 'object' ? response.data : null; })
    .catch((error) => { return Promise.reject(); });;
  }

  put(id:any, data:any) {
    return axios.put(API_URL + 'datore/causali/'+id, {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }

  add(data:any) {
    return axios.post(API_URL + 'datore/causali', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return []; });
  }

  abort() {
    this.controller.abort();
  }
  
  getAllDipendente() {
    this.controller = new AbortController();
    return axios.get(API_URL + 'dipendente/causali', {
      signal: this.controller.signal
    })
    .then(response => { return Array.isArray(response.data) ? response.data : []; })
    .catch((error) => { return Promise.reject(); });
  }
}
export default new CausaliService();