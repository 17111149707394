import { IBusinessUnit } from '../../helpers/interfaces/businessunit';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class BusinessUnitService {

    getAll() {
        return axios.get<IBusinessUnit[]>(API_URL + 'datore/businessunit')
            .then(response => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

    get(id: any) {
        return axios.get<IBusinessUnit>(API_URL + 'datore/businessunit/' + id)
            .then(response => { return typeof response.data == 'object' ? response.data : null; })
            .catch((error) => { return Promise.reject(); });
    }

    put(id: any, data: any) {
        return axios.put(API_URL + 'datore/businessunit/' + id, { ...data })
            .then(response => { return response.data; })
            .catch((error) => { return []; });
    }

    add(data: any) {
        return axios.post(API_URL + 'datore/businessunit', { ...data })
            .then(response => { return response.data; })
            .catch((error) => { return []; });
    }
}
export default new BusinessUnitService();