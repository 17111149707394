export const listJobordersColumns = [
    { "title": "Nome", "data": "name" },
    { "title": "Data Inizio", "data": "startdate" },
    { "title": "Data fine", "data": "enddate" },
];

export const listDevicesColumns = [
    { "title": "Tipologia", "data": "tipologia_nome" },
    { "title": "Marca e modello", "data": "marca_modello" },
    { "title": "Data consegna", "data": "data_consegna" },
    { "title": "Data restituzione", "data": "data_restituzione" },
];

export const listLicencesColumns = [
    { "title": "Nome", "data": "prodotto_nome" },
    { "title": "Identificativo", "data": "identificativo" },
    { "title": "Data consegna", "data": "data_consegna" },
    { "title": "Data restituzione", "data": "data_restituzione" }
];

export const listServicesColumns = [
    { "title": "Nome", "data": "nome" }
];

export const listPermissionsColumns = [
    { "title": "Nome", "data": "causale" },
    { "title": "Data Inizio", "data": "inizio" },
    { "title": "Data fine", "data": "fine" },
];

