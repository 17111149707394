import * as Yup from "yup";
import { IFormField } from "../../../../../helpers/interfaces/generic";

export function getEditAnagraficaFormFields(titolistudio: any): Array<IFormField> {
  return [
    {
      label:'E-mail Personale',
      name:'personal_email',
      type:'mail',
      class:'form-control',
      required: true,
      validation:Yup.string().email()
    },
    {
      label:'Telefono',
      name:'telefono',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Sesso',
      name:'sesso',
      type:'select',
      values:[{ key: 'M', value: 'M' }, { key: 'F', value: 'F' }],
      class:'form-select',
      validation:Yup.string()
    },
    {
      label:'Codice Fiscale',
      name:'cf',
      type:'text',
      class:'form-control',
      required: true,
      validation:Yup.string()
    },
    {
      label:'Data Nascita',
      name:'data_nascita',
      type:'date',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Luogo Nascita',
      name:'luogo_nascita',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Provincia Nascita',
      name:'provincia_nascita',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Codice Catastale Comune Nascita',
      name:'codice_catastale_comune_nascita',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Comune residenza',
      name:'comune_residenza',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Provincia residenza',
      name:'provincia_residenza',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'CAP residenza',
      name:'cap_residenza',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Indirizzo residenza',
      name:'indirizzo_residenza',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Nazionalità',
      name:'nazionalita',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Titolo Studio',
      name:'titolistudio_id',
      type:'select',
      values:titolistudio,
      class:'form-select',
      validation:Yup.string()
    },
    {
      label:'Permesso Soggiorno',
      name:'permesso_soggiorno',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label: 'indirizzo Domicilio',
      name: 'indirizzo_domicilio',
      type: 'text',
      class: 'form-control',
      validation:Yup.string()
    },
    {
      label:'Comune Domicilio',
      name:'comune_domicilio',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'Provincia Domicilio',
      name:'provincia_domicilio',
      type:'text',
      class:'form-control',
      validation:Yup.string()
    },
    {
      label:'CAP Domicilio',
      name:'cap_domicilio',
      type:'text',
      class:'form-control',
      validation:Yup.string().nullable(true)
    },
    {
      label:'IBAN',
      name:'iban',
      type:'text',
      class:'form-control',
      required: true,
      validation:Yup.string()
    },
  ];
}