declare var window:any;

export function openBase64NewTab(documento: any): void {
  var blob = base64toBlob(documento.body, documento.mimetype);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, documento.filename);
  } else {
    // const blobUrl = URL.createObjectURL(blob);
    // window.open(blobUrl);
    let link = document.createElement('a');
    link.download = documento.filename;

    link.href = URL.createObjectURL(blob);
    link.click();

    URL.revokeObjectURL(link.href);
  }
}

export function base64toBlob(base64Data: string, mimetype: string = "application/pdf"): Blob {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);
    const bytes = new Array(end - begin);

    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }

    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: mimetype });
}

export function base64Encode(value: string) {      
  return btoa(unescape(encodeURIComponent(value)));
}

export function base64Decode(value: string) {      
  return decodeURIComponent(escape(atob(value)));
}