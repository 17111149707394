import { generatePath } from "react-router-dom";
import { ListColumns } from "../../../helpers/interfaces/generic";
import { IDispositivo } from "../../../helpers/interfaces/magazzino/magazzino-dispositivi";

// table columns definitions
export const listDispositiviColumns: ListColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Stato", data: "stato_nome" },
    { title: "Tipologia", data: "tipologia_nome" },
    { title: "Spc. tecniche", data: "spec_tecniche" },
    { title: "Marca", data: "marca_modello" },
    { title: "Serial number", data: "serial_number" },
    { title: "Contratto", data: "contrattonoleggio" },
    { title: "Data inizio", data: "data_inizio_noleggio" },
    { title: "Data fine", data: "data_fine_noleggio" },
    { title: "Cespiti", data: "cespiti", searchable: false },
    { title: "Data acquisto", data: "data_acquisto", searchable: false },
    { title: "Assegnatario", data: "assegnatario" },
    { title: "Disponibilità", data: "inMagazzino", searchable: false },
    { title: "Note", data: "note" },
    { title: "Allegati", data: "attachments", searchable: false }
];

export const listAssegnatariColumns = [
    { title: "Assegnatario", data: "assegnatario" },
    { title: "Consegna", data: "data_consegna" },
    { title: "Restituzione", data: "data_restituzione" },
]

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getDispositiviColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IDispositivo) => {
                const url = generatePath("/magazzino/dispositivi/dettaglio-dispositivo/:id", { id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle me-1" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                const showStorico = `<a class="storico_btn custom-icon btn btn-outline-secondary rounded-circle" data-id="${id}"> 
                    <i style="font-size: 18px" class="fa fa-history" aria-hidden="true" title="Storico assegnatari"></i>
                </a>`

                return '<div class="d-flex">' + edit + (row.storico_assegnatari.length > 0 ? showStorico : '') + '</div>';
            },
        },
        {
            targets: listDispositiviColumns.length - 1,
            render: (id: number, type: string, row: IDispositivo) => {
                let files = row.attachments?.map(a => a.path)
                return files && files.length > 0 ? `<p data-id="'+${id}+'">${files.toString()}</p>` : '';;
            },
        },
    ];
}