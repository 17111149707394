import React, { Component } from "react";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

import EventBus from "../../common/EventBus";
import ReportsService from "../../services/api/reports.service";
import { getRapportiniAnnualeFormFields } from "../../config/formFields";
import { openBase64NewTab } from '../../common/Base64';
import Swal from "sweetalert2";
import { FormFields } from "../../helpers/interfaces/generic";
import moment from "moment";
import "moment/locale/it";

type Props = {
    renderField: (item: FormFields, key: number, formik: FormikProps<any>) => false | JSX.Element,
    persons: any
};
type State = {
    loading: boolean,
    formFields: Array<FormFields>,
    disabledForm: boolean,
    formInitialValues: { [key: string]: any }
}
export default class RapportiniAnnuale extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            disabledForm: true,
            formInitialValues: {},
        }

    }

    componentDidMount(): void {
        const years = this.numberRange(2000, moment().year());
        let newYears: any = years.map((item: any) => { return { key: item, value: item } });
        this.setState({ formFields: getRapportiniAnnualeFormFields(newYears, this.props.persons) });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.persons !== this.props.persons) {

            const years = this.numberRange(2000, moment().year());
            let newYears: any = years.map((item: any) => { return { key: item, value: item } });
            this.setState({ formFields: getRapportiniAnnualeFormFields(newYears, this.props.persons) });
        }
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    numberRange(start: any, end: any) {
        const arr: any = new Array(end - start + 1);
        return arr.fill().map((d: number, i: number) => i + start).reverse();
    }

    async handleUpdate(formValue: any) {
        EventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });
        await ReportsService.yearly(formValue.year, formValue.personId).then(
            (report: any) => {
                if (typeof report?.body !== 'undefined') {
                    openBase64NewTab(report);
                    this.setState({
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                    }, async () => {
                        Swal.fire({
                            title: 'Errore',
                            text: 'Si è verificato un errore durante la generazione del report.',
                            icon: 'error',
                        });
                    });
                }
            }
        ).catch((e: any) => {
            this.setState({
                loading: false,
            }, async () => {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore imprevisto durante la generazione del report.',
                    icon: 'error',
                });
            });
        });

        EventBus.dispatch("hideLoader");
    }

    render() {
        const { loading, formFields } = this.state;
        let initialValues: any = {};
        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate.bind(this)}
            >
                {(formik) => {
                    return <Form className="px-3 pt-3">
                        {formFields && formFields.map((item: FormFields, key: number) => {
                            return <div className="form-group mb-3 row" key={key}>

                                {this.props.renderField(item, key, formik)}

                                <ErrorMessage
                                    name={item.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        })}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                )}
                                <span>Genera</span>
                            </button>
                        </div>
                    </Form>
                }}

            </Formik>
        </React.Fragment>
    }
}