import * as Yup from "yup";
import { FormFields } from "../../helpers/interfaces/generic";

export function getNuovoPersonaleFormFields(persontype: any, businessUnit: any): Array<FormFields> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Cognome',
      'name': 'lastname',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'E-mail aziendale',
      'name': 'registrationemail',
      'type': 'mail',
      'class': 'form-control',
      'validation': Yup.string().email().required("Campo obbligatorio")
    },
    {
      'label': 'Password',
      'name': 'password',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Business unit',
      'name': 'businessunit',
      'type': 'select',
      'values': businessUnit,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Tipologia',
      'name': 'idpersontype',
      'type': 'select',
      'values': persontype,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Stato',
      'name': 'status',
      'type': 'select',
      'values': [{ key: 'E', value: 'Attivo con login' }, { key: 'N', value: 'Attivo senza login' }],
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
  ];
}