import React, { Component } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import DatePickerForm from '../../../common/DatePickerForm';
import { IDispositivoForm, dispositiviFormFields, mapListsData } from '../../../config/formFields/magazzino/magazzino-dispositivi';
import { IMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import { IDispositivo, IDispositivoBody } from '../../../helpers/interfaces/magazzino/magazzino-dispositivi';
import magazzinoContrattiNoleggiService from '../../../services/api/magazzino/magazzino-contratti-noleggi.service';
import magazzinoDispositiviService from '../../../services/api/magazzino/magazzino-dispositivi.service';
import tipologiaDispositiviService from '../../../services/api/magazzino/magazzino-tipologia-dispositivi.service';
import statoDispositiviService from '../../../services/api/magazzino/magazzino-stato-dispositivi.service';
import EventBus from '../../../common/EventBus';
import { IFormField, ISelectOption } from '../../../helpers/interfaces/generic';
import documentiService from '../../../services/api/documenti.service';
import { base64toBlob } from '../../../common/Base64';
import { IMagazzinoStatoDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo';
import { IMagazzinoTipologiaDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-dispositivi';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    dispositivo: IDispositivo,
    contratti: IMagazzinoContrattoNoleggio[],
    tipologie: IMagazzinoTipologiaDispositivo[]
    stati: IMagazzinoStatoDispositivo[],
    formFields: IFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: IDispositivoForm,
    attachmentsToUpload: File[]
};

export default class MagazzinoDettaglioDispositivo extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            dispositivo: {} as IDispositivo,
            contratti: [],
            tipologie: [],
            stati: [],
            disabledForm: true,
            formFields: [],
            loading: false,
            formInitialValues: {} as IDispositivoForm,
            attachmentsToUpload: []
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateDispositivo = this.handleUpdateDispositivo.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this)
    }

    // method: initializing the form values
    initFormValues(): IDispositivoForm {
        let stato = this.state.stati.find(stato => stato.id === this.state.dispositivo.stato_id)
        let tipologia = this.state.tipologie.find(tipologia => tipologia.id === this.state.dispositivo.tipologia_id)
        let contratto = this.state.contratti.find(contratto => contratto.id === this.state.dispositivo.contrattinoleggio_id)
        return {
            stato_nome: stato ? stato.nome : "",
            tipologia_nome: tipologia ? tipologia.nome : "",
            marca_modello: this.state.dispositivo.marca_modello ? this.state.dispositivo.marca_modello : "",
            serial_number: this.state.dispositivo.serial_number ? this.state.dispositivo.serial_number : "",
            noleggio: this.state.dispositivo.noleggio ? "Sì" : "No",
            contrattonoleggio: contratto ? contratto.nome + ' - ' + contratto.codice : "",
            inMagazzino: this.state.dispositivo.inMagazzino ? "Sì" : "No",
            cespiti: this.state.dispositivo.cespiti ? "Sì" : "No",
            data_acquisto: this.state.dispositivo.data_acquisto ? this.state.dispositivo.data_acquisto : "",
            spec_tecniche: this.state.dispositivo.spec_tecniche ? this.state.dispositivo.spec_tecniche : "",
            note: this.state.dispositivo.note ? this.state.dispositivo.note : ""
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dispositivo in corso...' });

        const dispositivoPromise = magazzinoDispositiviService.getOne(this.props.match.params.id)
        const tipologiePromise = tipologiaDispositiviService.getAll();
        const statiPromise = statoDispositiviService.getAll()
        const contrattiPromise = magazzinoContrattiNoleggiService.getAll();

        // calling the needed data
        await Promise.all([dispositivoPromise, tipologiePromise, statiPromise, contrattiPromise])
            .then(
                values => {
                    this.setState(
                        {
                            dispositivo: values[0],
                            tipologie: values[1],
                            stati: values[2],
                            contratti: values[3]
                        },
                        () => {
                            const tipologie = mapListsData(this.state.tipologie)
                            const stati = mapListsData(this.state.stati)
                            const contratti = mapListsData(this.state.contratti)


                            this.setState({
                                formFields: dispositiviFormFields(stati, tipologie, contratti),
                                formInitialValues: this.initFormValues(),
                                attachmentsToUpload: []
                            }, () => EventBus.dispatch("hideLoader"))
                        }
                    )
                },
                error => {
                    EventBus.dispatch("hideLoader");
                }
            );
    }

    // method: handling edit device actions
    async handleUpdateDispositivo(values: IDispositivoForm) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        const tipologia = this.state.tipologie.find(tipologia => tipologia.nome === values.tipologia_nome)
        const stato = this.state.stati.find(stato => stato.nome === values.stato_nome)
        const contratto = this.state.contratti.find(contratto => contratto.nome + " - " + contratto.codice === values.contrattonoleggio)

        let formData = new FormData();
        const { attachmentsToUpload } = this.state;
        if (attachmentsToUpload) {
            let files = Array.from(attachmentsToUpload)
            files.forEach((file, i) => formData.append("attachment_" + i, file))
        }

        let dispositivoBody: IDispositivoBody = {
            tipologia_id: tipologia ? tipologia.id : 0,
            marca_modello: values.marca_modello,
            serial_number: values.serial_number,
            stato_id: stato ? stato.id : undefined,
            noleggio: values.noleggio === "Sì" ? 1 : (values.noleggio === "No" ? 0 : null),
            contrattinoleggio_id: contratto ? contratto.id : undefined,
            inMagazzino: values.inMagazzino === "Sì" ? 1 : (values.inMagazzino === "No" ? 0 : null),
            spec_tecniche: values.spec_tecniche,
            note: values.note,
            cespiti: values.cespiti === "Sì" ? 1 : (values.cespiti === "No" ? 0 : null),
            data_acquisto: values.data_acquisto
        }

        for (const key of Object.keys(dispositivoBody) as (keyof IDispositivoBody)[]) {
            formData.append(key, dispositivoBody[key]?.toString() ?? '');
        }

        // handle edit call
        await magazzinoDispositiviService.edit(this.props.match.params.id, formData).then(
            response => {
                EventBus.dispatch("hideLoader");
                Swal.fire(
                    'Operazione eseguita.',
                    '',
                    'success'
                ).then(
                    () => this.setState(
                        {
                            loading: false
                        },
                        () => this.props.history.push("/magazzino/dispositivi")
                    )
                );
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    // method: saving files in the state
    handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { attachmentsToUpload } = this.state
        let files = event.currentTarget.files ? Array.from(event.currentTarget.files) : []
        if (attachmentsToUpload.length === 0) {
            this.setState({ attachmentsToUpload: files }, () => { event.target.value = "" })
        } else {
            let concatArray = files.concat(attachmentsToUpload)
            // removing duplicates after merging new and old files
            let attachments = concatArray.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.name === value.name &&
                    t.size === value.size &&
                    t.type === value.type &&
                    t.lastModified === value.lastModified
                ))
            )
            this.setState({ attachmentsToUpload: attachments }, () => { event.target.value = "" })
        }
    }

    async downloadAttachment(id: number) {
        EventBus.dispatch("showLoader", { text: 'Download in corso...' });

        this.setState({
            loading: true
        })

        await documentiService.downloadDipendente(id).then(
            documento => {
                EventBus.dispatch("hideLoader");
                this.setState({
                    loading: true
                })

                let fileBlob = base64toBlob(documento.body, documento.mimetype)
                let url = window.URL.createObjectURL(fileBlob);
                let a = document.createElement('a');
                a.href = url;
                a.download = documento.filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            },
            () => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    async deleteAttachment(id: number) {
        EventBus.dispatch("showLoader", { text: 'Eliminazione in corso...' });

        this.setState({
            loading: true
        });

        await documentiService.delete(id).then(
            () => this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        dispositivo: {
                            ...prevState.dispositivo,
                            attachments: prevState.dispositivo.attachments.filter(a => a.id !== id)
                        }
                    }
                },
                () => {
                    EventBus.dispatch("hideLoader");
                    Swal.fire(
                        'Operazione eseguita.',
                        '',
                        'success'
                    );
                    this.setState({
                        loading: false
                    });
                }
            ),
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: rendering form filed based on type
    renderFormFields(field: IFormField, setFieldValue: (name: string, value: any, shouldValidate?: boolean | undefined) => void): JSX.Element {
        switch (field.type) {
            case 'select':
                return (
                    <Field as={field.type} name={field.name} className={field.class} disabled={this.state.disabledForm}>
                        <option key={''} value={''}>---</option>
                        {field.values?.map((item: ISelectOption) => {
                            return <option key={item.key} value={item.value}>{item.value}</option>
                        })}
                    </Field>
                );
            case 'date':
                return (<DatePickerForm name={field.name} className={field.class} readValue={String(field.value)} disabled={this.state.disabledForm} onChange={(state) => setFieldValue(field.name, state.toSend)} />);
            case 'file':
                const { attachmentsToUpload, dispositivo } = this.state
                return (
                    <React.Fragment>
                        <Field id={field.name} name={field.name} multiple={true} className={field.class} type={field.type} onChange={this.handleFileChange} disabled={this.state.disabledForm} />
                        <div className='d-flex'>
                            <div className='col-6 mt-3'>
                                <h4>File caricati</h4>
                                {
                                    dispositivo.attachments.length !== 0 ? dispositivo.attachments.map((file, index) => (
                                        <div className='d-flex align-items-center mb-1' key={index + '_' + Date.now()}>
                                            <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-1' type='button' disabled={this.state.disabledForm} onClick={() => this.deleteAttachment(file.id)}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button>
                                            <button style={buttonsStyle} className='btn btn-outline-primary rounded-circle me-2' type='button' disabled={this.state.disabledForm} onClick={() => this.downloadAttachment(file.id)} >
                                                <i style={iconsStyle} className="fa fa-download" aria-hidden="true"></i>
                                            </button>
                                            <span>
                                                {file.path}
                                            </span>
                                        </div>
                                    )) : <p className='mt-3'> Nessun file da visualizzare </p>
                                }
                            </div>

                            {
                                attachmentsToUpload.length !== 0 &&
                                <div className='col-6 mt-3'>
                                    <h4>File da caricare</h4>
                                    {attachmentsToUpload.map((file, index) => (
                                        <div className='d-flex align-items-center mb-1'>
                                            <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-2' type='button' disabled={this.state.disabledForm} onClick={() => {
                                                let files = attachmentsToUpload
                                                files.splice(files.indexOf(file), 1)
                                                this.setState({ attachmentsToUpload: files })
                                            }}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button>
                                            <span key={index + '_' + Date.now()}>
                                                {file.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>

                    </React.Fragment>
                );
            default:
                return (<Field name={field.name} type={field.type} className={field.class} disabled={this.state.disabledForm} />);
        }
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IDispositivoForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleUpdateDispositivo}
                    >
                        {({ setFieldValue }) => (
                            <Form className="card">
                                <div className="card-body">
                                    <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                        <h3>Dettaglio dispositivo</h3>
                                        <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                            {
                                                disabledForm ?
                                                    <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                    <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                            }
                                        </button>
                                    </div>

                                    {formFields.map(
                                        (field: IFormField, key) => {
                                            return <div className="mb-3" key={key}>
                                                <div className='col-12'>
                                                    <label className="form-label">{field.label}</label>
                                                    {
                                                        this.renderFormFields(field, setFieldValue)
                                                    }
                                                    <ErrorMessage
                                                        name={field.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    )}

                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                            {
                                                loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                            }
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}