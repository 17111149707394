import { ListColumns } from "../../helpers/interfaces/generic";
import { IUtente } from "../../helpers/interfaces/utente";
import { statusColors } from "../../helpers/settings/status-settings";

// table columns definitions
export const listUtentiColumns: ListColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "name" },
    { title: "Cognome", data: "lastname" },
    { title: "Email", data: "registrationemail" },
    { title: "Tipo utente", data: "idpersontype" },
    { title: "Ruolo", data: "role" },
    { title: "Stato", data: "status" },
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getListUtentiColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IUtente) => {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${id}"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
        {
            targets: listUtentiColumns.length - 1,
            render: (id: number, type: string, row: IUtente) => {
                switch (row.status.toLowerCase()) {
                    case 's':
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                    case 'd':
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }
            }
        }
    ];
}