import { IJobOrderExpiring } from '../../helpers/interfaces/home-amministrazione';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class CommesseService {
  getAll() {
    return axios.get(API_URL + 'v2/datore/joborders')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  getAllExpiring(): Promise<IJobOrderExpiring> {
    return axios.get<IJobOrderExpiring>(API_URL + 'datore/joborders/expiring')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  fromCustomer(id: number) {
    return axios.get(API_URL + 'datore/joborders/customer/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }
  all() {
    return axios.get(API_URL + 'datore/joborders')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }
  get(id: any) {
    return axios.get(API_URL + 'datore/joborder/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }
  put(id: any, data: any) {
    return axios.put(API_URL + 'datore/joborder/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }
  add(data: any) {
    return axios.post(API_URL + 'datore/joborder', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }
  delete(id: any) {
    return axios.delete(API_URL + 'datore/joborder/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }

  stop(id: string | number) {
    return axios.put(API_URL + 'datore/joborder/stop/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  renew(id: any, data: any) {
    return axios.post(API_URL + 'datore/joborder/renew', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  personAdd(data: any) {
    return axios.post(API_URL + 'datore/personjoborder', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  personAddList(body: any) {
    return axios.post(API_URL + 'datore/personjoborder/addlist', body)
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  personDelete(id: any) {
    return axios.delete(API_URL + 'datore/personjoborder/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }
  personGet(id: any) {
    return axios.get(API_URL + 'datore/personjoborder/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }
  personPut(id: any, data: any) {
    return axios.put(API_URL + 'datore/personjoborder/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }
}
export default new CommesseService();