import { AxiosResponse } from 'axios';
import { IServizioForm } from '../../config/formFields/magazzino/magazzino-servizi';
import { IMagazzinoServizio } from '../../helpers/interfaces/magazzino/magazzino-servizi';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class ServiziService {

  getAll(): Promise<IMagazzinoServizio[]> {
    return axios.get<IMagazzinoServizio[]>(API_URL + 'magazzino/servizi')
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getOne(id: string): Promise<IMagazzinoServizio> {
    return axios.get<IMagazzinoServizio>(API_URL + 'magazzino/servizi/' + id)
      .then((response: AxiosResponse<IMagazzinoServizio>) => { return response.data })
      .catch((error) => { return Promise.reject(); });
  }

  add(body: IServizioForm): Promise<IMagazzinoServizio> {
    return axios.post<IServizioForm, AxiosResponse<IMagazzinoServizio>>(API_URL + 'magazzino/servizi', body)
      .then((response: AxiosResponse<IMagazzinoServizio>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  edit(id: string, body: IServizioForm): Promise<IMagazzinoServizio> {
    return axios.put<IServizioForm, AxiosResponse<IMagazzinoServizio>>(API_URL + 'magazzino/servizi/' + id, body)
      .then((response: AxiosResponse<IMagazzinoServizio>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  personAdd(data: any) {
    return axios.post(API_URL + 'magazzino/person/servizi', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  personDelete(id: any) {
    return axios.delete(API_URL + 'magazzino/person/servizi/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }
}
export default new ServiziService();