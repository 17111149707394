import React, { Component } from 'react'
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import { ILettereDispositivi } from '../../../helpers/interfaces/home-amministrazione';
import homeAmministrazioneService from '../../../services/api/home-amministrazione.service';
import * as homeSettings from '../../../config/tableColumns/home-amministrazione';

type Props = {}

type State = {
    lettereDispositivi: ILettereDispositivi;
}

export class HomeLettereTab extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            lettereDispositivi: {
                assegnazioni: [],
                riconsegne: []
            }
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await homeAmministrazioneService.getAllLetters()
            .then(
                lettereDispositivi => this.setState(
                    {
                        lettereDispositivi
                    },
                    () => {
                        EventBus.dispatch("hideLoader")
                    }
                ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    render() {
        const { lettereDispositivi } = this.state
        const currentDom = "t<'d-flex justify-content-between align-items-center m-2'lip>"

        return (
            <div className='d-flex flex-wrap'>
                {
                    lettereDispositivi.assegnazioni.length > 0 && <div className='col-12 py-3 col-md-6 p-md-3'>
                        <h4 className='mb-3'>Assegnazioni</h4>
                        <Table id='tab_home_amministrazione_lettere_assignments' columns={homeSettings.listLettereColumns} columnDefs={homeSettings.getLettereColumnDefs()} datas={lettereDispositivi.assegnazioni} dom={currentDom} buttons={[]} lengthMenu={[5, 10]} />
                    </div>
                }
                {
                    lettereDispositivi.riconsegne.length > 0 && <div className="col-12 py-3 col-md-6 p-md-3">
                        <h4 className='mb-3'>Riconsegne</h4>
                        <Table id='tab_home_amministrazione_lettere_riconsegne' columns={homeSettings.listLettereColumns} columnDefs={homeSettings.getLettereColumnDefs()} datas={lettereDispositivi.riconsegne} dom={currentDom} buttons={[]} lengthMenu={[5, 10]} />
                    </div>
                }
            </div>
        )
    }
}

export default HomeLettereTab