import { AxiosResponse } from 'axios';
import { AdminAuthorization, AdminLog, AdminRoute, AdminSync, putAuthorizationsRequest, putAuthorizationsResponse } from '../../helpers/interfaces/admin';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class AdminService {
  async getLogs(): Promise<Array<AdminLog>> {
    return await axios.get(API_URL + 'admin/logs')
      .then((response: AxiosResponse<Array<AdminLog>>) => {
        return response.data; 
      })
      .catch((error: any) => { 
        return Promise.reject(error);
      });
  }

  async getRoutes(): Promise<Array<AdminRoute>> {
    return await axios.get(API_URL + 'admin/routes')
      .then((response: AxiosResponse<Array<AdminRoute>>) => {
        return response.data; 
      })
      .catch((error: any) => { 
        return Promise.reject(error);
      });
  }

  async getRoute(id: number): Promise<AdminRoute> {
    return await axios.get(API_URL + 'admin/routes/' + id)
      .then((response: AxiosResponse<AdminRoute>) => {
        return response.data; 
      })
      .catch((error: any) => { 
        return Promise.reject(error);
      });
  }

  async putRoute(routeId: number, data: any) {
    return axios.put(API_URL + 'admin/routes/' + routeId, {
      ...data
    })
    .then(response => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject();
    });
  }

  async getSync(): Promise<AdminSync> {
    return await axios.get(API_URL + 'admin/routes/sync')
      .then((response: AxiosResponse<any>) => {
        return response.data; 
      })
      .catch((error: any) => { 
        return Promise.reject(error);
      });
  }

  async getAuthorizations(): Promise<AdminAuthorization> {
    return await axios.get(API_URL + 'admin/personrole/routes')
      .then((response: AxiosResponse<any>) => {
        return response.data; 
      })
      .catch((error: any) => { 
        return Promise.reject(error);
      });
  }

  async putAuthorizations(roleId: number, data:putAuthorizationsRequest): Promise<putAuthorizationsResponse> {
    return axios.put(API_URL + 'admin/personrole/' + roleId + '/routes', {
      ...data
    })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject();
    });
  }
}

export default new AdminService();