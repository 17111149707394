import { HRDocumentsIssuer, HRDocumentsList, HRDocumentsPerson, HRDocumentsSollecitiList } from "../../helpers/interfaces/HRDocuments";

export const listHRDocumentsColumns = [
  { title: "", data: "id" },
  { title: "Inviata da", data: "person"},
  { title: "Emittente", data: "issuer"},
  { title: "Inviato il", data: "sentdate"},
  { title: "Ricevuto il", data: "receivedate"},
  { title: "Stato", data: "status"},
];

export function getHRDocumentsColumnDefs() {
  return [
    {
      targets: 0,
      render: (data: number, type: string, row: HRDocumentsList) => {
        return '<div class="d-flex"><input type="checkbox" name="ids[]" value="' + data + '" /></div>';
      },
    },
    {
      targets: 1,
      render: (data: HRDocumentsPerson, type: string, row: HRDocumentsList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
    {
      targets: 2,
      render: (data: HRDocumentsIssuer, type: string, row: HRDocumentsList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
  ]
}

export const listSollecitiHRDocumentsColumns = [
  { title: "Inviata da", data: "person"},
  { title: "Emittente", data: "issuer"},
  { title: "Inviato il", data: "sentdate"},
  { title: "Ricevuto il", data: "receivedate", defaultContent: "-"},
  { title: "Stato", data: "status"},
  { title: "Tag", data: "tag"}
];

export function getSollecitiHRDocumentsColumnDefs() {
  return [
    {
      targets: 0,
      render: (data: HRDocumentsPerson, type: string, row: HRDocumentsSollecitiList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
    {
      targets: 1,
      render: (data: HRDocumentsIssuer, type: string, row: HRDocumentsSollecitiList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
  ]
}
