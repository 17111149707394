import { AdminRoute } from "../../../helpers/interfaces/admin";
import { ListColumns } from "../../../helpers/interfaces/generic";

// table columns definitions
export const listAdminRoutesColumns: ListColumns[] = [
    { title: "Id", data: "id" },
    { title: "Pattern", data: "pattern" },
    { title: "Name", data: "name" },
    { title: "Method", data: "method" },
    { title: "Description", data: "description" },
];

// function: setting columns defs 
export function getAdminRoutesColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: AdminRoute): string => {
                const edit = '<span class="edit_btn action-icon" data-id="' + id + '"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>';

                return '<div>' + edit + '</div>'
            },
        },
    ];
}