import * as Yup from "yup";
import { FormFields } from "../../helpers/interfaces/generic";
import $ from 'jquery';

export function getSollecitoRendicontazioneFields(): Array<FormFields> {
  return [
    {
      label:'Nota: (facoltativa)',
      name:'note',
      type:'text',
      class:'form-control',
      validation:Yup.string().notRequired()
    },
    {
      label:'',
      name:'id',
      type:'hidden',
      class:'form-control',
      validation:Yup.string().required("Campo obbligatorio")
    },
    {
      label:'',
      name:'year',
      type:'hidden',
      class:'form-control',
      validation:Yup.string().required("Campo obbligatorio")
    },
    {
      label:'',
      name:'month',
      type:'hidden',
      class:'form-control',
      validation:Yup.string().required("Campo obbligatorio")
    }
  ];
}

export function getModalRendicontazioneFields(
  joborders: any,
  causali: any
): Array<FormFields> {
  return [
    {
      label:'Tipologia',
      name:'tipologia',
      type:'select',
      values:[{ key: 'commessa', value: 'Commessa' }, { key: 'causale', value: 'Causale' }],
      class:'form-control',
      validation:Yup.string().required("Campo obbligatorio")
    },
    {
      label:'Commessa',
      name:'commessa',
      type:'select',
      values:joborders,
      class:'form-select',
      showFromFieldName:'tipologia',
      showFromFieldValue:['commessa']
    },
    {
      label:'Causale',
      name:'causale',
      type:'select',
      values:causali,
      class:'form-select',
      showFromFieldName:'tipologia',
      showFromFieldValue:['causale']
    },
    {
      label:'Ore',
      name:'ore',
      type:'number',
      class:'form-control',
      validation:Yup.number().min(0).max(24).required("Campo obbligatorio")
    },
    {
      label:'Minuti',
      name:'minuti',
      type:'number',
      class:'form-control',
      validation:Yup.number().min(0).max(59).required("Campo obbligatorio")
    },
    {
      label:'Smart working',
      name:'smartworking',
      type:'checkbox',
      class:'form-check-input'
    },
    {
      label:'Nota',
      name:'note',
      type:'text',
      class:'form-control'
    },
    {
      label: 'Applica a tutti i giorni selezionati',
      name: 'all-days',
      type: 'checkbox',
      class: $('#rendicontazione .day.selected').length <= 1 ? 'form-check-input invisible' : 'form-check-input',
      value: true,
      labelClass: $('#rendicontazione .day.selected').length <= 1 ? 'invisible' : '',
    },
  ]
}