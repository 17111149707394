export const statusColorsLabels = [
    { color: 'rgb(158, 158, 158)', label: 'Non applicabile' },
    { color: 'rgb(211, 47, 47)', label: 'Assente' },
    { color: 'rgb(251, 192, 45)', label: 'In attesa' },
    { color: 'rgb(56, 142, 60)', label: 'Ok' }
]

export const statusColors = [
    { color: 'rgb(158, 158, 158)' },
    { color: 'rgb(251, 192, 45)' },
    { color: 'rgb(211, 47, 47)' },
    { color: 'rgb(56, 142, 60)' }
]