import React from 'react';
import eventBus from '../../common/EventBus';
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import { getAddTipoUtenteFormFields, getEditTipoUtenteFormFields } from '../../config/formFields/tipi-utente';
import getListTipiUtenteColumnDefs, { listTipiUtenteColumns } from '../../config/tableColumns/tipi-utente';
import { IFormField } from '../../helpers/interfaces/generic';
import { ITipoUtente } from '../../helpers/interfaces/tipi-utente';
import tipiUtenteService from '../../services/api/tipi-utente.service';

const $ = require('jquery')

type Props = {}

type State = {
  tipiUtente: ITipoUtente[],
  formFields: IFormField[]
  formInitialValues: { type: string, visible: number | undefined },
  showModal: boolean,
  modalType: string,
  modalTitle: string
}


export default class ElencoTipiUtente extends React.Component<Props, State> {

  apiSubmit: typeof tipiUtenteService.edit | typeof tipiUtenteService.add;

  constructor(props: Props) {
    super(props)

    this.state = {
      tipiUtente: [],
      formFields: [],
      formInitialValues: { type: '', visible: 0 },
      showModal: false,
      modalType: '',
      modalTitle: ''
    }

    this.apiSubmit = tipiUtenteService.add
    this.openAddModale = this.openAddModale.bind(this)
  }

  async componentDidMount(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento tipi utente in corso...' });

    await tipiUtenteService.getAll().then(
      tipiUtente => this.setState({ tipiUtente }, () => eventBus.dispatch("hideLoader")),
      () => eventBus.dispatch("hideLoader")
    )

    $('#table_tipi_utente.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();

      eventBus.dispatch("showLoader", { text: 'Caricamento tipo utente in corso...' });
      const tipoUtenteId = $(e.currentTarget).data('id');
      const tipoUtente = await tipiUtenteService.getOne(tipoUtenteId);
      if (tipoUtente) {
        this.setState(
          {
            formFields: getEditTipoUtenteFormFields(),
            formInitialValues: { ...tipoUtente, visible: tipoUtente.visible ? 1 : 0 }
          },
          () => {
            this.apiSubmit = tipiUtenteService.edit;
            this.setState(
              {
                showModal: true,
                modalType: 'edit',
                modalTitle: 'Modifica tipo utente'
              }
            );
          });
      }
      eventBus.dispatch("hideLoader");
    });

  }

  openAddModale() {
    this.apiSubmit = tipiUtenteService.add;
    this.setState(
      {
        formFields: getAddTipoUtenteFormFields(),
        formInitialValues: { type: '', visible: undefined },
        showModal: true,
        modalType: 'add',
        modalTitle: 'Aggiungi tipo utente'
      }
    );
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    const { tipiUtente, showModal, modalType, modalTitle, formFields, formInitialValues } = this.state

    return <div className="custom-container">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="card-title">Elenco tipi utente</h2>
            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
              <span>Aggiungi tipo</span>
            </button>
          </div>
          <ModalForm showModal={showModal} title={modalTitle} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
          <Table id="table_tipi_utente" columns={listTipiUtenteColumns} columnDefs={getListTipiUtenteColumnDefs()} datas={tipiUtente} buttons={null} />
        </div>
      </div>
    </div>
  }
}