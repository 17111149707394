import axios, { AxiosResponse } from "axios";
import { IServizioDaDisabilitare } from "../../../helpers/interfaces/magazzino/magazzino-home";
const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoHomeService {

    getAllServiceToDisable(): Promise<IServizioDaDisabilitare[]> {
        return axios.get<IServizioDaDisabilitare[]>(API_URL + 'magazzino/person/servizi/find-to-disable')
            .then((response: AxiosResponse<IServizioDaDisabilitare[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

    getAllLicencesToDisable(): Promise<any[]> {
        return axios.get<IServizioDaDisabilitare[]>(API_URL + 'magazzino/person/licenze/find-to-disable')
            .then((response: AxiosResponse<IServizioDaDisabilitare[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

}

export default new MagazzinoHomeService();