import React, { Component } from 'react'
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import { IServizioForm, serviziFormFields } from '../../../config/formFields/magazzino/magazzino-servizi';
import { IFormField } from '../../../helpers/interfaces/generic';
import serviziService from '../../../services/api/servizi.service';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';

type State = {
    formFields: IFormField[],
    loading: boolean,
    formInitialValues: IServizioForm
};


export default class MagazzinoNuovoServizio extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            formFields: [],
            loading: false,
            formInitialValues: {} as IServizioForm
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleAddServizio = this.handleAddServizio.bind(this)
    }

    // method: initializing the form values
    initFormValues(): IServizioForm {
        return {
            nome: "",
            code: ""
        }
    }

    componentDidMount(): void {
        this.setState(
            {
                formFields: serviziFormFields(),
                formInitialValues: this.initFormValues()
            },
            () => {
                EventBus.dispatch("hideLoader");
            }
        );
    }

    // method: handling add service actions
    async handleAddServizio(body: IServizioForm) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        // handle add call
        await serviziService.add(body).then(
            response => {
                this.setState(
                    {
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");
                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );

                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IServizioForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleAddServizio}
                    >
                        <Form className="card">
                            <div className="card-body">
                                <div className='col-12 pb-2'>
                                    <h3>Nuovo servizio</h3>
                                </div>

                                {formFields.map(
                                    (field: IFormField, key) => {
                                        return <div className="mb-3" key={key}>
                                            <div className='col-12'>
                                                <label className="form-label">{field.label}</label>
                                                <Field name={field.name} type={field.type} className={field.class} />

                                                <ErrorMessage
                                                    name={field.name}
                                                    component="div"
                                                    className="alert alert-danger"
                                                />
                                            </div>
                                        </div>
                                    }
                                )}

                                <div className="col-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {
                                            loading && <span className="spinner-border spinner-border-sm me-1"></span>
                                        }
                                        <span>Salva</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </React.Fragment>
            }
        </div>

    }
}