import { Component } from "react";

import Elenco from './elenco';
import Dashboard from './dashboard';

type Props = {
    history: any
};
type State = {
    currentTab: number
}
export default class Attestati extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentTab: 0
        }
    }

    render() {
        const { currentTab } = this.state;
        return (
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <h3 className="card-title">Attestati</h3>
                        <ul className="nav nav-tabs" data-bs-toggle="tabs">
                            <li className="nav-item">
                                <a href="#dashboard" className="nav-link active" data-bs-toggle="tab" onClick={() => this.setState({ currentTab: 0 })}>Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a href="#elenco" className="nav-link" data-bs-toggle="tab" onClick={() => this.setState({ currentTab: 1 })}>Elenco</a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            {currentTab === 0 && <div className="tab-pane active show" id="dashboard">
                                <Dashboard />
                            </div>}
                            {currentTab === 1 && <div className="tab-pane active show" id="elenco">
                                <Elenco />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}