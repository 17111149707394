import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import ModalForm from "../../common/ModalForm";
import { Tab } from "../../common/TabComponent";
import { getNewTimetableFormFields } from "../../config/formFields/timetable";
import { FormFields } from "../../helpers/interfaces/generic";
import { GroupAll, groupList } from "../../helpers/interfaces/group";
import groupsService from "../../services/api/groups.service";
import timetableService from "../../services/api/timetable.service";
import authService from "../../services/auth.service";
import ElencoScadenze from "./components/elenco-scadenze";
import InScadenza from "./components/in-scadenza";
import * as Yup from "yup";
import Gestite from "./components/gestite";
import NonGestite from "./components/non-gestite";

type Props = {};

type State = {
  formFields: Array<FormFields>;
  formInitialValues: {
    deadline: string,
    note: string,
    type: string
  },
  showModal: boolean,
  modalType: string,
  loading: boolean;
  routes: Array<string | null>;
  groups: Array<groupList>;
}

export default class Scadenzario extends Component<Props, State> {

  apiSubmit: typeof timetableService.add;

  constructor(props: Props) {
    super(props)

    this.state = {
      formFields: [],
      loading: false,
      formInitialValues: {
        deadline: '',
        note: '',
        type: ''
      },
      showModal: false,
      modalType: 'add',
      routes: [],
      groups: []
    }

    this.apiSubmit = timetableService.add
    this.openAddModale = this.openAddModale.bind(this)
  }

  openAddModale() {
    const currentUser = authService.getCurrentUser();

    if (currentUser) {
      this.setState(
        {
          routes: currentUser.routes,
          formFields: getNewTimetableFormFields()
        },
        async () => {
          let formFields = this.state.formFields;
          let formInitialValues = this.state.formInitialValues
          if (!currentUser.routes.includes('amministrazione-groups-list')) {
            formFields = formFields.map(field => {
              if (field.name === 'type') {
                formInitialValues['type'] = "Private"
                return { ...field, type: "unset", validation: null }
              } else {
                return field
              }
            });
          } else {
            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
            const getGroups: Array<GroupAll> = await groupsService.getAll();

            if (getGroups) {
              let groups = getGroups.map((group: GroupAll) => { return { key: group.id, value: group.nome } });
              formFields.push(
                {
                  label: 'Gruppo',
                  name: 'group',
                  type: 'select',
                  values: groups,
                  class: 'form-select',
                  validation: Yup.string().when('type', {
                    is: (value: string) => value === "Group",
                    then: Yup.string().required('Campo obbligatorio')
                  }),
                  showFromFieldName: 'type',
                  showFromFieldValue: ['Group']
                })
              this.setState({ groups }, () => EventBus.dispatch("hideLoader"));
            }
          }
          this.setState(
            {
              formFields,
              formInitialValues,
              showModal: true
            }
          );
        }
      )
    }
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render(): JSX.Element {
    const { showModal, modalType, formFields, formInitialValues } = this.state
    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="card-title m-0">Scadenzario</h2>
              <button type="button" className="btn btn-outline-primary" onClick={this.openAddModale}>
                <span>Aggiungi scadenza</span>
              </button>
            </div>
            <ModalForm showModal={showModal} title={'Nuova scadenza'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
            <Tab useState={true} tabs={[
              {
                id: 'tab_in_scadenza',
                label: "In scadenza",
                content: <InScadenza />,
                isActive: true,
              },
              {
                id: 'tab_non_gestite',
                label: "Non gestite",
                content: <NonGestite />,
                isActive: false,
              },
              {
                id: 'tab_gestite',
                label: "Gestite",
                content: <Gestite />,
                isActive: false,
              },
              {
                id: 'tab_scadenze',
                label: "Tutte le scadenze",
                content: <ElencoScadenze />,
                isActive: false,
              }
            ]} />
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}