import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getEditDocumentoFormFields(): Array<FormFields> {
  return [
    {
      'label': 'Nome',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Note',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Tipo',
      'name': 'type',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Scadenza',
      'name': 'expire_date',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    }
  ];
}