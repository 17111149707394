import React, { Component } from "react";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

import EventBus from "../../common/EventBus";
import ReportsService from "../../services/api/reports.service";
import { getRapportiniCommesseFormFields } from "../../config/formFields";
import { openBase64NewTab } from '../../common/Base64';
import Swal from "sweetalert2";
import { FormFields, FormFieldsValue } from "../../helpers/interfaces/generic";
import moment from "moment";
import "moment/locale/it";
import { numberRange } from "../../common/NumberRanger";

type Props = {
    renderField: (item: FormFields, key: number, formik: FormikProps<any>) => false | JSX.Element,
    persons: FormFieldsValue[],
    joborders: FormFieldsValue[],
    joborders_all: any[],
};
type State = {
    loading: boolean,
    formFields: Array<FormFields>,
    years: FormFieldsValue[],
    months: FormFieldsValue[],
    year: string,
    joborders: FormFieldsValue[],
    disabledForm: boolean,
    formInitialValues: { [key: string]: any },
}
export default class RapportiniCommesse extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            years: [],
            months: [],
            year: '',
            joborders: [],
            disabledForm: true,
            formInitialValues: {},
        }
    }

    componentDidMount(): void {
        const years_all = numberRange(2016, moment().year()).reverse();
        let years: FormFieldsValue[] = years_all.map((item: any) => { return { key: item, value: item } });

        const months_all = numberRange(1, 12);
        let months: FormFieldsValue[] = months_all.map((item: any) => { return { key: item, value: item } });

        this.setState({
            formFields: getRapportiniCommesseFormFields(years, months, this.props.joborders, this.updateYearField.bind(this)),
            joborders: this.props.joborders,
            years,
            months
        });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.joborders !== this.props.joborders) {
            const {years, months} = this.state;

            this.setState({ formFields: getRapportiniCommesseFormFields(years, months, this.props.joborders, this.updateYearField.bind(this)) });
        }
    }

    updateYearField(
        year: string
    ): void {
        const {years, months} = this.state;
        let joborders_all;
        const startYear = moment(year + '-01-01').year();
        const endYear = moment(year + '-12-31').year();

        if (year) {
            joborders_all = this.props.joborders_all.filter((joborder, index: number) => {
                return (joborder.start && moment(joborder.start).year() === startYear) || 
                    (joborder.end && moment(joborder.end).year() === endYear) ||
                    ((joborder.start && moment(joborder.start).year() <= startYear) && (!joborder.end || (joborder.end && moment(joborder.end).year() >= endYear)))
            })
        } else {
            joborders_all = this.props.joborders_all;
        }

        const joborders = joborders_all.map((item) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } });
        this.setState({year, joborders, formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this)) });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    async handleUpdate(formValue: any) {
        EventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });
        const report: any = await ReportsService.joborder(formValue.year, formValue.month, formValue.joborder);
        if (typeof report.body !== 'undefined') {
            openBase64NewTab(report);
        } else {
            this.setState({
                loading: false,
            }, () => {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la generazione del report.',
                    icon: 'error',
                });
            });
        }
        EventBus.dispatch("hideLoader");
    }

    render() {
        const { loading, formFields } = this.state;
        let initialValues: any = {};
        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate}
            >
                {(formik: FormikProps<any>) => {
                    return <Form className="px-3 pt-3">
                        {formFields && formFields.map((item: FormFields, key: number) => {
                            return <div className="form-group mb-3 row" key={key}>

                                {this.props.renderField(item, key, formik)}

                                <ErrorMessage
                                    name={item.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        })}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                )}
                                <span>Genera</span>
                            </button>
                        </div>
                    </Form>
                }}

            </Formik>
        </React.Fragment>
    }
}