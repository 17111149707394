import { StatusDipendente } from '../../helpers/interfaces/permessiferie';
import axios from '../api';
import { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class StatiPermessiFerieService {
  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  getAll() {
    this.controller = new AbortController();
    return axios.get<{ id: number, nome: string, code: string }[]>(API_URL + 'datore/permessi-ferie/stati', {
      signal: this.controller.signal
    })
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getAllDipendente(): Promise<Array<StatusDipendente>> {
    return axios.get(API_URL + 'dipendente/permessi-ferie/stati')
      .then((response: AxiosResponse<Array<StatusDipendente>>) => {
        return Array.isArray(response.data) ? response.data : [];
      })
      .catch((error) => { return Promise.reject(error); });
  }

  abort() {
    this.controller.abort();
  }
}
export default new StatiPermessiFerieService();