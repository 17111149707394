import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Table from "../../common/TableComponent";
import { listGroupColumns } from "../../config/tableColumns";
import { groupAddPersonResponse, GroupAll, GroupDetails, Subscribers } from "../../helpers/interfaces/group";
import GroupService from "../../services/api/groups.service";
import Swal from "sweetalert2";
import groupsService from "../../services/api/groups.service";
import ModalForm from "../../common/ModalForm";
import { getNewGroupFormFields } from "../../config/formFields";
import Modal from "../../common/Modal";
import { listNotSubscribersGroupColumns, listSubscribersGroupColumns } from "../../config/tableColumns/groups";
import personService from "../../services/api/person.service";
import { IPersonForSearch } from "../../helpers/interfaces/person";
import { FormFields } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {};

type State = {
  group: Array<GroupAll | never>;
  subscribers: Array<Subscribers | never>;
  subscribersModal: boolean;
  groupID: number | null;
  notSubscribers: Array<IPersonForSearch>;
  subscribersAdded: Array<number>;
  addModal: boolean;
  editModal: boolean;
  formFields: Array<FormFields>;
  formInitialValues: { [key: string]: any };
}

export default class ElencoGruppi extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      group: [],
      subscribers: [],
      subscribersModal: false,
      groupID: null,
      notSubscribers: [],
      subscribersAdded: [],
      addModal: false,
      editModal: false,
      formFields: [],
      formInitialValues: {},
    }
  }

  async componentDidMount() {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco gruppi in corso...' });

    this.updateHandler();

    const formFields = await getNewGroupFormFields();

    if (formFields) {
      this.setState({ formFields });
    }

    $('#table_group.table').on('click', '.subscribers_btn', async (e: any) => {
      e.preventDefault();
      const groupID: number = $(e.currentTarget).data('id');
      const group: GroupDetails = await groupsService.get(groupID);

      if (group && typeof group.error === 'undefined') {
        this.setState({ subscribers: group.subscribers, subscribersModal: true });
      } else {
        Swal.fire({
          title: 'Errore',
          text: typeof group.error !== 'undefined'
            ? group.error
            : 'Si è verificato un errore imprevisto e non è stato possibile caricare la lista dei iscritti',
          icon: 'error',
        })
      }
    });

    $('#table_group.table').on('click', '.add_btn', async (e: any) => {
      e.preventDefault();
      const persons = await personService.getAllForSearch();
      const groupID: number = $(e.currentTarget).data('id');
      const group: GroupDetails = await groupsService.get(groupID);

      if (persons && persons.data && group && typeof group.error === 'undefined') {
        const notSubscribers: Array<IPersonForSearch | never> = [];
        for (let index = 0; index < persons.data.length; index++) {
          let isSubscribed = false;
          const element = persons.data[index];

          for (let index = 0; index < group.subscribers.length; index++) {
            if (element.id === group.subscribers[index].id) {
              isSubscribed = true;
            }
          }

          if (!isSubscribed) {
            notSubscribers.push(element);
          }
        };

        this.setState({ notSubscribers, groupID, addModal: true });
      } else {
        Swal.fire({
          title: 'Errore',
          text: 'Si è verificato un errore imprevisto e non è stato possibile caricare la lista del personale',
          icon: 'error',
        })
      }
    });

    $('#table_group.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      const groupID = $(e.currentTarget).data('id');
      const group: any = await groupsService.get(groupID);

      this.setState({ editModal: true, formInitialValues: group })
    });

    $('#table_group.table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: 'Sei sicuro di voler eliminare questo gruppo?',
        //text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#85d630',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Elimina',
        cancelButtonText: 'Annulla'
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const groupID = $(e.currentTarget).data('id');
            const group: any = await groupsService.delete(groupID);

            if (group && typeof group.error !== 'undefined') {
              Swal.fire(
                group.error,
                '',
                'error'
              );
            } else {
              await Swal.fire({
                title: "Gruppo eliminato con successo",
                icon: "success",
                timer: 2500,
                showConfirmButton: false,
              });

              eventBus.dispatch("showLoader", { text: 'Caricamento elenco gruppi in corso...' });

              this.updateHandler();

              eventBus.dispatch("hideLoader");
            }
          }
        });
    });

    eventBus.dispatch("hideLoader");
  }

  async updateHandler(): Promise<void> {
    await GroupService.getAll()
      .then((value: Array<GroupAll | never>) => {
        if (value) {
          this.setState({ group: value });
        }
      })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista dei gruppi.',
          icon: 'error',
        })
      });
  }

  closeSubscribersModal() {
    this.setState({ subscribersModal: false });
  }

  closeAddModal() {
    this.setState({ addModal: false, subscribersAdded: [] });
  }

  closeEditModal() {
    this.setState({ editModal: false });
  }

  addSubscribers() {
    $('#table_addSubscribers.table').on('click', '.add_subscriber_btn', async (e: any) => {
      e.preventDefault();
      let { groupID, subscribersAdded } = this.state;
      const personID: number = $(e.currentTarget).data('id');
      if (groupID && personID && !subscribersAdded.includes(personID)) {
        const addPerson: groupAddPersonResponse & { error: string } = await groupsService.addPerson({ groupID, personID });

        if (addPerson && typeof addPerson.error === 'undefined') {
          subscribersAdded.push(personID);
          // $(e.currentTarget).removeAttribute('href');
          // $(e.currentTarget).style.color = '#888';
          this.setState({ subscribersAdded });
        } else {
          Swal.fire({
            title: 'Errore',
            text: typeof addPerson.error !== 'undefined'
              ? addPerson.error
              : 'Si è verificato un errore imprevisto e non è stato possibile aggiungere questa persona alla lista dei iscritti',
            icon: 'error',
          })
        }
      } else {
        Swal.fire({
          title: 'Errore',
          text: 'Questa persona è già iscritta a questo gruppo',
          icon: 'error',
        })
      }
    });
  }

  render(): JSX.Element {
    const { group, subscribers, subscribersModal, addModal, notSubscribers, editModal, formFields, formInitialValues } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Elenco gruppi</h2>
            <Modal id="subscribers" showModal={subscribersModal} title={'Elenco iscritti'} closeCallback={this.closeSubscribersModal.bind(this)} type={''} content={subscribers.length
              ? (
                <Table id="table_subscribers" columns={listSubscribersGroupColumns} columnDefs={null} datas={subscribers} buttons={null} lengthChange={false} lengthMenu={[10]} />)
              : (
                <span>Nessun iscritto a questo gruppo</span>
              )
            } />
            <Modal id="add_subscribers" showModal={addModal} title={'Aggiungi iscritti'} closeCallback={this.closeAddModal.bind(this)} type={''} content={
              <>
                <Table id="table_addSubscribers" columns={listNotSubscribersGroupColumns} columnDefs={null} datas={notSubscribers} buttons={null} lengthChange={false} lengthMenu={[10]} didMount={this.addSubscribers.bind(this)} />
                {!notSubscribers.length && <span>Nessuna persona da iscrivere a questo gruppo</span>}
              </>
            } />
            <ModalForm showModal={editModal} title={'Modifica gruppo'} modalType={'edit'} formFields={formFields} initialValues={formInitialValues} apiSubmit={GroupService.edit} closeCallback={this.closeEditModal.bind(this)} successAction={this.updateHandler.bind(this)} />
            <Table id="table_group" columns={listGroupColumns} columnDefs={null} datas={group} buttons={null} />
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}