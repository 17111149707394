import { Device, DeviceEmail } from "../../../../../helpers/interfaces/device";

export const listDispositiviColumns = [
    {
        title: 'Azioni',
        data: 'id'
    },
    {
        title: 'Proprietario',
        width: 'auto',
        data: 'tipologia'
    },
    {
        title: 'Tipologia',
        width: 'auto',
        data: 'tipologia_nome'
    },
    {
        title: 'Marca Modello',
        width: 'auto',
        data: 'marca_modello'
    },
    {
        title: 'Serial Number',
        width: 'auto',
        data: 'serial_number'
    },
    {
        title: 'Data Consegna',
        width: 'auto',
        data: 'data_consegna'
    },
    {
        title: 'Data Restituzione',
        width: 'auto',
        data: 'data_restituzione'
    },
    {
        title: 'Note',
        width: 'auto',
        data: 'note'
    }
];

export function getListDispositiviColumnDefs(tipologiadispositivo: Array<string>) {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: number, type: any, row: Device) {
                let trash = '';
                if ((!row.emails.A?.status || (row.emails.A?.status && row.emails.A.status === 'S')) && (!row.emails.R?.status || (row.emails.R?.status && row.emails.R.status === 'S'))){
                    trash = `<a class="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}" title="Elimina associazione personale/dispositivo">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </a>`;
                }

                const edit = `<a class="edit_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${data}" title="Modifica associazione personale/dispositivo">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
        {
            targets: 1,
            //data: null,
            render: function (data: number, type: any, row: Device) {
                return tipologiadispositivo[data];
            },
        },
        {
            targets: 5,
            render: function (data: string | undefined, type: any, row: Device) {
                return getEmail(data, type, row, row.emails.A, 'assegnazione');
            },
        },
        {
            targets: 6,
            render: function (data: string | undefined, type: any, row: Device) {
                return getEmail(data, type, row, row.emails.R, 'riconsegna');
            },
        },
    ];
}

function getEmail(
    data: string | undefined,
    type: any,
    row: Device,
    email: DeviceEmail | undefined,
    action: 'assegnazione' | 'riconsegna',
) {
    let status: string = '';
    
    if (data && email?.status === 'A') {
        status = '<i class="fa fa-circle" title="Approvata" style="color: #00ff00"></i>'
    } else if (data && email?.status === 'R') {
        status = '<i class="fa fa-circle" title="Ricevuta" style="color: #ffff00"></i>'
    } else if (data && email?.status === 'S') {
        status = '<i class="fa fa-circle" title="Inviata" style="color: #666666"></i>'
    } else if (data && !email?.status) {
        status = '<i class="fa fa-circle" title="Assente" style="color: #ff0000"></i>'
    }
    

    let actions = '';
    if (data && (!email?.status || email?.status === 'S')) {
        actions += '<span class="' + (action === 'assegnazione' ? 'device_consegna_btn' : 'device_riconsegna_btn') + '" data-id="' + row.id + '" title="Invia email"><i class="action-icon fa fa-envelope sendEmail"></i></span>';
    } else if (data && email?.status === 'R') {
        actions += '<span class="device_download_document_btn" data-id="' + email.documento_id + '" title="Scarica documento"><i class="action-icon fa fa-download downloadAttachment"></i></span> ';
        actions += '<span class="device_validate_email_btn" data-id="' + email.id + '" data-action="' + action + '" title="Valida documento"><i class="action-icon fa fa-check-circle validate"></i></span>';
    }

    const result = '<div>' + (data ?? '') + ' ' + status + '</div><div>' + actions + '</div>';

    return result;
}