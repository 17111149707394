import React, { Component } from 'react'
import Swal from 'sweetalert2';
import eventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent'
import { getListReadOnlyTimetableColumnDefs, listReadOnlyTimetableColumns } from '../../../config/tableColumns/timetable'
import { ITimetable } from '../../../helpers/interfaces/timetable';
import { orderTimetableByDate } from '../../../helpers/settings/date-settings';
import timetableService from '../../../services/api/timetable.service';

type Props = {};

type State = {
    timetable: Array<ITimetable | null>;
    showManagedModal: boolean;
    showDeleteModal: boolean;
}
export class ElencoScadenze extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            timetable: [],
            showManagedModal: false,
            showDeleteModal: false,
        }
    }

    async componentDidMount() {
        eventBus.dispatch("showLoader", { text: 'Caricamento scadenzario in corso...' });

        await timetableService.getAll()
            .then(
                value => {
                    if (value) {
                        this.setState({ timetable: orderTimetableByDate(value) });
                    }
                },
                (error) => {
                    Swal.fire({
                        title: 'Errore',
                        text: error ?? 'A causa di un errore non è stato possibile caricare l\'elenco delle scadenze.',
                        icon: 'error',
                    });
                }
            ).catch(() => {
                Swal.fire({
                    title: 'Errore imprevisto',
                    text: 'A causa di un errore imprevisto non è stato possibile caricare l\'elenco delle scadenze.',
                    icon: 'error',
                });
            });

        eventBus.dispatch("hideLoader");
    }

    render() {
        const { timetable } = this.state
        return <Table id="table_timetable" columns={listReadOnlyTimetableColumns} columnDefs={getListReadOnlyTimetableColumnDefs()} datas={timetable} buttons={null} />
    }
}

export default ElencoScadenze