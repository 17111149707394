import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class PersonTypeService {
  controller: AbortController;
  constructor() {
    this.controller = new AbortController();
  }
  getAll() {
    this.controller = new AbortController();
    return axios.get(API_URL + 'datore/persontype', {
      signal: this.controller.signal
    })
    .then(response => { return Array.isArray(response.data) ? response.data : []; })
    .catch((error) => { return Promise.reject(); });
  }
  abort() {
    this.controller.abort();
  }
}
export default new PersonTypeService();