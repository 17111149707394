import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class AnagraficaService {
  update(personId:Number, data:any) {
    return axios.put(API_URL + 'datore/person/'+personId+'/anagrafica', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return Promise.reject(); });
  }
}
export default new AnagraficaService();