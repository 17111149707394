import React, { Component } from "react";
import EventBus from "../../../common/EventBus";

import StatoRendicontazioneService from "../../../services/api/statorendicontazione.service";
import { listStatoRendicontazioneColumns } from "../../../config/tableColumns";
import Table from '../../../common/TableComponent';

type Props = {
    history: any
};

type State = {
    loading: boolean,
    message: string,
    stati: any
}

export default class StatoRendicontazione extends Component<Props, State> {
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            stati: [],
            loading: false,
            message: ""
        }
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const stati = await StatoRendicontazioneService.getAll();
        if (stati.length > 0) {
            this.setState({ stati });
        }
        EventBus.dispatch("hideLoader");
    }

    render() {
        const { stati } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">Stato rendicontazione</h2>
                        {
                            stati.length > 0 && <Table id="table_stati" columns={listStatoRendicontazioneColumns} columnDefs={[]} datas={stati} buttons={this.buttons} />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}