import React, { Component } from 'react'
import { contrattiFormFields, IContrattoForm } from '../../../config/formFields/magazzino/magazzino-contratti';
import { IFormField } from '../../../helpers/interfaces/generic';
import { IMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import * as Yup from 'yup'
import EventBus from '../../../common/EventBus';
import magazzinoContrattiNoleggiService from '../../../services/api/magazzino/magazzino-contratti-noleggi.service';
import Swal from 'sweetalert2';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePickerForm from '../../../common/DatePickerForm';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    contratto: IMagazzinoContrattoNoleggio,
    formFields: IFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: IContrattoForm
}

export default class MagazzinoDettaglioContratto extends Component<Props, State>{

    constructor(props: Props) {
        super(props)
        this.state = {
            contratto: {} as IMagazzinoContrattoNoleggio,
            formFields: [],
            loading: false,
            disabledForm: true,
            formInitialValues: {} as IContrattoForm
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateContratto = this.handleUpdateContratto.bind(this);
    }

    // method: initializing the form values
    initFormValues(): IContrattoForm {
        return {
            nome: this.state.contratto.nome,
            codice: this.state.contratto.codice,
            data_inizio_noleggio: this.state.contratto.data_inizio_noleggio,
            data_fine_noleggio: this.state.contratto.data_fine_noleggio
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento contratto in corso...' });
        await magazzinoContrattiNoleggiService.getOne(this.props.match.params.id)
            .then(
                contratto =>
                    this.setState(
                        {
                            contratto
                        },
                        () => {
                            EventBus.dispatch("hideLoader")
                            this.setState({
                                formFields: contrattiFormFields(),
                                formInitialValues: this.initFormValues()
                            })
                        }
                    ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    // method: handling edit contract actions
    async handleUpdateContratto(body: IContrattoForm) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        // handle edit call
        await magazzinoContrattiNoleggiService.edit(this.props.match.params.id, body).then(
            response => {
                this.setState(
                    {
                        contratto: response,
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");

                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );
                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IContrattoForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleUpdateContratto}
                    >
                        {({ setFieldValue }) => (
                            <Form className="card">
                                <div className="card-body">
                                    <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                        <h3>Dettaglio contratto</h3>
                                        <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                            {
                                                disabledForm ?
                                                    <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                    <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                            }
                                        </button>
                                    </div>

                                    {formFields.map(
                                        (field: IFormField, key) => {
                                            return <div className="mb-3" key={key}>
                                                <div className='col-12'>
                                                    <label className="form-label">{field.label}</label>
                                                    {
                                                        field.type === 'date' ?
                                                            <DatePickerForm name={field.name} className={field.class} readValue={String(field.value)} disabled={disabledForm} onChange={(state) => setFieldValue(field.name, state.toSend)} />
                                                            : <Field name={field.name} type={field.type} className={field.class} disabled={disabledForm} />
                                                    }
                                                    <ErrorMessage
                                                        name={field.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            </div>
                                        }
                                    )}

                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                            {
                                                loading && <span className="spinner-border spinner-border-sm me-1"></span>
                                            }
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}