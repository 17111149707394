interface TimeToMins {
    (time: string): number;
}

interface TimeFromMins {
    (mins: number): string;
}

interface OperationTimes {
    (time0: string, time1: string): string;
}

const subTimes: OperationTimes = (time0: string, time1: string): string => {
    return timeFromMins(timeToMins(time0) - timeToMins(time1));
}

const addTimes: OperationTimes = (time0: string, time1: string): string => {
    return timeFromMins(timeToMins(time0) + timeToMins(time1));
}

const timeFromMins: TimeFromMins = (minutes: number): string => {
    const decimalCheck = (minute: number): string => {
        if (minute < 0) { return ('-0' + (minute).toString().slice(1)); }
        return (minute < 10 ? '0' : '') + minute;
    }
    var h = (minutes / 60 | 0);
    var m = minutes % 60;
    return decimalCheck(h) + ':' + decimalCheck(m);
}

const timeToMins: TimeToMins = (time: string): number => {
    const split: string[] = time.split(':');
    const hours: number = Number(split[0]);
    const minutes: number = Number(split[1]);
    return hours * 60 + +minutes;
}

export {subTimes, addTimes, timeFromMins, timeToMins};