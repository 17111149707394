import { Component } from 'react';
import { Tab, TabProp } from '../../common/TabComponent';
import EventBus from '../../common/EventBus';
import { IHomeCards, IHomeTab } from '../../helpers/interfaces/home-amministrazione';
import homeAmministrazioneService from '../../services/api/home-amministrazione.service';
import HomeMaterialeTab from './components/home-materiale-tab';
import HomeLettereTab from './components/home-lettere-tab';
import HomeCommesseTab from './components/home-commesse-tab';
import HomeDeadlinesTab from './components/home-deadlines-tab';

type Props = {}

type State = {
    cardsData: IHomeCards,
    lettere: IHomeTab,
    jobOrder: IHomeTab,
    materiale: IHomeTab,
    deadlines: IHomeTab
}

export default class HomeAmministrazione extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            cardsData: {
                anagrafiche: [],
                email: [],
                patrono: [],
                permessiferie: 0
            },
            lettere: {
                nome: '',
                tab: 0,
                badge: 0
            },
            jobOrder: {
                nome: '',
                tab: 0,
                badge: 0
            },
            materiale: {
                nome: '',
                tab: 0,
                badge: 0
            },
            deadlines: {
                nome: '',
                tab: 0,
                badge: 0
            }
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento della home in corso...' });

        // loading all data
        const cardsDataPromise = homeAmministrazioneService.getAllCardsData();
        const tabsPromise = homeAmministrazioneService.getAllTabs();

        await Promise.all([cardsDataPromise, tabsPromise]).then(
            values => this.setState(
                {
                    cardsData: values[0],
                    lettere: values[1][2],
                    jobOrder: values[1][0],
                    materiale: values[1][3],
                    deadlines: values[1][1]
                },
                () => EventBus.dispatch("hideLoader")
            ),
            error => EventBus.dispatch("hideLoader")
        )
    }

    render() {
        const { cardsData, jobOrder, materiale, lettere, deadlines } = this.state

        let tabsBadge = jobOrder.badge + materiale.badge + lettere.badge + deadlines.badge

        // set tabs config
        let homeTabs: TabProp[] = []

        if (jobOrder.badge !== 0)
            homeTabs.push(
                {
                    id: 'tab_home_amministrazione_expiring',
                    label: `Commesse in scadenza`,
                    hasBadge: true,
                    badgeValue: jobOrder.badge,
                    content: <HomeCommesseTab />,
                    isActive: false
                }
            )
        if (deadlines.badge !== 0)
            homeTabs.push(
                {
                    id: 'tab_home_amministrazione_deadlines',
                    label: "Deadlines",
                    hasBadge: true,
                    badgeValue: deadlines.badge,
                    content: <HomeDeadlinesTab />,
                    isActive: false
                }
            )
        if (lettere.badge !== 0)
            homeTabs.push(
                {
                    id: 'tab_home_amministrazione_lettere',
                    label: "Lettere dispositivi",
                    hasBadge: true,
                    badgeValue: lettere.badge,
                    content: <HomeLettereTab />,
                    isActive: false
                }
            )
        if (materiale.badge !== 0)
            homeTabs.push(
                {
                    id: 'tab_home_amministrazione_materiale',
                    label: "Materiale non riconsegnato",
                    hasBadge: true,
                    badgeValue: materiale.badge,
                    content: <HomeMaterialeTab />,
                    isActive: false
                }
            )

        if (homeTabs.length > 0)
            homeTabs[0].isActive = true

        return (
            <div className="custom-container">
                <div className='card'>
                    <div className='card-body'>
                        <h2 className='card-title'>Home amministrazione</h2>

                        <div className='d-flex justify-content-around flex-column flex-md-row px-3'>
                            {
                                cardsData.permessiferie !== 0 && <div className="card col-12 col-md-3 my-2 my-md-0 mx-md-1">
                                    <div className="card-body text-center">
                                        <i style={{ fontSize: '40px' }} className="fa fa-calendar-o text-secondary mb-3" aria-hidden="true"></i>
                                        <h4>Richieste permessi/ferie</h4>
                                        <a style={{ border: 'none', background: 'unset', padding: '4px 6px', margin: '0 auto' }} href='/organico/permessi-ferie' className="badge badge-pill bg-primary">
                                            {cardsData.permessiferie} nuove
                                        </a>
                                    </div>
                                </div>
                            }
                            {
                                cardsData.anagrafiche.length !== 0 && <div className="card col-12 col-md-3 my-2 my-md-0 mx-md-1">
                                    <div className="card-body text-center">
                                        <i style={{ fontSize: '40px' }} className="fa fa-address-card text-secondary mb-3" aria-hidden="true"></i>
                                        <h4>Anagrafica mancante</h4>
                                        <div className="dropdown">
                                            <button style={{ border: 'none', background: 'unset', padding: '4px 6px', margin: '0 auto' }} className="badge badge-pill bg-primary nav-link dropdown-toggle" data-bs-toggle="dropdown" id="emailDropdown" aria-expanded="false">
                                                {cardsData.anagrafiche.length} rimanenti
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="emailDropdown">
                                                {
                                                    cardsData.anagrafiche.map(
                                                        element => {
                                                            return <a className="dropdown-item" key={element.id} href={'/organico/dettaglio-personale/' + element.id}>{element.nome}</a>
                                                        }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                cardsData.email.length !== 0 && <div className="card col-12 col-md-3 my-2 my-md-0 mx-md-1">
                                    <div className="card-body text-center">
                                        <i style={{ fontSize: '40px' }} className="fa fa-envelope text-secondary mb-3" aria-hidden="true"></i>
                                        <h4>Servizio e-mail mancante</h4>
                                        <div className="dropdown">
                                            <button style={{ border: 'none', background: 'unset', padding: '4px 6px', margin: '0 auto' }} className="badge badge-pill bg-primary nav-link dropdown-toggle" data-bs-toggle="dropdown" id="emailDropdown" aria-expanded="false">
                                                {cardsData.email.length} rimanenti
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="emailDropdown">
                                                {
                                                    cardsData.email.map(
                                                        element => {
                                                            return <a className="dropdown-item" key={element.id} href={'/organico/dettaglio-personale/' + element.id}>{element.nome}</a>
                                                        }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                cardsData.patrono.length !== 0 && <div className="card col-12 col-md-3 my-2 my-md-0 mx-md-1">
                                    <div className="card-body text-center">
                                        <i style={{ fontSize: '40px' }} className="fa fa-address-book text-secondary mb-3" aria-hidden="true"></i>
                                        <h4>Patrono mancante</h4>
                                        <div className="dropdown">
                                            <button style={{ border: 'none', background: 'unset', padding: '4px 6px', margin: '0 auto' }} className="badge badge-pill bg-primary nav-link dropdown-toggle" data-bs-toggle="dropdown" id="patronoDropdown" aria-expanded="false">
                                                {cardsData.patrono.length} rimanenti
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="patronoDropdown">
                                                {
                                                    cardsData.patrono.map(
                                                        element => {
                                                            return <a className="dropdown-item" key={element.id} href={'/organico/dettaglio-personale/' + element.id}>{element.nome}</a>
                                                        }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                        {
                            tabsBadge !== 0 ? <div className='mt-4'>
                                <Tab useState={true} tabs={homeTabs} />
                            </div> : <p>Nessun dato da mostrare</p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}