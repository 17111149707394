import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { Component } from 'react';
import { IAuthChangePassword } from '../helpers/interfaces/user';
import * as Yup from 'yup';
import authService from '../services/auth.service';
import EventBus from '../common/EventBus';
import Swal from 'sweetalert2';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    token: string,
    loading: boolean,
    message: string,
    initialValues: { password1: string, password2: string }
}

export class ChangePassword extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            token: "",
            loading: false,
            message: "",
            initialValues: { password1: "", password2: "" }
        };

        this.handleChangePassword = this.handleChangePassword.bind(this)
    }

    validationSchema() {
        return Yup.object().shape({
            password1: Yup.string().required("Campo obbligatorio").min(8, 'La password deve contenere minimo 8 caratteri'),
            password2: Yup.string().required("Campo obbligatorio").oneOf([Yup.ref('password1'), null], 'Le password devono coincidere')
        });
    }

    async handleChangePassword(values: { password1: string, password2: string }) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        const changePasswordBody: IAuthChangePassword = {
            ...values,
            token: this.props.match.params.id
        }

        await authService.resetPassword(changePasswordBody)
            .then(
                () => {
                    EventBus.dispatch("hideLoader");
                    Swal.fire(
                        'Operazione eseguita.',
                        '',
                        'success'
                    );

                    this.setState({
                        loading: false
                    });

                    this.props.history.push("/login");
                },
                error => {
                    EventBus.dispatch("hideLoader");

                    Swal.fire(
                        'Errore',
                        '',
                        'error'
                    );

                    this.setState({
                        loading: false
                    });
                }
            )
    }

    render() {
        const { initialValues, loading, message } = this.state

        return (
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="card card-md">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.handleChangePassword}
                        >
                            <Form>
                                <div className="card-body">
                                    <h3 className='mb-3'>Modifica la tua password</h3>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Nuova password
                                        </label>
                                        <Field name="password1" type="password" className="form-control" />
                                        <ErrorMessage
                                            name="password1"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            Ripeti password
                                        </label>
                                        <Field name="password2" type="password" className="form-control" />
                                        <ErrorMessage
                                            name="password2"
                                            component="div"
                                            className="alert alert-danger"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm me-1"></span>
                                            )}
                                            <span>Salva</span>
                                        </button>
                                    </div>
                                    {
                                        message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {message}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword