import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';
import { listBusinessunitColumns, getListBusinessunitColumnDefs } from "../../config/tableColumns/businessunit";
import { getBusinessunitFormFields } from "../../config/formFields/businessunit";
import Table from '../../common/TableComponent';
import { FormFields } from "../../helpers/interfaces/generic";
import { IBusinessUnit } from "../../helpers/interfaces/businessunit";
import BusinessunitService from "../../services/api/businessunit.service";
import Modal from "../../common/Modal";
import { base64Decode } from "../../common/Base64";

const $ = require('jquery');

type Props = {
    history: any
};

type State = {
    formFields: Array<FormFields>,
    loading: boolean,
    message: string,
    showModal: boolean,
    showInfoModal: boolean,
    infoContent: string,
    modalType: string,
    infoModalType: string,
    businessunit: IBusinessUnit[],
    formInitialValues: { [key: string]: any }
}
export default class BusinessUnit extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            businessunit: [],
            loading: false,
            message: "",
            showModal: false,
            showInfoModal: false,
            infoContent: '',
            modalType: 'add',
            infoModalType: '',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const businessunit = await BusinessunitService.getAll();
        if (businessunit.length > 0) {
            this.setState({ businessunit });
        }
        EventBus.dispatch("hideLoader");

        $('#table_businessunit.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento business unit in corso...' });
            const idBusinessunit = $(e.currentTarget).data('id');
            const businessunit: Partial<IBusinessUnit> | null = await BusinessunitService.get(idBusinessunit).then(res => {
                return { ...res, mailfooter: base64Decode(res?.mailfooter || ''), documentfooter: base64Decode(res?.documentfooter || '') }
            })

            if (businessunit) {
                this.setState({ formFields: getBusinessunitFormFields(), formInitialValues: businessunit }, () => {
                    this.apiSubmit = BusinessunitService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('table').on('click', '.info_btn', async (e: any) => {
            e.preventDefault();
            this.setState({ showInfoModal: true, infoModalType: 'html', infoContent: $(e.currentTarget).data('id') });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    closeInfoModal() {
        this.setState({ showInfoModal: false });
    }

    async openAddModale() {
        this.apiSubmit = BusinessunitService.add;
        this.setState({ formFields: getBusinessunitFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, businessunit, formInitialValues, showInfoModal, infoContent, infoModalType } = this.state;

        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>Business unit</h3>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi business unit</span>
                            </button>
                        </div>
                        <Modal showModal={showInfoModal} content={base64Decode(infoContent)} title={'Mostra dettagli'} type={infoModalType} closeCallback={this.closeInfoModal.bind(this)} />
                        <ModalForm showModal={showModal} title={'Business unit'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {businessunit.length > 0 && <Table id="table_businessunit" columns={listBusinessunitColumns} columnDefs={getListBusinessunitColumnDefs()} datas={businessunit} buttons={this.buttons} />}
                    </div>
                </div>
            </div>

        </React.Fragment>
    }
}