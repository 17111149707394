import React, { Component } from 'react'
import EventBus from '../../../common/EventBus'
import Table from '../../../common/TableComponent'
import getStatoDispositiviColumnDefs, { listStatoDispositiviColumns } from '../../../config/tableColumns/magazzino/magazzino-stato-dispositivi'
import { IMagazzinoStatoDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo'
import statodispositiviService from '../../../services/api/magazzino/magazzino-stato-dispositivi.service'

type State = {
    stati: IMagazzinoStatoDispositivo[]
}

export default class MagazzinoStatoDispositivi extends Component<{}, State> {
    constructor(props: {}) {
        super(props)

        this.state = {
            stati: []
        }
    }

    async componentDidMount() {
        // loading the contracts
        EventBus.dispatch("showLoader", { text: 'Caricamento degli stati in corso...' });

        await statodispositiviService.getAll().then(
            (stati) => this.setState({ stati })
        );

        EventBus.dispatch("hideLoader");
    }

    render() {
        const { stati } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Stato dispositivi</h2>
                                <a href="/magazzino/stato-dispositivi/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi stato</span>
                                </a>
                            </div>
                            {
                                stati.length > 0 &&
                                <Table id={'table_magazzino_contratti'} columns={listStatoDispositiviColumns} columnDefs={getStatoDispositiviColumnDefs()} datas={stati} buttons={buttons} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}