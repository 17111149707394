import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getEditVariabileContrattoFormFields(comuni: any): Array<FormFields> {

  return [
    {
      'label': 'Livello',
      'name': 'livello',
      'type': 'text',
      'class': 'form-control'
    },
    {
      'label': 'Ral',
      'name': 'ral',
      'type': 'number',
      'class': 'form-control'
    },
    {
      'label': 'Indennità',
      'name': 'indennita',
      'type': 'text',
      'class': 'form-control autoNumeric'
    },
    {
      'label': 'Tipo indennità',
      'name': 'tipo_indennita',
      'type': 'select',
      'values': [{ key: 'D', value: 'D' }, { key: 'M', value: 'M' }],
      'class': 'form-select',
      'validation': Yup.string()
    },
    {
      'label': 'Premio',
      'name': 'premio',
      'type': 'text',
      'class': 'form-control'
    },
    {
      'label': 'Nota',
      'name': 'nota',
      'type': 'text',
      'class': 'form-control'
    },
    {
      'label': 'Data aggiornamento',
      'name': 'data_aggiornamento',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.string()
    },
    {
      'label': 'Comune patrono',
      'name': 'comune_id',
      'type': 'select',
      'values': comuni,
      'class': 'form-select'
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
    {
      'label': '',
      'name': 'contratto_id',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}