import * as Yup from 'yup';

export function getEditUtenteFormFields(tipiUtente: any[], ruoli: any[]) {
    return [
        {
            label: 'Tipo utente',
            name: 'idpersontype',
            type: 'select',
            class: 'form-select',
            values: tipiUtente,
            validation: Yup.string().required('Campo obbligatorio')
        },
        {
            label: 'Ruolo',
            name: 'roles',
            type: 'select',
            class: 'form-select',
            values: ruoli,
            validation: Yup.string().required('Campo obbligatorio')
        }
        // {
        //     label: 'Ruolo',
        //     name: 'roles',
        //     type: 'multi-select',
        //     options: ruoli,
        //     class: 'form-control',
        //     validation: Yup.array().required('Campo obbligatorio')
        // }
    ]
}