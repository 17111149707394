
import { Component } from 'react'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

type Props = {
    name: string,
    className: string,
    readValue?: string,
    disabled?: boolean,
    onChange: (value: State) => void
}

type State = {
    value: string,
    forSelected: Date | null,
    toSend: string
}

export default class DatePickerForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            value: "",
            forSelected: null,
            toSend: ""
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(): void {
        if (this.props.readValue) {
            this.setState({
                value: this.props.readValue,
                forSelected: new Date(this.props.readValue),
                toSend: this.props.readValue
            })
        }

    }

    handleChange(value: Date | null) {

        if (value) {
            this.setState({
                forSelected: value,
                value: value?.toLocaleDateString() ?? "",
                toSend: new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString()
            }, () => {
                this.props.onChange(this.state)
            })
        }
    }

    render() {
        return (
            <DatePicker
                name={this.props.name}
                selected={this.state.forSelected || null}
                onChange={(value) => this.handleChange(value)}
                dateFormat={["dd/MM/yyyy", "dd-MM-yyyy"]}
                className={this.props.className}
                disabled={this.props.disabled}
            />
        )
    }

}