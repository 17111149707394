import axios, { AxiosResponse } from "axios";
import { IProdottoForm } from "../../../config/formFields/magazzino/magazzino-prodotti";
import { IMagazzinoProdotto } from "../../../helpers/interfaces/magazzino/magazzino-prodotti";


const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoLicenzeProdotti {

    getAll(): Promise<IMagazzinoProdotto[]> {
        return axios.get<IMagazzinoProdotto[]>(API_URL + 'magazzino/licenze/prodotti')
            .then((response: AxiosResponse<IMagazzinoProdotto[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

    getOne(id: string): Promise<IMagazzinoProdotto> {
        return axios.get<IMagazzinoProdotto>(API_URL + 'magazzino/licenze/prodotto/' + id)
            .then((response: AxiosResponse<IMagazzinoProdotto>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    add(body: IProdottoForm): Promise<IMagazzinoProdotto> {
        return axios.post<IProdottoForm, AxiosResponse<IMagazzinoProdotto>>(API_URL + 'magazzino/licenze/prodotto', body)
            .then((response: AxiosResponse<IMagazzinoProdotto>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

    edit(id: string, body: IProdottoForm): Promise<IMagazzinoProdotto> {
        return axios.put<IProdottoForm, AxiosResponse<IMagazzinoProdotto>>(API_URL + 'magazzino/licenze/prodotto/' + id, body)
            .then((response: AxiosResponse<IMagazzinoProdotto>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

}

export default new MagazzinoLicenzeProdotti()