import { AxiosResponse } from 'axios';
import { IRuoloUtente, IUtente } from '../../helpers/interfaces/utente';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class UtentiService {

    getAll(): Promise<IUtente[]> {
        return axios.get<IUtente[]>(API_URL + "admin/users")
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    get(id: string | number): Promise<IUtente> {
        return axios.get<IUtente>(API_URL + "admin/user/" + id)
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    getRoles(): Promise<IRuoloUtente[]> {
        return axios.get<IRuoloUtente[]>(API_URL + 'admin/personrole')
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    edit(id: string | number, body: { idpersontype: number, roles: number }): Promise<IUtente> {
        return axios.put<{ idpersontype: number, roles: number[] }, AxiosResponse<IUtente>>(API_URL + "admin/user/" + id, { idpersontype: body.idpersontype, roles: [body.roles] })
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

}

export default new UtentiService();