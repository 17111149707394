import React from 'react';
import adminService from '../../services/api/admin.service';
import { history } from '../../helpers/history';
import eventBus from '../../common/EventBus';

export default class SincronizzaRotte extends React.Component {
  async componentDidMount(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    await adminService.getSync();

    eventBus.dispatch("hideLoader");

    history.push("/admin/sicurezza/elenco-autorizzazione");
  }

  render() {
    return <></>
  }
}