import React from 'react';
import { AdminLog } from './../../helpers/interfaces/admin';
import eventBus from '../../common/EventBus';
import adminService from './../../services/api/admin.service';
import Table from '../../common/TableComponent';
import { getAdminLogsColumnDefs, listAdminLogsColumns } from './../../config/tableColumns/admin/admin-logs';

type Props = {
  //
};

type State = {
  adminLogs: Array<AdminLog>;
};

export default class HomeAdmin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      adminLogs: []
    }
  }

  async componentDidMount() {
    eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    const adminLogs = await adminService.getLogs();
    if (adminLogs.length > 0) {
      this.setState({ adminLogs });
    }

    eventBus.dispatch("hideLoader");
  }

  render() {
    const { adminLogs } = this.state;

    return <div className="custom-container">
      <div className='card'>
        <div className='card-body'>
          <h2 className="card-title">Home admin</h2>
          <Table id="admin_logs" columns={listAdminLogsColumns} columnDefs={getAdminLogsColumnDefs()} datas={adminLogs} buttons={[]} order={[[0, 'desc']]} />
        </div>
      </div>
    </div>
  }
}