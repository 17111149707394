import axios from '../api';
import { AxiosResponse } from 'axios';
import { IOreSettimanaliRequest, IOreSettimanaliResponse } from '../../helpers/interfaces/ore-settimanali';

const API_URL = process.env.REACT_APP_API_URL;

class WorkHoursService {
  async getAll(): Promise<IOreSettimanaliResponse[]> {
    try {
      const response: AxiosResponse<IOreSettimanaliResponse> = await axios.get(
        API_URL + 'datore/workhours/list'
      );
      return Array.isArray(response.data) ? response.data : [];
    } catch (error: any) {
      return await Promise.reject(error);
    }
  }

  async getOne(id: number): Promise<IOreSettimanaliResponse|null> {
    try {
      const response: AxiosResponse<IOreSettimanaliResponse> = await axios.get(
        API_URL + 'datore/workhours/' + id
      );
      return typeof response.data == 'object' ? response.data : null;
    } catch (error: any) {
      return await Promise.reject(error);
    };
  }

  async add(data: IOreSettimanaliRequest): Promise<IOreSettimanaliResponse> {
    try {
      const response: AxiosResponse<IOreSettimanaliResponse> = await axios.post(
        API_URL + 'datore/workhours', { ...data }
      );
      return response.data;
    } catch (error: any) {
      return await Promise.reject(error);
    }
  }

  async edit(id: number, data: IOreSettimanaliRequest): Promise<IOreSettimanaliResponse> {
    try {
      const response: AxiosResponse<IOreSettimanaliResponse> = await axios.put(
        API_URL + 'datore/workhours/' + id, {
          domenica: data.domenica,
          lunedi: data.lunedi,
          martedi: data.martedi,
          mercoledi: data.mercoledi,
          giovedi: data.giovedi,
          venerdi: data.venerdi,
          sabato: data.sabato,
        }
      );
      return response.data;
    } catch (error: any) {
      return await Promise.reject();
    }
  }
}

export default new WorkHoursService();