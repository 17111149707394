import React, { Component } from 'react'
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import getContrattiColumnDefs, { listContrattiColumns } from '../../../config/tableColumns/magazzino/magazzino-contratti';
import { IMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import magazzinoContrattiNoleggiService from '../../../services/api/magazzino/magazzino-contratti-noleggi.service';

type State = {
    contratti: IMagazzinoContrattoNoleggio[]
}

export default class MagazzinoContratti extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            contratti: []
        }
    }

    async componentDidMount() {
        // loading the contracts
        EventBus.dispatch("showLoader", { text: 'Caricamento dei contratti in corso...' });

        await magazzinoContrattiNoleggiService.getAll().then(
            (contratti) => this.setState(
                { contratti },
                () => EventBus.dispatch("hideLoader")
            ),
            error => EventBus.dispatch("hideLoader")
        )


    }

    render() {
        const { contratti } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Contratti noleggio</h2>
                                <a href="/magazzino/contratti/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi contratto noleggio</span>
                                </a>
                            </div>
                            {
                                contratti.length > 0 &&
                                <Table id={'table_magazzino_contratti'} columns={listContrattiColumns} columnDefs={getContrattiColumnDefs()} datas={contratti} buttons={buttons} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}