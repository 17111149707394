import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getAddCommesseFormFields(commesse: any): Array<FormFields> {
  return [
    {
      'label': 'Commessa',
      'name': 'joborderid',
      'type': 'search',
      'values': commesse,
      'class': 'form-select',
      'required': true,
      'placeholder': 'Cerca una commessa...',
      'validation': Yup.string().required('Commessa è un campo obbligatorio')
    },
    {
      'label': 'Data Inizio',
      'name': 'startdate',
      'type': 'date',
      'class': 'form-control',
      'required': true,
      'validation': Yup.date().required('Data Inizio è un campo obbligatori')
    },
    {
      'label': 'Data Fine',
      'name': 'enddate',
      'type': 'date',
      'class': 'form-control',
      'required': true,
      'validation': Yup.date().required('Data Fine è un campo obbligatorio')
    },
    {
      'label': '',
      'name': 'personid',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}