import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormFields } from "../../helpers/interfaces/generic";

export function getEditTitoloStudioFormFields(): Array<FormFields> {
  return [
    {
      'label': 'Nome',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}

export function getAddTitoloStudioFormFields(): Array<FormFields> {
    return [
      {
        'label': 'Nome',
        'name': 'nome',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      }
    ];
  }