import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Swal from "sweetalert2";
import Select from 'react-select'
import personService from "../../services/api/person.service";
import $ from 'jquery';
import nomineService from "../../services/api/nomine.service";
import { NomineInviaResponse } from "../../helpers/interfaces/nomine";
import { base64Encode } from "../../common/Base64";
// import { encodeChars } from "../../helpers/charConverter";

type Props = {};

type State = {
  options: Array<Option>;
  person: Option | null;
  file: string | ArrayBuffer | null;
  loading: boolean;
};

type Option = {
  value: any,
  label: string
};

export default class InviaNomina extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: [],
      person: null,
      file: null,
      loading: false,
    }
  }

  async componentDidMount() {
    this.updateHandler();

    $('div#form-send').on('click', 'input#submit', async (e) => {
      this.setState({ loading: true });

      const { person, file } = this.state;

      if (!person || !file) {
        this.setState({ loading: false });
        return Swal.fire(
          'Campi non compilati',
          'Per favore compila tutti i campi obbligatori del modulo.',
          'warning'
        )
      }

      await nomineService.send({
        id: person.value,
        file
      }).then(
        (result: NomineInviaResponse) => {
          Swal.fire({
            title: 'Nomina inviata',
            text: 'La nomina è stata inviata con successo.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              await this.updateHandler();
              this.setState({ person: null });
              $('input#document').val('');
            }
          });
        },
        (error) => {
          Swal.fire({
            title: 'Errore',
            text: error ?? 'A causa di un errore non è stato possibile inviare la nomina.',
            icon: 'error',
          });
        }
      ).catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile inviare la nomina.',
          icon: 'error',
        });
      })

      this.setState({ loading: false });
      return false;
    });

    $('div#form-send').on('change', 'input#document', (e) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result?.toString();
        console.log('original text: ' + result);
        this.setState({ file: base64Encode(result ?? '') });
      };
      //reader.readAsDataURL($('input#document').prop('files')[0]);
      // reader.readAsDataURL(e.target.files[0]);
      reader.readAsText(e.target.files[0], 'utf-8');
      return false;
    })
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco personale in corso...' });
    this.setState({ loading: true });

    await personService.getAllForSearch()
      .then(
        (result) => {
          let options: Array<Option> = [];
          result.data.forEach(element => {
            return options.push({ value: element.id, label: element.lastname + ' ' + element.name });
          });

          this.setState({ options });
        }, (error: string) => {
          Swal.fire({
            title: 'Errore',
            text: error ?? 'A causa di un errore non è stato possibile caricare la lista del personale.',
            icon: 'error',
          })
        })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista del personale.',
          icon: 'error',
        })
      });

    this.setState({ loading: false });
    eventBus.dispatch("hideLoader");
  }

  render(): JSX.Element {
    const { options, person, loading } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Invia una nomina</h3>
            <div id="form-send">
              <h4>Seleziona personale: *</h4>
              <Select
                id="person"
                name="person"
                options={options}
                styles={{ container: (style) => ({ ...style, width: 'auto', minWidth: 0, maxWidth: 200 }) }}
                required={true}
                onChange={(input) => {
                  input?.label && this.setState({ person: { label: input?.label, value: input?.value } ?? '' })
                }}
                value={person}
              />
              <br />
              <h4>Carica documento nomina: *</h4>
              <input id="document"
                name="document"
                type="file"
                required={true}
              />
              <br /><br />
              <input id="submit" className="btn btn-info" type="submit" value="Invia" disabled={loading} />
              <br /><br />
              <strong>* = Campi obbligatori</strong>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}