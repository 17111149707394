import React, { Component } from 'react'
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import { IDeadlines } from '../../../helpers/interfaces/home-amministrazione';
import deadlinesService from '../../../services/api/deadlines.service';
import * as homeSettings from '../../../config/tableColumns/home-amministrazione';

type Props = {};

type State = {
    deadlines: IDeadlines;
}

export class HomeDeadlinesTab extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            deadlines: {
                noleggi: [],
                fine_prova: [],
                fine_contratto: [],
                attestati: [],
                adempimenti: [],
                apprendisti: [],
                documenti: [],
                attestati_mancanti: []
            },
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await deadlinesService.getAll()
            .then(
                deadlines => this.setState(
                    {
                        deadlines
                    },
                    () => {
                        EventBus.dispatch("hideLoader")
                    }
                ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    // method: render deadlines tables
    renderDeadlines(): JSX.Element {
        const currentDom = "t<'d-flex justify-content-between align-items-center m-2'lip>"
        const { deadlines } = this.state
        let deadlinesLabels = Object.keys(deadlines)
        let deadlinesLabelsToShow: string[] = []
        deadlinesLabels.forEach(label => { return label !== 'attestati' ? deadlinesLabelsToShow.push(label) : deadlinesLabelsToShow.push('attestati_in_scadenza') })
        const deadlinesData = Object.values(deadlines)

        return (
            <div className='d-flex flex-wrap'>
                {
                    deadlinesData.map(
                        (value: Array<any>, index) => {
                            const currentLabelToShow = deadlinesLabelsToShow[index]
                            const currentLabel = deadlinesLabels[index]
                            return value.length > 0 && <div className='col-12 py-3 col-md-6 p-md-3' key={index + '_' + Date.now()}>
                                <h4 className='mb-3'>{currentLabelToShow.charAt(0).toUpperCase() + currentLabelToShow.slice(1).toLowerCase().replaceAll("_", " ")}</h4>
                                <Table id={'tab_home_amministrazione_deadlines_' + currentLabel} columns={homeSettings.setCorrectDeadlinesColumns(this.state.deadlines, currentLabel)} columnDefs={homeSettings.setCorrectDeadlinesColumnsDef(this.state.deadlines, currentLabel)} datas={value} buttons={[]} dom={currentDom} lengthMenu={[5, 10]} />
                            </div>
                        }
                    )
                }
            </div>
        )
    }

    render() {
        return this.renderDeadlines()
    }
}

export default HomeDeadlinesTab