import { ListColumns } from "../../helpers/interfaces/generic";
import { INotifica } from "../../helpers/interfaces/notifica";
import { statusColors } from "../../helpers/settings/status-settings";

// table columns definitions
export const listNotificheColumns: ListColumns[] = [
    { title: "Seleziona", data: "id" },
    { title: "Autore", data: "author" },
    { title: "Messaggio", data: "message" },
    { title: "Data", data: "created_at" },
    { title: "Letta?", data: "readed" }
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getNotificheColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: INotifica) => {
                const checkbox = `<div class="form-check">
                    <input class="check_btn form-check-input" type="checkbox" value="" data-id="${row.id}">
                </div>`;

                return (row.readed === false ? '<div class="d-flex">' + checkbox + '</div>' : '');
            },
        },
        {
            targets: 1,
            render: (data: string, type: string, row: INotifica) => {
                return row.readed ? data : '<strong>' + data + '</strong>';
            }
        },
        {
            targets: 2,
            render: (data: string, type: string, row: INotifica) => {
                return row.readed ? data : '<strong>' + data + '</strong>';
            }
        },
        {
            targets: 3,
            render: (data: string, type: string, row: INotifica) => {
                return row.readed ? data : '<strong>' + data + '</strong>';
            }
        },
        {
            targets: listNotificheColumns.length - 1,
            render: (id: number, type: string, row: INotifica) => {
                switch (row.readed) {
                    case false:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }
            }
        }
    ];
}