import React, { Component } from "react";
import EventBus from "../../../common/EventBus";
import AttestatiService from "../../../services/api/attestati.service";
import {
    AttestatiDashboardCompletoColumns,
    AttestatiDashboardCompletoColumnDefs,
    AttestatiDashboardScadenzaColumns,
    AttestatiDashboardMancantiColumns
} from "../../../config/tableColumns";
import Table from '../../../common/TableComponent';

type Props = {

};
type State = {
    elenco_completo: any,
    attestati_scadenza: any,
    gruppo_attestati: any,
    gruppo_tot_attestati: number
}
export default class Dashboard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            elenco_completo: [],
            attestati_scadenza: [],
            gruppo_attestati: [],
            gruppo_tot_attestati: 0
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento Dashboard in corso...' });
        const dashboard: any = await AttestatiService.dashboard();
        if (dashboard) {
            const gruppo_attestati: any = [];
            let gruppo_tot_attestati = 0;
            if (dashboard.attestati_mancanti.length > 0) {
                dashboard.attestati_mancanti.forEach((attestato: any) => {
                    Object.keys(attestato.by_contract).forEach((key: any) => {
                        const value = key.split('_');
                        if (typeof gruppo_attestati[value[1]] == 'undefined') {
                            gruppo_attestati[value[1]] = [];
                        }
                        if (
                            (attestato.by_contract.missing_idoneita && value[1] === 'idoneita') ||
                            (attestato.by_contract.missing_sicurezza && value[1] === 'sicurezza') ||
                            (value[1] === 'apprendistato' && attestato.by_contract.missing_apprendistato)
                        )
                            gruppo_attestati[value[1]].push(attestato);

                        gruppo_tot_attestati++;
                    });
                    Object.keys(attestato.by_ruolo).forEach((key: any) => {
                        const value = attestato.by_ruolo[key];
                        if (typeof gruppo_attestati[value] == 'undefined') { gruppo_attestati[value] = []; }
                        gruppo_attestati[value].push(attestato);
                        gruppo_tot_attestati++;
                    });
                });
            }
            this.setState({
                elenco_completo: dashboard.elenco_completo,
                attestati_scadenza: dashboard.attestati_scadenza,
                gruppo_attestati,
                gruppo_tot_attestati
            });
        }
        EventBus.dispatch("hideLoader");
    }

    render() {
        const { elenco_completo, attestati_scadenza, gruppo_attestati, gruppo_tot_attestati } = this.state;

        return <div className="mt-3">
            <ul className="nav nav-tabs mb-3" data-bs-toggle="tabs">
                <li className="nav-item">
                    <a href="#mancanti" className="nav-link active" data-bs-toggle="tab">Mancanti ({gruppo_tot_attestati})</a>
                </li>
                <li className="nav-item">
                    <a href="#scadenza" className="nav-link" data-bs-toggle="tab">In scadenza ({attestati_scadenza.length})</a>
                </li>
                <li className="nav-item">
                    <a href="#elenco" className="nav-link" data-bs-toggle="tab">Elenco assegnazioni ({elenco_completo.length})</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane active show" id="mancanti">
                    {
                        Object.keys(gruppo_attestati).map((key: string, index) => {
                            return <div key={key.replace(/ /g, '_')}>
                                <h4>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</h4>
                                <Table id={'table_' + key.replace(/ /g, '_')} columns={AttestatiDashboardMancantiColumns} columnDefs={AttestatiDashboardCompletoColumnDefs()} datas={gruppo_attestati[key]} buttons={null} />
                                {index !== Object.keys(gruppo_attestati).length - 1 && <hr />}
                            </div>
                        })
                    }
                </div>
                <div className="tab-pane" id="scadenza">
                    <Table id="table_dashboard_scadenza" columns={AttestatiDashboardScadenzaColumns} columnDefs={AttestatiDashboardCompletoColumnDefs()} datas={attestati_scadenza} buttons={null} />
                </div>
                <div className="tab-pane" id="elenco">
                    <Table id="table_dashboard_completo" columns={AttestatiDashboardCompletoColumns} columnDefs={AttestatiDashboardCompletoColumnDefs()} datas={elenco_completo} buttons={null} />
                </div>
            </div>
        </div>
    }
}