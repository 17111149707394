import { AxiosResponse } from 'axios';
import { ITipoUtente } from '../../helpers/interfaces/tipi-utente';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class TipiUtenteService {

    getAll(): Promise<ITipoUtente[]> {
        return axios.get(API_URL + "admin/persontype")
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    getOne(id: number): Promise<ITipoUtente> {
        return axios.get(API_URL + "admin/persontype/" + id)
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    add(body: { type: string, visible: boolean }): Promise<ITipoUtente> {
        return axios.post<{ type: string, visible: boolean }, AxiosResponse<ITipoUtente>>(API_URL + "admin/persontype", body)
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    edit(id: number, body: { type: string, visible: boolean }): Promise<ITipoUtente> {
        return axios.put<{ type: string, visible: boolean }, AxiosResponse<ITipoUtente>>(API_URL + "admin/persontype/" + id, body)
            .then(response => response.data)
            .catch(err => Promise.reject());
    }
}

export default new TipiUtenteService()