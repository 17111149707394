import React from 'react';
import eventBus from '../../common/EventBus';
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import { getEditUtenteFormFields } from '../../config/formFields/utenti';
import getListUtentiColumnDefs, { listUtentiColumns } from '../../config/tableColumns/utenti';
import { IFormField } from '../../helpers/interfaces/generic';
import { IUtente } from '../../helpers/interfaces/utente';
import tipiUtenteService from '../../services/api/tipi-utente.service';
import utentiService from '../../services/api/utenti.service';

const $ = require('jquery')

type Props = {}

type State = {
  utenti: IUtente[],
  formFields: IFormField[]
  formInitialValues: {},
  showModal: boolean,
  modalType: string
}
export default class ElencoUtenti extends React.Component<Props, State> {

  apiSubmit: typeof utentiService.edit;

  constructor(props: Props) {
    super(props)

    this.state = {
      utenti: [],
      formFields: [],
      formInitialValues: {},
      showModal: false,
      modalType: ''
    }

    this.apiSubmit = utentiService.edit
  }

  async componentDidMount(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento utenti in corso...' });

    await utentiService.getAll().then(
      utenti => this.setState({ utenti }, () => eventBus.dispatch("hideLoader")),
      () => eventBus.dispatch("hideLoader")
    )

    $('#table_utenti.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();

      eventBus.dispatch("showLoader", { text: 'Caricamento utente in corso...' });
      const utenteId = $(e.currentTarget).data('id');
      const utente = await utentiService.get(utenteId);
      const tipiUtente = await tipiUtenteService.getAll();
      const ruoliUtente = await utentiService.getRoles();
      if (utente) {
        const allTypes = tipiUtente.map(tipo => { return { key: tipo.id, value: tipo.type } })
        // const allRoles = ruoliUtente.map(ruolo => { return { label: ruolo.role, value: ruolo.id } })
        const allRoles = ruoliUtente.map(ruolo => { return { key: ruolo.id, value: ruolo.role } })
        // let newUtente = { ...utente, roles: utente.roles.map(role => { return { value: role.id, label: role.name } }) }
        this.setState(
          {
            formFields: getEditUtenteFormFields(allTypes, allRoles),
            formInitialValues: { ...utente, roles: utente.roles.map(role => role.id)[0] }
          },
          () => {
            this.setState(
              {
                showModal: true,
                modalType: 'edit'
              }
            );
          });
      }
      eventBus.dispatch("hideLoader");
    });

  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    const { utenti, showModal, modalType, formFields, formInitialValues } = this.state

    return <div className="custom-container">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Elenco utenti</h2>
          <ModalForm showModal={showModal} title={'Modifica utente'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
          <Table id="table_utenti" columns={listUtentiColumns} columnDefs={getListUtentiColumnDefs()} datas={utenti} buttons={null} />
        </div>
      </div>
    </div>
  }
}