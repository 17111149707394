import React from 'react';
import { Route } from '../helpers/interfaces/generic';
import { history } from '../helpers/history';
import $ from 'jquery';

type Props = {
  route: Route & {
    computedMatch: {
      path: string;
      url: string;
      isExact: boolean;
      params: { [key: string]: any };
    },
    location: {
      pathname: string;
      search: string;
      hash: string;
    }
  },
};

type State = {};

export default class Breadcrumb extends React.Component<Props, State> {
  pathnameParts: string[];
  matchParts: string[];

  constructor(props: Props) {
    super(props);

    let pathname = window.location.pathname;

    if (pathname.substring(pathname.length - 1) === '/') {
      pathname = pathname.slice(0, pathname.length - 1);
    }

    this.pathnameParts = pathname.split('/');
    this.matchParts = this.props.route.computedMatch.path.split('/');
  }

  render() {
    let link = '';

    return <nav aria-label="breadcrumb">
      <ol className="breadcrumb py-3">
        {(
          this.pathnameParts.map((part, index) => {
            let label = '';
            if (part !== '') {
              link += '/' + part;
              label = part;
            }

            if (this.matchParts[index + 1]?.charAt(0) === ':') {
              label = part + ' (' + this.pathnameParts[index + 1] + ')';
            }

            if (this.matchParts[index]?.charAt(0) === ':') {
              return null;
            }

            if ((index + 1) === this.pathnameParts.length || (this.matchParts[index + 1]?.charAt(0) === ':' && index === (this.pathnameParts.length - 2))) {
              return <li key={index} className="breadcrumb-item active" aria-current="page">{label}</li>
            }

            return <li key={index} className="breadcrumb-item" aria-current="page"><a href={link ? link : '/'} data-link={link} onClick={e => {
              e.preventDefault();
              history.push($(e.currentTarget).data('link'));
            }}>
              {label !== '' ? label : <i className="fa fa-home"></i>}
            </a>
            </li>;
          })
        )}
      </ol>
    </nav>
  }
}