import { AxiosResponse } from 'axios';
import { INotifica, IStatusNotifica } from '../../helpers/interfaces/notifica';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class NotificheService {

    getAll(): Promise<{ [key: string]: INotifica[] }> {
        return axios.get<{ [key: string]: INotifica[] }>(API_URL + "notifications")
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    getAllForMenu(): Promise<{ badge: { [key: string]: IStatusNotifica[] }, count: number }> {
        return axios.get<{ badge: { [key: string]: IStatusNotifica[] }, count: number }>(API_URL + "notifications/status")
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

    edit(body: { readed: number[] }): Promise<IStatusNotifica> {
        return axios.put<{ readed: number[] }, AxiosResponse<IStatusNotifica>>(API_URL + "notifications", body)
            .then(response => response.data)
            .catch(err => Promise.reject());
    }

}

export default new NotificheService();