export const listCommesseColumns = [
    { "title": "Azioni", "data": "id" },
    { "title": "Cliente", "data": "customer" },
    { "title": "Com. padre", "data": "parentJoborder" },
    { "title": "Nome", "data": "name" },
    { "title": "Codice", "data": "code" },
    { "title": "Cod. ordine", "data": "order_code" },
    {
        "title": "Assegnatari", "data": null, render: (data: any, type: any, row: any) => {
            const persons = Object.keys(row.persons).map((key: any) => row.persons[key].name);
            return persons.join(', ');
        }
    },
    {
        "title": "Tar. oraria ?", "data": null, render: (data: any, type: any, row: any) => {
            return row.rateIsHourly ? 'Si' : 'No';
        }
    },
    {
        "title": "Tariffa", "data": null, render: (data: any, type: any, row: any) => {
            return (row.rate ? row.rate : '0') + '&euro;';
        }
    },
    {
        "title": "Giornate (ore)", "data": null, render: (data: any, type: any, row: any) => {
            return row.days && (row.days + (row.hours ? ' (' + row.hours + ')' : ''))
        }
    },
    // { "title": "Ore utilizzate", "data": "hours_used" },
    { "title": "Inizio", "data": "start" },
    { "title": "Scadenza", "data": "expired" }
];

export function getListCommesseColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const assign = `<a class="assign_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-group" aria-hidden="true"></i></a>`;
                const trash = row.persons.length === 0 ? `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>` : '';
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;
                const stop = `<a class="stop_btn custom-icon btn btn-outline-secondary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-hand-paper-o" aria-hidden="true"></i></a>`;
                const renew = row.parentJoborder === '' ? `<a class="renew_btn custom-icon btn btn-outline-success rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-refresh" aria-hidden="true"></i></a>` : ``;

                return '<div class="d-flex">' + assign + edit + renew + stop + trash + '</div>';
            },
        },
    ];
}