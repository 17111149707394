// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Component, CSSProperties } from "react";
import { history } from "../helpers/history";
import Swal from "sweetalert2";

export type TabProp = {
  id: string;
  label: string;
  content: JSX.Element;
  isActive?: boolean;
  hasBadge?: boolean;
  badgeValue?: number;
  args?: {[key: string | number]: any}
}

type Props = {
  id?: string
  tabs: Array<TabProp>;
  style?: CSSProperties;
  navStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  useState?: boolean;
  updateHash?: boolean;
  canChangeTab?: boolean;
  tabClickHandler?: (currentTab: number, args?: TabProp["args"]) => Promise<void>;
};

type State = {
  currentTab: number;
};

export class Tab extends Component<Props, State> {
  hashtab: number | null;

  constructor(props: Props) {
    super(props)

    this.hashtab = null;

    this.state = {
      currentTab: 0,
    }
  }

  async componentDidMount(): Promise<void> {
    let currentTab = 0;
    this.props.tabs.forEach((value, index) => {
      if (value.isActive && !this.hashtab) {
        currentTab = index;
      }

      if (window.location.hash === '#' + value.id) {
        currentTab = index;
        this.hashtab = index;
      }
    });

    this.setState({currentTab});
  }

  render(): JSX.Element {
    const { id, tabs, style, navStyle, contentStyle, useState, canChangeTab = true, tabClickHandler } = this.props;
    const { currentTab } = this.state;

    return <>
      <div className="tabbable" id={'tabs-' + (id ? id : 'generic')} style={style}>
        <ul className="nav nav-tabs" style={navStyle}>
          {
            tabs.map((tab: TabProp, index: number) => {
              return <li key={index} className="nav-item" onClick={async () => {
                let changeTab = canChangeTab
                if (!changeTab) {
                  await Swal.fire({
                    title: 'Modifiche non salvate',
                    text: 'Ci sono delle modifiche non salvate, sei sicuro di voler cambiare scheda? Tutti i cambiamenti verranno persi.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#33dd33',
                    cancelButtonColor: '#dd3333',
                    confirmButtonText: 'Continua',
                    cancelButtonText: 'Annulla'
                  }).then(
                    (result) => {
                      if (result.isConfirmed) {
                        changeTab = true;
                      }
                    }
                  )
                };

                if (!changeTab) {
                  return false;
                }

                this.setState({ currentTab: index }, async () => {
                  tabClickHandler && await tabClickHandler(index, tab.args);
                });
                this.props.updateHash && history.push('#' + tab.id);
              }}>
                <a className={("nav-link" + (currentTab === index ? " active show" : "") + ((!canChangeTab && currentTab !== index) ? ' disabled disabledTab' : ''))} href={"#" + (tab.id ? tab.id : "tab" + (index + 1))} data-toggle="tab" data-bs-toggle="tab" data-bs-target={"#" + (tab.id ? tab.id : "tab" + (index + 1))} role="tab" aria-controls={tab.id ? tab.id : "tab" + (index + 1)} aria-selected="true">
                  {tab.label}
                  <div style={tab.hasBadge === true ? { display: 'block' } : { display: 'none' }}>
                    <span style={{ width: '24px', height: '24px', borderRadius: "50%", fontSize: '12px' }} className="badge bg-primary d-flex justify-content-center align-items-center ms-1">
                      {tab.badgeValue}
                    </span>
                  </div>
                </a>
              </li>;
            })
          }
        </ul>
        <div className="tab-content" style={{ marginTop: '15px', ...contentStyle }}>
          {tabs.map((tab: TabProp, index: number) => {
            return (!useState || (useState && index === currentTab)) && <div key={index} className={!useState ? "tab-pane fede" + (currentTab === index ? " active" : "") : "tab-pane fede active"} id={tab.id ? tab.id : "tab" + (index + 1)} role="tabpanel" aria-labelledby={(tab.id ? tab.id : "tab" + (index + 1) + "-tab")}>{tab.content}</div>;
          })}
        </div>
      </div>
    </>
  }
}