import React, { Component, MouseEvent } from 'react'
import EventBus from '../../common/EventBus';
import Table from '../../common/TableComponent';
import getListOreSettimanaliColumnDefs, { listOreSettimanaliColumns } from '../../config/tableColumns/organico/ore-settimanali'
import { IOreSettimanaliResponse, IOreSettimanaliFormField } from '../../helpers/interfaces/ore-settimanali';
import OreSettimanaliService from '../../services/api/ore-settimanali.service';
import ModalForm from '../../common/ModalForm';
import { IFormField, FormFieldsValue } from '../../helpers/interfaces/generic';
import { getOreSettimanaliFormFields } from '../../config/formFields/ore-settimanali';
import $ from 'jquery';
import Swal from 'sweetalert2';
import personService from '../../services/api/person.service';
import { IDipendente } from '../../helpers/interfaces/magazzino/magazzino-home';

type State = {
    OreSettimanali: IOreSettimanaliResponse[],
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    formFields: IFormField[],
    formInitialValues: IOreSettimanaliFormField| {},
    loading: boolean,
}

export default class OreSettimanali extends Component<{}, State>
{
    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            OreSettimanali: [],
            showModal: false,
            modalType: '',
            modalTitle: '',
            formFields: [],
            formInitialValues: {},
            loading: false,
        }

        this.apiSubmit = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei profili settimanali in corso...' });

        await OreSettimanaliService.getAll()
        .then((OreSettimanali: IOreSettimanaliResponse[]) => {
            this.setState({OreSettimanali})
        })
        .catch((error: any) => {
            this.setState({OreSettimanali: []});
            Swal.fire(
                'Errore di lettura',
                'Si è verificato un errore imprevisto durante la lettura dei dati',
                'error'
            )
        });

        EventBus.dispatch("hideLoader");

        $('#table_ore_settimanali.table').on('click', '.edit_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();

            EventBus.dispatch("showLoader", { text: 'Caricamento del profilo settimanale in corso...' });

            const idItem = $(e.currentTarget).data('id');

            await OreSettimanaliService.getOne(idItem)
            .then((OreSettimanali: IOreSettimanaliResponse | null) => {
                if (!OreSettimanali) {
                    Swal.fire(
                        'Errore di lettura',
                        'Si è verificato un errore imprevisto durante la lettura dei dati',
                        'error'
                    )
                } else {
                    this.apiSubmit = OreSettimanaliService.edit;

                    this.setState({
                        formFields: getOreSettimanaliFormFields([{
                            value: `${OreSettimanali.user.name} ${OreSettimanali.user.lastname}`,
                            key: OreSettimanali.user.id
                        }], 'edit'),
                        formInitialValues: {
                            id: OreSettimanali.id,
                            domenica: OreSettimanali.domenica,
                            lunedi: OreSettimanali.lunedi,
                            martedi: OreSettimanali.martedi,
                            mercoledi: OreSettimanali.mercoledi,
                            giovedi: OreSettimanali.giovedi,
                            venerdi: OreSettimanali.venerdi,
                            sabato: OreSettimanali.sabato,
                            person: OreSettimanali.user.id
                        },
                        modalType: 'edit',
                        modalTitle: `Modifica il profilo settimanale di ${OreSettimanali.user.name} ${OreSettimanali.user.lastname}`
                    }, async() => {
                        this.setState({showModal: true});
                    });
                }
            })
            .catch((error: any) => {
                Swal.fire(
                    'Errore di lettura',
                    'Si è verificato un errore imprevisto durante la lettura dei dati',
                    'error'
                )
            })
            .finally(() => {
                EventBus.dispatch("hideLoader");
            });
        });
    }

    async addHandler() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        this.apiSubmit = OreSettimanaliService.add;

        let employeesList: FormFieldsValue[] = [];
        await personService.getAllDipendenti()
        .then(employees => {
            employeesList = employees.length ? employees.map((employee: IDipendente) => {
                return {
                    key: employee.id,
                    value: `${employee.name} ${employee.lastname}`
                }
            }) : [];
            return employeesList;
        })
        .catch(() => {
            Swal.fire(
                'Errore di lettura',
                'Si è verificato un errore imprevisto durante la lettura dell\'elenco dei dipendenti.',
                'error'
            )
        });

        this.setState({
            formFields: getOreSettimanaliFormFields(employeesList, 'add'),
            formInitialValues: {
                domenica: 0,
                lunedi: 0,
                martedi: 0,
                mercoledi: 0,
                giovedi: 0,
                venerdi: 0,
                sabato: 0,
            },
            modalType: 'add',
            modalTitle: 'Crea un nuovo profilo settimanale'
        }, async() => {
            this.setState({showModal: true});
        });

        EventBus.dispatch("hideLoader");
    }

    closeModalForm() {
      this.setState({ showModal: false });
    }
  
    handleSuccess() {
      // window.location.hash = "#oreSettimanali";
      window.location.reload();
    }

    render() {
        const { OreSettimanali, showModal, modalType, modalTitle, formFields, formInitialValues } = this.state;

        const buttons = ['excel', 'pdf', 'print'];

        return <React.Fragment>
            <ModalForm
                showModal={showModal}
                title={modalTitle}
                modalType={modalType}
                formFields={formFields}
                initialValues={formInitialValues}
                apiSubmit={this.apiSubmit}
                closeCallback={this.closeModalForm.bind(this)}
                successAction={this.handleSuccess.bind(this)}
            />
            <div className='custom-container'>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                            <h2 className="card-title">Ore settimanali</h2>
                            <a
                                id="add_btn"
                                href="#add"
                                className="btn btn-outline-primary"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.addHandler();
                                }}
                            >
                                <span>Aggiungi persona</span>
                            </a>
                        </div>
                        {
                            OreSettimanali.length > 0 &&
                            <Table
                                id={'table_ore_settimanali'}
                                columns={listOreSettimanaliColumns}
                                columnDefs={getListOreSettimanaliColumnDefs()}
                                datas={OreSettimanali}
                                buttons={buttons}
                            />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}