import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Table from "../../common/TableComponent";
import Swal from "sweetalert2";
import $ from 'jquery';
import { NomineList } from "../../helpers/interfaces/nomine";
import nomineService from "../../services/api/nomine.service";
import { getNomineColumnDefs, listNomineColumns } from "../../config/tableColumns/nomine";

type Props = {};

type State = {
  nomine: Array<NomineList>;
  loading: boolean;
}

export default class ElencoNomine extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nomine: [],
      loading: false,
    }
  }

  async componentDidMount() {
    this.updateHandler();

    $('div#nomine').on('click', 'button#send', async () => {
      this.setState({ loading: true });

      const checkboxs = $('div#nomine input[name="ids[]"][type="checkbox"]:checked');

      let ids: Array<string> = [];
      $.each(checkboxs, (index, value) => {
        const id = value.getAttribute('value');
        id && ids.push(id);
      });

      if (!ids.length) {
        this.setState({ loading: false });
        return Swal.fire(
          'Nessuna selezione',
          'Per inviare un sollecito è necessario selezionare almeno una riga della tabella.',
          'warning'
        )
      }

      await nomineService.sendSollecito({ ids }).then(
        () => {
          Swal.fire({
            title: 'Sollecito inviato',
            text: 'Il sollecito è stato inviato con successo.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              this.updateHandler();
            }
          });
        },
        (error) => {
          Swal.fire({
            title: 'Errore',
            text: error ?? 'A causa di un errore non è stato possibile inviare il sollecito.',
            icon: 'error',
          });
        }
      ).catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile inviare il sollecito.',
          icon: 'error',
        });
      });

      this.setState({ loading: false });
    })
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco nomine in corso...' });
    this.setState({ loading: true });

    await nomineService.getList()
      .then((nomine: any) => {
        if (nomine && typeof nomine.error !== 'undefined') {
          return Swal.fire(
            "Errore",
            nomine.error,
            'error'
          )
        }

        this.setState({ nomine });
      }, (error: string) => {
        Swal.fire({
          title: 'Errore',
          text: error ?? 'A causa di un errore non è stato possibile caricare la lista delle nomine.',
          icon: 'error',
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista delle nomine.',
          icon: 'error',
        });
      });

    this.setState({ loading: false });
    eventBus.dispatch("hideLoader");
  }

  render(): JSX.Element {
    const { nomine, loading } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div id="nomine" className="card">
          <div className="card-body">
            <h3 className="card-title">Elenco nomine</h3>
            <Table id="table_nomine" columns={listNomineColumns} columnDefs={getNomineColumnDefs()} datas={nomine} buttons={null} />
            {
              nomine.length && <div className="d-flex justify-content-end">
                <button id="send" className="btn btn-info" disabled={loading}>Invia sollecito</button>
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}