import React, { Component } from 'react';
import EventBus from '../../../common/EventBus';
import { Tab, TabProp } from '../../../common/TabComponent';
import Table from '../../../common/TableComponent';
import { tipologieProdotti } from '../../../config/formFields/magazzino/magazzino-licenze';
import getLicenzeColumnDefs, { listLicenzeColumns } from '../../../config/tableColumns/magazzino/magazzino-licenze';
import { IMagazzinoLicenza } from '../../../helpers/interfaces/magazzino/magazzino-licenze';
import licenzeService from '../../../services/api/magazzino/magazzino-licenze.service';
import magazzinoLicenzeProdottiService from '../../../services/api/magazzino/magazzino-licenze-prodotti.service';

type State = {
    licenze: IMagazzinoLicenza[],
    prodotti: any[]
}

export default class MagazzinoLicenze extends Component<{}, State> {
    constructor(props: {}) {
        super(props)

        this.state = {
            licenze: [],
            prodotti: []
        }
    }

    async componentDidMount() {

        EventBus.dispatch("showLoader", { text: 'Caricamento delle licenze in corso...' });

        const licenzePromise = licenzeService.getAll();
        const prodottiPromise = magazzinoLicenzeProdottiService.getAll();

        // loading the devices (for tables) and the types (for tabs)
        await Promise.all([licenzePromise, prodottiPromise])
            .then(
                values =>
                    this.setState(
                        {
                            licenze: values[0].map(licenza => {
                                let tipologia = tipologieProdotti.find(t => t.key === licenza.tipologia)
                                return {
                                    ...licenza,
                                    tipologia: tipologia ? tipologia.value : ""
                                }
                            }),
                            prodotti: values[1]
                        },
                        () => EventBus.dispatch("hideLoader")
                    ),
                error => EventBus.dispatch("hideLoader")
            );
    }

    render() {
        const { licenze, prodotti } = this.state;
        let prodottiTabs: TabProp[] = prodotti.map(prodotto => {
            let licenzeInTable = []

            licenzeInTable = [...licenze].filter(licenza => licenza.prodotto_nome === prodotto.nome)

            return {
                id: 'tab_magazzino_dispositivi_' + prodotto?.nome.toLowerCase().replace(/\s/g, "_"),
                label: prodotto ? prodotto.nome : '',
                content: (<Table id={'table_magazzino_licenze_' + prodotto?.nome.toLowerCase().replace(/\s/g, "_")} columns={listLicenzeColumns} columnDefs={getLicenzeColumnDefs()} datas={licenzeInTable} buttons={['excel', 'pdf', 'print']} />),
                isActive: false
            }
        })

        if (prodottiTabs.length !== 0)
            prodottiTabs[0].isActive = true

        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Licenze</h2>
                                <a href="/magazzino/licenze/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi licenza</span>
                                </a>
                            </div>
                            {
                                licenze.length > 0 &&
                                <Tab tabs={prodottiTabs} />
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}