import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import ClientiService from "../../services/api/clienti.service";
import ContattiService from "../../services/api/contatti.service";
import { listContattiColumns, getListContattiColumnDefs } from "../../config/tableColumns";
import { getEditContattiFormFields, getAddContattiFormFields } from "../../config/formFields";
import Table from '../../common/TableComponent';
import { FormFields } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<FormFields>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    contatti: any,
    formInitialValues: { [key: string]: any }
}
export default class Contatti extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            contatti: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const contatti = await ContattiService.getAll();
        if (contatti.length > 0) {
            this.setState({ contatti });
        }
        EventBus.dispatch("hideLoader");

        $('#table_contatti.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Contatto in corso...' });
            const idContatto = $(e.currentTarget).data('id');
            const contatti = await ContattiService.get(idContatto);
            if (contatti) {
                const contatto: any = contatti[0];
                const clienti_all = await ClientiService.getAll();
                let clienti: any = clienti_all.map((item: any) => { return { key: item.id, value: item.ragionesociale } });
                this.setState({ formFields: getEditContattiFormFields(clienti), formInitialValues: { ...contatto, clientiId: contatto.id } }, () => {
                    this.apiSubmit = ContattiService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });
        $('#table_contatti.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi eliminare il contatto ?',
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idContatto = $(e.currentTarget).data('id');
                    const contatto: any = await ContattiService.delete(idContatto);
                    if (contatto && typeof contatto.error !== 'undefined') {

                        Swal.fire(
                            contatto.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    async openAddModale() {
        this.apiSubmit = ContattiService.add;
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const clienti_all = await ClientiService.getAll();
        let clienti: any = clienti_all.map((item: any) => { return { key: item.id, value: item.ragionesociale } });
        EventBus.dispatch("hideLoader");
        this.setState({ formFields: getAddContattiFormFields(clienti), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, contatti, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title">Contatti</h2>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi contatto</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Contatto'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {contatti.length > 0 && <Table id="table_contatti" columns={listContattiColumns} columnDefs={getListContattiColumnDefs()} datas={contatti} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}