import axios from './api';

const API_URL = process.env.REACT_APP_API_URL;

class StatsService {

  getStatsEmployees() {
    return axios.get(API_URL + 'v2/stats/employees');
  }

}

export default new StatsService();