import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Table from "../../common/TableComponent";
import Swal from "sweetalert2";
import $ from 'jquery';
import { HRDocumentsList } from "../../helpers/interfaces/HRDocuments";
import HRDocumentsService from "../../services/api/HRDocuments.service";
import { getHRDocumentsColumnDefs, listHRDocumentsColumns } from "../../config/tableColumns/HRDocuments";

type Props = {};

type State = {
  HRDocuments: Array<HRDocumentsList>;
  loading: boolean;
}

export default class ElencoHRDocuments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      HRDocuments: [],
      loading: false,
    }
  }

  async componentDidMount() {
    this.updateHandler();

    $('div#HRDocuments').on('click', 'button#send', async () => {
      this.setState({ loading: true });

      const checkboxs = $('div#HRDocuments input[name="ids[]"][type="checkbox"]:checked');

      let ids: Array<string> = [];
      $.each(checkboxs, (index, value) => {
        const id = value.getAttribute('value');
        id && ids.push(id);
      });

      if (!ids.length) {
        this.setState({ loading: false });
        return Swal.fire(
          'Nessuna selezione',
          'Per inviare un sollecito è necessario selezionare almeno una riga della tabella.',
          'warning'
        )
      }

      await HRDocumentsService.sendSollecito({ ids }).then(
        () => {
          Swal.fire({
            title: 'Sollecito inviato',
            text: 'Il sollecito è stato inviato con successo.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              this.updateHandler();
            }
          });
        },
        (error) => {
          Swal.fire({
            title: 'Errore',
            text: error ?? 'A causa di un errore non è stato possibile inviare il sollecito.',
            icon: 'error',
          });
        }
      ).catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile inviare il sollecito.',
          icon: 'error',
        });
      });

      this.setState({ loading: false });
    })
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco nomine in corso...' });
    this.setState({ loading: true });

    await HRDocumentsService.getList()
      .then((HRDocuments: any) => {
        if (HRDocuments && typeof HRDocuments.error !== 'undefined') {
          return Swal.fire(
            "Errore",
            HRDocuments.error,
            'error'
          )
        }

        this.setState({ HRDocuments });
      }, (error: string) => {
        Swal.fire({
          title: 'Errore',
          text: error ?? 'A causa di un errore non è stato possibile caricare la lista dei documenti.',
          icon: 'error',
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista dei documenti.',
          icon: 'error',
        });
      });

    this.setState({ loading: false });
    eventBus.dispatch("hideLoader");
  }

  render(): JSX.Element {
    const { HRDocuments, loading } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div id="HRDocuments" className="card">
          <div className="card-body">
            <h3 className="card-title">Elenco HRDocuments</h3>
            <Table id="table_HRDocuments" columns={listHRDocumentsColumns} columnDefs={getHRDocumentsColumnDefs()} datas={HRDocuments} buttons={null} />
            {HRDocuments.length ? (
              <>
                <br /><br />
                <button id="send" className="btn btn-info" disabled={loading}>Invia Sollecito</button>
              </>
            ) : <></>}
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}