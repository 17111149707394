import { AxiosResponse } from 'axios';
import {
  HRDocumentsList,
  HRDocumentsInviaRequest,
  HRDocumentsInviaResponse,
  HRDocumentsInviaSollecitoRequest,
  HRDocumentsInviaSollecitoResponse,
  HRDocumentsSollecitiList,
  HRDocumentsTemplate
} from '../../helpers/interfaces/HRDocuments';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class HRDocumentsService {
  async getList(): Promise<Array<HRDocumentsList>> {
    return await axios.get(API_URL + 'datore/hrdocuments')
    .then((response: AxiosResponse<Array<HRDocumentsList>>) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async send(data: HRDocumentsInviaRequest): Promise<HRDocumentsInviaResponse> {
    return await axios.post(API_URL + 'datore/hrdocuments/invio', data)
    .then((response: any) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async getSollecitiList(): Promise<Array<HRDocumentsSollecitiList>> {
    return await axios.get(API_URL + 'datore/hrdocuments/sollecito/list')
    .then((response: AxiosResponse<Array<HRDocumentsSollecitiList>>) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async sendSollecito(data: HRDocumentsInviaSollecitoRequest): Promise<HRDocumentsInviaSollecitoResponse> {
    return await axios.post(API_URL + 'datore/hrdocuments/sollecito/send', data)
    .then((response: any) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }

  async getDocumentTemplates(): Promise<Array<HRDocumentsTemplate> & {error: string}> {
    return await axios.get(API_URL + 'datore/templates')
    .then((response: any) => {
      return Array.isArray(response.data) ? response.data : []; 
    })
    .catch((error: string) => { return Promise.reject(error); });
  }
}

export default new HRDocumentsService();