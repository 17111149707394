import { generatePath } from "react-router-dom";
import { ListColumnDefs, ListColumns } from "../../helpers/interfaces/generic";
import { IAdempimento, IApprendista, IAssignment, IAttestato, IAttestatoMancante, IDeadlines, IFineContratto, IFineProva, IHomeDocumento, IJobOrder, ILettera, IMaterialeNonRiconsegnato } from "../../helpers/interfaces/home-amministrazione";
import { extractProperty } from "../../helpers/interfaces/magazzino/magazzino-contratti-noleggi";
import { statusColors, statusColorsLabels } from "../../helpers/settings/status-settings";

interface IDeadlinesListColumns {
    noleggi: ListColumns[],
    fine_prova: ListColumns[],
    fine_contratto: ListColumns[],
    attestati: ListColumns[],
    adempimenti: ListColumns[],
    apprendisti: ListColumns[],
    documenti: ListColumns[],
    attestati_mancanti: ListColumns[]
}

// table columns definitions
export const listAssignmentsColumns: ListColumns[] = [
    { title: "Data scadenza", data: "enddate" },
    { title: "Commessa", data: "joborderid" },
    { title: "Assegnata a", data: "user" },
    { title: "Codice", data: "joborderid" }
];

export const listJobOrdersColumns: ListColumns[] = [
    { title: "Azioni", data: "joborderid" },
    { title: "Data scadenza", data: "expiredate" },
    { title: "Commessa", data: "jobordername" },
    { title: "Codice", data: "identificationcode" }
];

export const listAttestatiColumns: ListColumns[] = [
    { title: "Nome", data: "id" },
    { title: "Attestato", data: "attestato" },
    { title: "Assegnazione", data: "data_assegnazione" },
    { title: "Scadenza", data: "data_scadenza" }
]

export const listAttestatiMancantiColumns: ListColumns[] = [
    { title: "Azioni", data: "person_id" },
    { title: "Nome", data: "person_id" },
    { title: "Per ruolo", data: "person_id" },
    { title: "Per contratto", data: "person_id" }
]

export const listNoleggiColumns: ListColumns[] = [
    { title: "Nome", data: "nome" },
    { title: "Codice", data: "codice" },
    { title: "Inizio noleggio", data: "data_inizio_noleggio" },
    { title: "Fine noleggio", data: "data_fine_noleggio" }
]

export const listFineContrattoColumns: ListColumns[] = [
    { title: "Nome", data: "id" },
    { title: "Contratto", data: "contratto" },
    { title: "Assunzione", data: "data_assunzione" },
    { title: "Fine rapporto", data: "data_fine_rapporto" },
    { title: "Fine prova", data: "data_fine_prova" },
    { title: "Proroghe", data: "numero_proroghe" }
]

export const listApprendistiColumns: ListColumns[] = [
    { title: "Nome", data: "person_id" },
    { title: "Sede", data: "sede" },
    { title: "Assunzione", data: "data_assunzione" },
    { title: "18 mesi", data: "data_18_mesi" },
]

export const listAdempimentiColumns: ListColumns[] = [
    { title: "Nome", data: "id" },
    { title: "Adempimento", data: "adempimento" },
    { title: "Stato", data: "stato" },
]

export const listHomeDocumentiColumns: ListColumns[] = [
    { title: "Dipendente", data: "person_id" },
    { title: "Documento", data: "nome" },
    { title: "Scadenza", data: "scadenza" },
]

export const listFineProvaColumns: ListColumns[] = [
    { title: "Nome", data: "id" },
    { title: "Contratto", data: "contratto" },
    { title: "Assunzione", data: "data_assunzione" },
    { title: "Fine rapporto", data: "data_fine_rapporto" },
    { title: "Fine prova", data: "data_fine_prova" },
]

export const listLettereColumns: ListColumns[] = [
    { title: "Azioni", data: "personId" },
    { title: "Status", data: "status" },
    { title: "Dipendente", data: "personName" },
    { title: "Dispositivo", data: "dispositivo" }
]

export const listMaterialeNonRiconsegnatoColumns = [
    { title: "Azioni", data: "personId" },
    { title: "Stato", data: "personId" },
    { title: "Dipendente", data: "personName" },
    { title: "Dispositivo", data: "dispositivo" },
    { title: "S/N", data: "serial" }
]

const columnsLists: IDeadlinesListColumns = {
    noleggi: listNoleggiColumns,
    fine_prova: listFineProvaColumns,
    fine_contratto: listFineContrattoColumns,
    attestati: listAttestatiColumns,
    adempimenti: listAdempimentiColumns,
    apprendisti: listApprendistiColumns,
    documenti: listHomeDocumentiColumns,
    attestati_mancanti: listAttestatiMancantiColumns,
}

function createAllLists(deadlines: IDeadlines): IDeadlinesListColumns {
    const labels = Object.keys(deadlines)

    return labels.reduce((accumulator, value, index) => {
        return { ...accumulator, [value]: extractProperty(columnsLists, value as keyof IDeadlinesListColumns) };
    }, {} as IDeadlinesListColumns)
}

// setting the correct deadlines columns
export function setCorrectDeadlinesColumns(deadlines: IDeadlines, currentLabel: string): ListColumns[] {
    const allLists: IDeadlinesListColumns = createAllLists(deadlines)
    return extractProperty(allLists, currentLabel as keyof IDeadlinesListColumns)
}

// function: setting columns defs
export function getAssignmentsColumnDefs() {
    return [
        {
            targets: 1,
            render: (id: number, type: string, row: IAssignment) => {
                return '<div class="d-flex">' + row.commessa.customer + " " + row.commessa.jobordername + '</div>';
            },
        },
        {
            targets: 2,
            render: (id: number, type: string, row: IAssignment) => {
                const url = generatePath("/organico/dettaglio-personale/:id", { id: row.userid })

                const renderData = `<a href="${url}" data-id="'+${id}+'"> 
                    ${row.user}
                </a>`;

                return '<div class="d-flex">' + renderData + '</div>';
            },
        },
        {
            targets: 3,
            render: (id: number, type: string, row: IAssignment) => {
                return '<div class="d-flex">' + row.commessa.identificationcode + '</div>';
            },
        }
    ];
}

export function getJobOrdersColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IJobOrder) => {

                const stop = `<button class="stop_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}"> 
                    <i style="font-size: 18px" class="fa fa-hand-paper-o" aria-hidden="true"></i>
                </button>`;

                const refresh = `<button class="renew_btn custom-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${id}"> 
                    <i style="font-size: 18px" class="fa fa-refresh" aria-hidden="true"></i>
                </button>`;

                return '<div class="d-flex">' + stop + refresh + '</div>';
            },
        }
    ];
}

export function getNoleggiColumnDefs() {
    return []
}

export function getFineProvaColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IFineProva) => {
                return '<div class="d-flex">' + row.name + " " + row.lastname + '</div>';
            }
        },
    ]
}

export function getFineContrattoColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IFineContratto) => {
                return '<div class="d-flex">' + row.name + " " + row.lastname + '</div>';
            }
        },
    ]
}

export function getAttestatiColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IAttestato) => {
                return '<div class="d-flex">' + row.name + " " + row.lastname + '</div>';
            }
        },
    ]
}

export function getAdempimentiColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IAdempimento) => {
                return '<div class="d-flex">' + row.name + " " + row.lastname + '</div>';
            }
        },
        {
            targets: 2,
            render: (id: number, type: string, row: IAdempimento) => {
                switch (row.stato) {
                    case 1: {
                        return `<div class="d-flex align-items-center">
                            <div style="width: 16px; height: 16px; background-color: ${statusColorsLabels[0].color}" class="rounded-circle me-1"></div>
                            ${statusColorsLabels[0].label}
                        </div>`;
                    }
                    case 2: {
                        return `<div class="d-flex align-items-center">
                            <div style="width: 16px; height: 16px; background-color: ${statusColorsLabels[1].color}" class="rounded-circle me-1"></div>
                            ${statusColorsLabels[1].label}
                        </div>`;
                    }
                    case 3: {
                        return `<div class="d-flex align-items-center">
                            <div style="width: 16px; height: 16px; background-color: ${statusColorsLabels[2].color}" class="rounded-circle me-1"></div>
                            ${statusColorsLabels[2].label}
                        </div>`;
                    }
                    default: {
                        return `<div class="d-flex align-items-center">
                            <div style="width: 16px; height: 16px; background-color: ${statusColorsLabels[3].color}" class="rounded-circle me-1"></div>
                            ${statusColorsLabels[3].label}
                        </div>`;
                    }
                }
            }
        }
    ]
}

export function getApprendistiColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IApprendista) => {
                return '<div class="d-flex">' + row.name + " " + row.lastname + '</div>';
            }
        }
    ]
}

export function getDocumentiColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IHomeDocumento) => {
                return '<div class="d-flex">' + row.name + " " + row.lastname + '</div>';
            }
        }
    ]
}

export function getAttestatiMancantiColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IAttestatoMancante) => {
                const url = generatePath("/organico/dettaglio-personale/:id", { id: row.person_id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            }
        },
        {
            targets: 1,
            render: (id: number, type: string, row: IAttestatoMancante) => {
                return '<div class="d-flex">' + row.person_name + " " + row.person_lastname + '</div>';
            }
        },
        {
            targets: 2,
            render: (id: number, type: string, row: IAttestatoMancante) => {
                return row.by_ruolo ? '<div class="d-flex flex-column">' + Object.keys(row.by_ruolo).map(
                    key => { return row.by_ruolo[key] }
                ) + '</div>' : '';
            }
        },
        {
            targets: 3,
            render: (id: number, type: string, row: IAttestatoMancante) => {
                const idoneita = row.by_contract.missing_idoneita ? '<span>Idoneita</span>' : '';
                const sicurezza = row.by_contract.missing_sicurezza ? '<span>Sicurezza</span>' : '';
                const apprendistato = row.by_contract.missing_apprendistato && row.by_contract.missing_apprendistato === true ? '<span>Apprendistato</span>' : '';

                return row.by_contract ? '<div class="d-flex flex-column">' + idoneita + sicurezza + apprendistato + '</div>' : '';
            }
        }
    ]
}

export function getLettereColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: ILettera) => {
                const url = generatePath("/organico/dettaglio-personale/:id/#dispositivi", { id: row.personId })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            }
        },
        {
            targets: 1,
            render: (id: number, type: string, row: ILettera) => {
                switch (row.status) {
                    case 's':
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[0].color}" class="rounded-circle"></div>`;
                    case 'r':
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                }
            }
        }
    ]
}

export function getMaterialeColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: IMaterialeNonRiconsegnato) => {
                const url = generatePath("/organico/dettaglio-personale/:id", { id: row.personId })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            }
        },
        {
            targets: 1,
            render: (id: number, type: string, row: ILettera) => {
                return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
            }
        }
    ]
}

const columnsDefsLists: { [key: string]: ListColumnDefs[] } = {
    noleggi: getNoleggiColumnDefs(),
    fine_prova: getFineProvaColumnDefs(),
    fine_contratto: getFineContrattoColumnDefs(),
    attestati: getAttestatiColumnDefs(),
    adempimenti: getAdempimentiColumnDefs(),
    apprendisti: getApprendistiColumnDefs(),
    documenti: getDocumentiColumnDefs(),
    attestati_mancanti: getAttestatiMancantiColumnDefs(),
}

function createAllListsDef(deadlines: IDeadlines) {
    const labels = Object.keys(deadlines)

    return labels.reduce((accumulator, value, index) => {
        return { ...accumulator, [value]: extractProperty(columnsDefsLists, value) };
    }, {} as { [key: string]: ListColumnDefs[] })
}

// setting the correct deadlines column defs
export function setCorrectDeadlinesColumnsDef(deadlines: IDeadlines, currentLabel: string) {
    const allLists = createAllListsDef(deadlines)
    return extractProperty(allLists, currentLabel as keyof ListColumnDefs)
}