import React, { Component } from "react";
import eventBus from "../../common/EventBus";
import Table from "../../common/TableComponent";
import Swal from "sweetalert2";
import { NomineList } from "../../helpers/interfaces/nomine";
import nomineService from "../../services/api/nomine.service";
import { listSollecitiNomineColumns, getSollecitiNomineColumnDefs } from "../../config/tableColumns/nomine";

type Props = {};

type State = {
  solleciti: Array<NomineList>;
}

export default class ElencoSollecitiNomine extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      solleciti: [],
    }
  }

  async componentDidMount() {
    this.updateHandler();
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco solleciti in corso...' });

    await nomineService.getSollecitiList()
      .then((solleciti: any) => {
        if (solleciti && typeof solleciti.error !== 'undefined') {
          return Swal.fire(
            "Errore",
            solleciti.error,
            'error'
          )
        }

        this.setState({ solleciti });
      }, (error: string) => {
        Swal.fire({
          title: 'Errore',
          text: error ?? 'A causa di un errore non è stato possibile caricare la lista dei solleciti.',
          icon: 'error',
        })
      })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista dei solleciti.',
          icon: 'error',
        })
      });

    eventBus.dispatch("hideLoader");
  }

  render(): JSX.Element {
    const { solleciti } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Elenco solleciti</h2>
            <Table id="table_solleciti" columns={listSollecitiNomineColumns} columnDefs={getSollecitiNomineColumnDefs()} datas={solleciti} buttons={null} />
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}