import * as Yup from "yup";
import { FormFields } from "../../helpers/interfaces/generic";

export function getNewTimetableFormFields(): Array<FormFields> {
  return [
    {
      label: 'Scadenza',
      name: 'deadline',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().required("Campo obbligatorio")
    },
    {
      label: 'Note',
      name: 'note',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Tipologia',
      name: 'type',
      type: 'select',
      values: [{ key: 'Private', value: 'Privata' }, { key: 'Group', value: 'Gruppo' }],
      class: 'form-select',
      validation: Yup.string().required("Campo obbligatorio"),
    },
  ];
}