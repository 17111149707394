import React from 'react';
import { AdminRoute } from './../../helpers/interfaces/admin';
import eventBus from '../../common/EventBus';
import adminService from './../../services/api/admin.service';
import Table from '../../common/TableComponent';
import { getAdminRoutesColumnDefs, listAdminRoutesColumns } from './../../config/tableColumns/admin/admin-routes';
import ModalForm from '../../common/ModalForm';
import { FormFields } from '../../helpers/interfaces/generic';
import { getEditRouteFormFields } from '../../config/formFields/admin';
import $ from 'jquery';

type Props = {
  //
};

type State = {
  adminRoutes: Array<AdminRoute>;
  showModal: boolean;
  modalTitle: string;
  modalType: string;
  formFields: FormFields[];
  formInitialValues: { [key: string]: any }
};

export default class ElencoRotte extends React.Component<Props, State> {
  apiSubmit: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      adminRoutes: [],
      showModal: false,
      modalTitle: '',
      modalType: '',
      formFields: [],
      formInitialValues: {}
    }
  }

  async componentDidMount() {
    await this.updateHandler();

    $('#table_admin_routes.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      eventBus.dispatch("showLoader", { text: 'Caricamento rotta in corso...' });
      const routeId = $(e.currentTarget).data('id');
      const route = await adminService.getRoute(routeId);
      if (route) {
        this.setState({ formFields: getEditRouteFormFields(), formInitialValues: route }, () => {
          this.apiSubmit = adminService.putRoute;
          this.setState({ showModal: true, modalType: 'edit', });
        });
      }
      eventBus.dispatch("hideLoader");
    });
  }

  async updateHandler() {
    eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    const adminRoutes = await adminService.getRoutes();
    if (adminRoutes.length > 0) {
      this.setState({ adminRoutes });
    }

    eventBus.dispatch("hideLoader");
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { adminRoutes, showModal, modalTitle, modalType, formFields, formInitialValues } = this.state;

    return <div className="custom-container">
      <ModalForm
        showModal={showModal}
        title={modalTitle}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.updateHandler.bind(this)}
      />
      <div className='card'>
        <div className='card-body'>
          <h2 className='card-title'>Rotte</h2>
          <Table
            id="table_admin_routes"
            columns={listAdminRoutesColumns}
            columnDefs={getAdminRoutesColumnDefs()}
            datas={adminRoutes}
            buttons={[]}
          />
        </div>
      </div>
    </div>
  }
}