import React from "react";

import FullCalendar, { ViewApi } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import itLocale from '@fullcalendar/core/locales/it';
import EventBus from "../../common/EventBus";
import statipermessiferieService from "../../services/api/statipermessiferie.service";
import permessiferieService from "../../services/api/permessiferie.service";
import rendicontazioniService from '../../services/api/rendicontazioni.service';

// daterangepicker
import $ from 'jquery';
import moment from "moment";
import 'moment/locale/it';
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';
import 'font-awesome/css/font-awesome.min.css';
import { PermessiferieDipendente } from "../../helpers/interfaces/permessiferie";
import Swal from "sweetalert2";
import { CausalDipendente, StatusDipendente } from "../../helpers/interfaces/permessiferie";
import { ellipsisText } from "../../helpers/settings/text-styles";
import { statusColorsLabels } from "../../helpers/settings/status-settings";
import { IPermessiStudioEsami } from "../../helpers/interfaces/rendicontazione";

interface Props { }

interface State {
  events: Array<Event>;
  causal: Array<CausalDipendente>;
  statuses: Array<StatusDipendente>;
  startDate: string;
  endDate: string;
  causali_id: string;
  monthEvents: Array<Event>;
  permessiEsamiStudio: IPermessiStudioEsami
}

interface Event {
  id: string;
  title: string;
  start: string;
  end: string;
  backgroundColor: string;
  status: string;
  nota: string;
  totalePermessi: number,
  orePermessi: string,
  statoPermessi: boolean
}

const dateFormat = 'YYYY/MM/DD HH:mm';
const startDate = moment().format('YYYY/MM/DD 09:00:00');
const endDate = moment().format('YYYY/MM/DD 18:00:00');

export default class PermessiFerieDipendente extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      events: [],
      causal: [],
      statuses: [],
      startDate,
      endDate,
      causali_id: '1',
      monthEvents: [],
      permessiEsamiStudio: {} as IPermessiStudioEsami
    }
  }

  async componentDidMount() {
    await this.updateHandler();

    $(function () {
      $('input#ferieperiodo').daterangepicker({
        timePicker: true,
        timePicker24Hour: true,
        startDate,
        endDate,
        locale: {
          customRangeLabel: dateFormat,
          format: dateFormat,
          cancelLabel: 'Annulla',
          applyLabel: 'Applica'
        },
        minDate: '2016-01-01',
        minYear: 2016,
      },
        async function (startDate: moment.Moment, endDate: moment.Moment) {
          const value = startDate.format(dateFormat) + ' - ' + endDate.format(dateFormat);
          $('input#ferieperiodo').attr('value', value);
        });
    })

    $('div.form-group').on('change', 'input#ferieperiodo', (e) => {
      const dates = e.target.value.split(' - ');
      this.setState({ startDate: dates[0], endDate: dates[1] });
    });

    $('div.card-body').on('submit', 'form#permissionRequest', (e) => {
      e.preventDefault();

      Swal.fire({
        title: "Invia la richiesta?",
        text: "Sei sicuro/a di voler inviare questa richiesta?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result: PermessiferieDipendente & { error: Array<string> } = await permessiferieService.createPermessiFerieDipendente(this.state.startDate, this.state.endDate, this.state.causali_id);
          if (result && typeof result.error !== 'undefined') {
            Swal.fire(
              'Si è verificato un errore',
              'SI è verificato un errore imprevisto durante l\'elaborazione della sua richiesta',
              'error'
            );
          } else {
            Swal.fire({
              title: "Richiesta inviata",
              text: "la richiesta è stata inviata con successo",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK',
            }).then(async (result) => {
              if (result.isConfirmed) {
                window.location.reload();
                // await this.updateHandler();
              }
            });
          }
        }
      });
    });

    $('div.delete_btn').on('click', '.action-icon', async (e: any) => {
      e.preventDefault();
      const idRichiesta = $(e.currentTarget).data('id');

      idRichiesta && Swal.fire({
        title: "Eliminare la richiesta?",
        text: "Sei sicuro/a di voler eliminare questa richiesta?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result: any = await permessiferieService.deletePermessiFerieDipendente(idRichiesta);
          if (result && typeof result.error !== 'undefined') {
            Swal.fire(
              'Si è verificato un errore',
              result.error,
              'error'
            );
          } else {
            Swal.fire({
              title: "Richiesta eliminata",
              text: "la richiesta è stata eliminata con successo",
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await this.updateHandler();
              }
            });
          }
        }
      });
    });

    $('div.info_btn').on('click', '.action-icon', async (e: any) => {
      e.preventDefault();
      const idRichiesta = $(e.currentTarget).data('id');
      let nota = '';

      this.state.monthEvents.forEach(event => {
        if (parseInt(event.id) === idRichiesta) {
          nota = event.nota;
        }
      });

      idRichiesta && Swal.fire({
        title: "Motivazione rifiuto",
        text: nota,
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'chiudi',
      })
    });
  }

  async updateHandler() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    const causal = await permessiferieService.getCausalDipendente();
    this.setState({ causal });

    const statuses = await statipermessiferieService.getAllDipendente();
    this.setState({ statuses });

    const results: Array<PermessiferieDipendente> = await permessiferieService.getPermessiFerieDipendente();
    const permessiEsamiStudio = await rendicontazioniService.getPermessiStudioEsami();

    if (results && results.length && statuses && statuses.length > 0) {
      const events = results.map((item) => {
        return {
          id: item.id.toString(),
          title: item.causale.nome,
          start: item.inizio,
          end: item.fine,
          backgroundColor: item.stato.colore,
          status: item.stato.nome,
          nota: item.nota,
          totalePermessi: item.limit,
          orePermessi: item.ore,
          statoPermessi: item.status
        }
      });

      this.setState({ events, permessiEsamiStudio });

      const year = moment().year().toString();
      const momentMonthString = (moment().month() + 1).toString();
      const month = momentMonthString.length === 2 ? momentMonthString : '0' + momentMonthString;
      const daysInMonth = moment(year + '-' + month + '-01').daysInMonth().toString();
      await this.getMonthEvents(moment(year + '-' + month + '-01T00:00:00+01:00'), moment(year + '-' + month + '-' + daysInMonth + 'T23:59:59+01:00'));
    }

    EventBus.dispatch("hideLoader");
  }

  async handleMonthChange(payload: { startStr: string, endStr: string, view: ViewApi }) {
    const calendarStartDate = moment(payload.view.currentStart);
    const calendarEndDate = moment(payload.view.currentEnd);
    await this.getMonthEvents(calendarStartDate, calendarEndDate);
  }

  async getMonthEvents(eventStartDate: moment.Moment, eventEndDate: moment.Moment) {
    const { events } = this.state;
    let monthEvents: Array<Event> = []

    events && events.length && events.forEach(event => {
      const compareStartDate = moment(event.start);
      const compareEndDate = moment(event.end);

      if (compareStartDate.isBetween(eventStartDate, eventEndDate) || compareEndDate.isBetween(eventStartDate, eventEndDate) || compareStartDate.isSame(eventStartDate) || compareEndDate.isSame(eventEndDate)) {
        monthEvents.push(event);
      }
    })

    this.setState({ monthEvents });
  }

  handleSelectChange(selectValue: string) {
    const { startDate, endDate } = this.state

    const causali_id = selectValue;
    this.setState({ causali_id });

    if (selectValue === '5') {
      $('.calendar-time').hide();
      let value = moment(startDate).hours(9).minutes(0).format(dateFormat) + ' - ' + moment(endDate).hours(18).minutes(0).format(dateFormat);
      $('input#ferieperiodo').val(value);
      const dates = value.split(' - ');
      $('input#ferieperiodo').data('daterangepicker')?.setStartDate(dates[0]);
      $('input#ferieperiodo').data('daterangepicker')?.setEndDate(dates[1]);
      this.setState({ startDate: dates[0], endDate: dates[1] });
    } else {
      $('.calendar-time').show();
    }
  }

  render() {
    const { causal, statuses, startDate, endDate, monthEvents, permessiEsamiStudio } = this.state;
    let countNew = 0;
    let countReject = 0;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h3 className="pb-3">Permessi e ferie</h3>

            <form id="permissionRequest" className="d-flex align-items-center" action="/dipendente/permessi-ferie" method="post">
              <div className="form-group me-2">
                <label className="mb-1">Tipologia</label>
                <select className="form-control" id="causali_id" name="causali_id" tabIndex={-1} aria-hidden="true"
                  onChange={e => this.handleSelectChange(e.target.value)}>
                  {causal.map((element, index) => {
                    if (element.code !== 'IN' && element.code !== 'ML' && element.code !== 'VI') {
                      return <option key={index} value={element.id}>{element.name}</option>
                    } else {
                      return null;
                    }
                  })}
                </select>
                <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="5" style={{ width: '384.75px' }}>
                  <span className="selection">
                    <span className="select2-selection select2-selection--single" role="combobox" aria-controls="" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-causali_id-no-container">
                      {/* <span className="select2-selection__rendered" id="select2-causali_id-no-container" role="textbox" aria-readonly="true" title="PERMESSI">PERMESSI</span> */}
                      <span className="select2-selection__arrow" role="presentation">
                        <b role="presentation">{' '}</b>
                      </span>
                    </span>
                  </span>
                  <span className="dropdown-wrapper" aria-hidden="true"></span>
                </span>
              </div>
              <div className="form-group w-75 me-2">
                <label className="mb-1">Periodo (Inizio - Fine) [formato.. Anno/Mese/giorno ora:minuti]</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" style={{ padding: 10 }}><i className="fa fa-clock-o"></i></span>
                  </div>
                  <input type="text" className="form-control float-right" name="ferieperiodo" id="ferieperiodo" value={startDate + ' - ' + endDate} onChange={(e) => { }} />
                </div>
              </div>
              <button style={{ marginTop: "24px" }} className="btn btn-primary" type="submit">
                Richiedi
              </button>
            </form>
            <hr />
            <div className="d-lg-flex justify-content-lg-between mt-2">
              <div className="col-12 mb-3 col-lg-7 mb-lg-0">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  }}
                  initialView="dayGridMonth"
                  displayEventEnd={true}
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                  }}
                  fixedWeekCount={false}
                  events={monthEvents}
                  locale={itLocale}
                  datesSet={this.handleMonthChange.bind(this)}
                />
              </div>
              <div className="stato-richieste col-12 col-lg-5 ps-lg-4">
                <div className="d-flex align-items-center">
                  <strong className="me-2">Stato richieste</strong>
                  <div>
                    {
                      statuses.map((status: any, index: number) => {
                        return <div key={index} className="badge badge-pill me-1" style={{ "color": "#fff", "margin": "5px 0px", "backgroundColor": status.colore }}>{status.nome}</div>
                      })
                    }
                  </div>
                </div>
                <hr />
                {
                  permessiEsamiStudio ? <div>
                    <div className="row align-items-center mb-3">
                      <h4 className="mb-0">
                        Permessi studio dell'ultimo anno<strong className="ms-2" style={{ color: permessiEsamiStudio.overstudio === true ? statusColorsLabels[1].color : statusColorsLabels[3].color }}>{permessiEsamiStudio.orestudio} / {permessiEsamiStudio.limitestudio}:00</strong>
                      </h4>
                    </div><div className="row align-items-center">
                      <h4 className="mb-0">
                        Permessi esami degli ultimi 3 anni<strong className="ms-2" style={{ color: permessiEsamiStudio.overesame === true ? statusColorsLabels[1].color : statusColorsLabels[3].color }}>{permessiEsamiStudio.oreesame} / {permessiEsamiStudio.limiteesame}:00</strong>
                      </h4>
                    </div>
                    <hr />
                  </div> : null
                }
                <div>
                  <h4 className="pb-2">Richieste aperte</h4>
                  {
                    monthEvents.map((event, index) => {
                      event.status === 'nuova' && countNew++;
                      return event.status === 'nuova' && (
                        <div key={index} className="row pb-2">
                          <div className="col-sm-1 delete_btn">
                            <i className="fa fa-trash action-icon deleteParent" data-id={event.id} />
                          </div>
                          <strong style={{ ...ellipsisText, whiteSpace: "nowrap" }} className="col-sm-3">
                            {event.title}
                          </strong>
                          <div className="col-sm-4">
                            {moment(event.start).format(dateFormat)}
                          </div>
                          <div className="col-sm-4">
                            {moment(event.end).format(dateFormat)}
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    countNew === 0 && (
                      <div>Nessuna nuova richiesta</div>
                    )
                  }
                </div>
                <hr />
                <div>
                  <h4 className="pb-2">Richieste rifiutate</h4>
                  {
                    monthEvents.map((event, index) => {
                      event.status === 'rifiutata' && countReject++;
                      return event.status === 'rifiutata' && (
                        <div key={index} className="row pb-2">
                          <div className="col-sm-1 info_btn">
                            <i className="fa fa-info-circle action-icon" data-id={event.id} />
                          </div>
                          <div className="col-sm-3">
                            {event.title}
                          </div>
                          <div className="col-sm-4">
                            {moment(event.start).format(dateFormat)}
                          </div>
                          <div className="col-sm-4">
                            {moment(event.end).format(dateFormat)}
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    countReject === 0 && (
                      <div>Nessuna richiesta rifiutata</div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}
