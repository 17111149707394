import React from 'react';
import { Component } from 'react';
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import getProdottiColumnDefs, { listProdottiColumns } from '../../../config/tableColumns/magazzino/magazzino-prodotti';
import { IMagazzinoProdotto } from '../../../helpers/interfaces/magazzino/magazzino-prodotti';
import magazzinoLicenzeProdottiService from '../../../services/api/magazzino/magazzino-licenze-prodotti.service';

type State = {
    prodotti: IMagazzinoProdotto[]
}

export default class MagazzinoProdotti extends Component<{}, State>{

    constructor(props: {}) {
        super(props)

        this.state = {
            prodotti: []
        }
    }

    async componentDidMount(): Promise<void> {

        EventBus.dispatch("showLoader", { text: 'Caricamento dei prodotti in corso...' });

        await magazzinoLicenzeProdottiService.getAll()
            .then(
                prodotti => this.setState(
                    {
                        prodotti
                    },
                    () => EventBus.dispatch("hideLoader")
                ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    render() {
        const { prodotti } = this.state;

        const buttons = ['excel', 'pdf', 'print']

        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Prodotti</h2>
                                <a href="/magazzino/prodotti/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi prodotto</span>
                                </a>
                            </div>
                            {
                                prodotti.length > 0 &&
                                <Table id={'table_magazzino_prodotti'} columns={listProdottiColumns} columnDefs={getProdottiColumnDefs()} datas={prodotti} buttons={buttons} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}