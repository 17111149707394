import * as Yup from "yup";
import { FormFields, IFormField } from "../../helpers/interfaces/generic";

export function getEditCommessaFormFields(): Array<IFormField> {
  return [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Codice',
      name: 'code',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Ordine',
      name: 'order',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Tariffa Oraria ?',
      name: 'rateIsHourly',
      type: 'select',
      'values': [{ key: 1, value: 'Si' }, { key: 0, value: 'No' }],
      class: 'form-select',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Tariffa',
      name: 'rate',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Giorni',
      name: 'days',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Data Inizio',
      name: 'start',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().required("Campo obbligatorio")
    },
    {
      label: 'Data Termine',
      name: 'expire',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().required("Campo obbligatorio")
    },
    {
      label: '',
      name: 'customerid',
      type: 'hidden',
      class: 'form-control',
      validation: Yup.number().positive().integer().required("Campo obbligatorio")
    },
    {
      label: '',
      name: 'joborderid',
      type: 'hidden',
      class: 'form-control',
      validation: Yup.number().positive().integer().notRequired()
    },
  ];
}

export function getAddCommessaFormFields(clienti: any, joborderFromCustomer: any, joborders: any = []): Array<IFormField> | Array<FormFields> {
  return [
    {
      label: 'Cliente',
      name: 'customerid',
      type: 'select',
      'values': clienti,
      class: 'form-select',
      validation: Yup.string().required("Campo obbligatorio"),
      'updateField': 'joborderid',
      'updateCallback': joborderFromCustomer
    },
    {
      label: 'Commessa Padre',
      name: 'joborderid',
      type: 'select',
      'values': joborders,
      class: 'form-select',
      validation: Yup.string().notRequired()
    },
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Codice',
      name: 'code',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Ordine',
      name: 'order',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Tariffa Oraria ?',
      name: 'rateIsHourly',
      type: 'select',
      'values': [{ key: 1, value: 'Si' }, { key: 0, value: 'No' }],
      class: 'form-select',
      validation: Yup.string().required("Campo obbligatorio")
    },
    {
      label: 'Tariffa',
      name: 'rate',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Giorni',
      name: 'days',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Data Inizio',
      name: 'start',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().required("Campo obbligatorio")
    },
    {
      label: 'Data Termine',
      name: 'expire',
      type: 'date',
      class: 'form-control',
      validation: Yup.date().required("Campo obbligatorio")
    },
  ];
}

export function getPersonListCommessaFormFields(personale: any[]) {
  return [
    {
      label: '',
      name: 'joborderid',
      type: 'unset',
      class: 'form-control',
    },
    {
      label: '',
      name: 'startdate',
      type: 'unset',
      class: 'form-control',
    },
    {
      label: '',
      name: 'enddate',
      type: 'unset',
      class: 'form-control',
    },
    {
      label: 'Dipendenti',
      name: 'ids',
      type: 'multi-select',
      isMulti: true,
      options: personale,
      class: 'form-select',
      validation: Yup.array().required("Campo obbligatorio")
    }
  ]
}