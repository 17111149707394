import { AdminLog, AdminLogAction } from "../../../helpers/interfaces/admin";
import { ListColumns } from "../../../helpers/interfaces/generic";

// table columns definitions
export const listAdminLogsColumns: ListColumns[] = [
    { title: "Id", data: "id" },
    { title: "Data", data: "date"},
    { title: "Tipo", data: "action.type" },
    { title: "Dipendente", data: "person" },
    { title: "Materiale", data: "action.label" },
    { title: "Azioni", data: "action" },
];

// function: setting columns defs 
export function getAdminLogsColumnDefs() {
    return [
        {
            targets: 5,
            render: (actions: AdminLogAction, type: string, row: AdminLog): string => {
                let output = '';
                if (actions.data.length >= 1) {
                    actions.data.forEach(action => {
                        if (typeof action === 'string') {
                            output += '<div>' + action + '</div>'
                        }
                        if (typeof action === 'object') {
                            output += '<div>' + action.field + ' modificato da <u>' + action.old +'</u> a <strong>' + action.new + '</strong></div>'
                        }
                    });
                }

                return '<div>' + output + '</div>'
            },
        },
    ];
}