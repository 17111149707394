import { AdminRoute } from "../../../helpers/interfaces/admin";
import { ListColumns } from "../../../helpers/interfaces/generic";

// table columns definitions
export const listAdminAuthorizationsColumns: ListColumns[] = [
    { title: "", data: "isChecked" },
    { title: "Pattern", data: "pattern" },
    { title: "Name", data: "name" },
    { title: "Method", data: "method" },
    { title: "Description", data: "description" },
];

// function: setting columns defs 
export function getAdminAuthorizationsColumnDefs(role: string) {
    return [
        {
            targets: 0,
            searchable: false,
            orderable: false,
            width: "5%",
            checkboxes: {
                selectRow: true
            },
            render: (isChecked: boolean, type: string, row: AdminRoute): string => {
                const checkbox = '<input id="' + row.id + '" type="checkbox"' + (isChecked && 'checked') + ' data-id="' + row.id + '" data-role="' + role + '" />';

                return '<span>' + checkbox + '</span>'
            },
        },
    ];
}