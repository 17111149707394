import { IPermessiStudioEsami } from '../../helpers/interfaces/rendicontazione';
import axios from '../api';
import { IOreSettimanali } from '../../helpers/interfaces/ore-settimanali';
const API_URL = process.env.REACT_APP_API_URL;

class RendicontazioniService {

  getAll(year: number, month: number) {
    return axios.get(API_URL + 'datore/rendicontazioni/' + year + '/' + month)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  sollecito(personId: number, data: any) {
    const { year, month } = data;
    return axios.post(API_URL + 'datore/rendicontazioni/' + personId + '/' + year + '/' + month + '/sollecito', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  getPermessiStudioEsami(): Promise<IPermessiStudioEsami> {
    return axios.get<IPermessiStudioEsami>(API_URL + 'dipendente/studio')
      .then(response => response.data)
      .catch(error => Promise.reject());
  }

  getPersonPermessiStudioEsami(id: number): Promise<IPermessiStudioEsami> {
    return axios.get<IPermessiStudioEsami>(API_URL + 'datore/studio/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject());
  }

  getRendicontazione(personId: number, year: number, month: number) {
    return axios.get(API_URL + 'datore/rendicontazioni/' + personId + '/' + year + '/' + month)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  amministrazioneEditRendicontazione(rendicontazioneId: string, formData: any) {
    return axios.put(API_URL + 'datore/rendicontazioni/edit/' + rendicontazioneId, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  amministrazioneUpdateRendicontazione(rendicontazioneId: string, data: any) {
    return axios.put(API_URL + 'datore/rendicontazioni/' + rendicontazioneId, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  getDipendenteRendicontazione(year: number, month: number) {
    return axios.get(API_URL + 'dipendente/rendicontazione/' + year + '/' + month)
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  dipendenteEditRendicontazione(year: number, month: number, formData: any) {
    return axios.put(API_URL + 'dipendente/rendicontazione/' + year + '/' + month, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  getDipendenteWorkHours(year: number, month: number): Promise<IOreSettimanali> {
    return axios.get(API_URL + 'dipendente/workhours/' + month + '/' + year)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  getDatoreWorkHours(id: number, year: number, month: number): Promise<IOreSettimanali> {
    return axios.get(API_URL + 'datore/workhours/' + id + '/' + month + '/' + year)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }
}
export default new RendicontazioniService();