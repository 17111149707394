import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import { IStatoDispositiviForm, statoDispositiviFormFields } from '../../../config/formFields/magazzino/magazzino-stato-disposiviti';
import { IFormField, ISelectOption } from '../../../helpers/interfaces/generic';
import { IMagazzinoStatoDispositivo, IStatoDispositivoBody } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo';
import statodispositiviService from '../../../services/api/magazzino/magazzino-stato-dispositivi.service';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    stato: IMagazzinoStatoDispositivo,
    formFields: IFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: IStatoDispositiviForm
}

export default class MagazzinoDettaglioStato extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            stato: {} as IMagazzinoStatoDispositivo,
            formFields: [],
            loading: false,
            disabledForm: true,
            formInitialValues: {} as IStatoDispositiviForm
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateStato = this.handleUpdateStato.bind(this);
    }

    // method: initializing the form values
    initFormValues(): IStatoDispositiviForm {
        return {
            nome: this.state.stato.nome,
            censimento: this.state.stato.censimento === true ? "Sì" : (this.state.stato.censimento === false ? "No" : ""),
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento stato dispositivo in corso...' });
        await statodispositiviService.getOne(this.props.match.params.id)
            .then(
                stato =>
                    this.setState(
                        {
                            stato
                        },
                        () => {
                            EventBus.dispatch("hideLoader")
                            this.setState({
                                formFields: statoDispositiviFormFields(),
                                formInitialValues: this.initFormValues()
                            })
                        }
                    ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    // method: handling edit state actions
    async handleUpdateStato(stato: IStatoDispositiviForm) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        let statoBody: IStatoDispositivoBody = {
            nome: stato.nome,
            censimento: stato.censimento === "Sì" ? true : (stato.censimento === "No" ? false : null)
        }

        // handle edit call
        await statodispositiviService.edit(this.props.match.params.id, statoBody).then(
            response => {
                this.setState(
                    {
                        stato: response,
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");

                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );
                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof IStatoDispositiviForm])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleUpdateStato}
                    >
                        <Form className="card">
                            <div className="card-body">
                                <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                    <h3>Dettaglio stato</h3>
                                    <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                        {
                                            disabledForm ?
                                                <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                        }
                                    </button>
                                </div>

                                {formFields.map(
                                    (field: IFormField, key) => {
                                        return <div className="mb-3" key={key}>
                                            <div className='col-12'>
                                                <label className="form-label">{field.label}</label>
                                                {
                                                    field.type === 'select' ?
                                                        <Field as={field.type} name={field.name} className={field.class} disabled={disabledForm}>
                                                            <option key={''} value={''}>---</option>
                                                            {field.values?.map((item: ISelectOption) => {
                                                                return <option key={item.key} value={item.value}>{item.value}</option>
                                                            })}
                                                        </Field>
                                                        : <Field name={field.name} type={field.type} className={field.class} disabled={disabledForm} />
                                                }
                                                <ErrorMessage
                                                    name={field.name}
                                                    component="div"
                                                    className="alert alert-danger"
                                                />
                                            </div>
                                        </div>
                                    }
                                )}

                                <div className="col-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                        {
                                            loading && <span className="spinner-border spinner-border-sm me-1"></span>
                                        }
                                        <span>Salva</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}