import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import EventBus from "../../common/EventBus";

import PersonTypeService from "../../services/api/persontype.service";
import PersonService from "../../services/api/person.service";
import { getNuovoPersonaleFormFields } from "../../config/formFields";
import Swal from "sweetalert2";
import { FormFields } from "../../helpers/interfaces/generic";
import businessunitService from "../../services/api/businessunit.service";
import { IBusinessUnit } from "../../helpers/interfaces/businessunit";

type Props = {
    history: any
};
type State = {
    formFields: Array<FormFields>,
    loading: boolean,
}
export default class NuovoPersonale extends Component<Props, State> {
    innerRefs: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            loading: false,
        }
        this.innerRefs = [];
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const persontype_all = await PersonTypeService.getAll();
        let persontype: any = persontype_all.map((item: any) => { return { key: item.id, value: item.type } });
        const allBusinessUnits = await businessunitService.getAll();
        let businessUnit: { key: number, value: string }[] = allBusinessUnits.map((item: IBusinessUnit) => { return { key: item.id, value: item.nome } });

        if (persontype_all.length > 0) {
            this.setState({ formFields: getNuovoPersonaleFormFields(persontype, businessUnit) });
        }
        EventBus.dispatch("hideLoader");
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    handleSubmit(formValue: any) {
        this.setState({
            loading: true
        });
        PersonService.add(formValue).then(
            (response) => {
                this.setState({
                    loading: false,
                }, () => {
                    Swal.fire({
                        title: 'Account creato!',
                        icon: 'success'
                    })
                    this.props.history.push("/organico/dettaglio-personale/" + response.id);
                });
            },
            (error: any) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.setState({
                    loading: false,
                }, () => {
                    Swal.fire({
                        title: 'Error',
                        text: resMessage,
                        icon: 'error'
                    })
                });
            }
        );
    }

    componentWillUnmount() {
        PersonTypeService.abort();
    }

    render() {
        const { formFields, loading } = this.state;
        let initialValues: any = {};
        return (formFields.length > 0 && <React.Fragment>
            <div className="custom-container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema.bind(this)}
                    onSubmit={this.handleSubmit.bind(this)}
                >
                    <Form className="card">
                        <div className="card-body">
                            <h3 className="mb-3">Aggiungi nuovo profilo</h3>
                            {formFields.map((item: FormFields, key: number) => {
                                return <div className="mb-3" key={key}>
                                    <label className="form-label">{item.label}</label>
                                    {item.type === 'select' ?
                                        <Field innerRef={(el: any) => this.innerRefs[item.name] = el} as={item.type} name={item.name} className={item.class}>
                                            <option key={''} value={''}>---</option>
                                            {item.values && item.values.map((item: any) => {
                                                return <option key={item.key} value={item.key}>{item.value}</option>
                                            })}
                                        </Field>
                                        :
                                        <Field innerRef={(el: any) => this.innerRefs[item.name] = el} name={item.name} type={item.type} className={item.class} />
                                    }
                                    <ErrorMessage
                                        name={item.name}
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                            })}
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm me-1"></span>
                                    )}
                                    <span>Salva</span>
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </React.Fragment>
        );
    }
}