import { Component } from 'react';
import EventBus from '../../common/EventBus';
import { IDispositivo } from '../../helpers/interfaces/magazzino/magazzino-dispositivi';
import magazzinoDispositiviService from '../../services/api/magazzino/magazzino-dispositivi.service';
import { IServizioDaDisabilitare, ILicenzaDaDisabilitare } from '../../helpers/interfaces/magazzino/magazzino-home'
import magazzinoHomeService from '../../services/api/magazzino/magazzino-home.service';
import Table from '../../common/TableComponent';
import { getLicenzeDaDisabilitareColumnDefs, getServiziDaDisabilitareColumnDefs, listLicenzeDaDisabilitareColumns, listServiziDaDisabilitareColumns } from '../../config/tableColumns/magazzino/magazzino-home';

type State = {
    dispositivi: IDispositivo[];
    tipologie: string[];
    dispositiviDisponibili: IDispositivo[];
    dispositiviDaResettare: number;
    serviziDaDisabilitare: IServizioDaDisabilitare[];
    licenzeDaDisabilitare: ILicenzaDaDisabilitare[]
}

export class MagazzinoHome extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            dispositivi: [],
            tipologie: [],
            dispositiviDisponibili: [],
            dispositiviDaResettare: 0,
            serviziDaDisabilitare: [],
            licenzeDaDisabilitare: []
        }
    }

    async componentDidMount(): Promise<void> {
        // loading data
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const dispositiviPromise = magazzinoDispositiviService.getAll();
        const serviziDaDisabilitarePromise = magazzinoHomeService.getAllServiceToDisable();
        const licenzeDaDisabilitarePromise = magazzinoHomeService.getAllLicencesToDisable();

        await Promise.all([dispositiviPromise, serviziDaDisabilitarePromise, licenzeDaDisabilitarePromise]).then(
            (values) => {
                const dispositiviDaResettare = values[0].filter(d => d.stato_nome === "da resettare").length;
                const dispositiviDisponibili = values[0].filter(d => d.censimento === true);
                let tipologie = values[0].map(d => d.tipologia_nome)
                tipologie = tipologie.filter((item, pos) => {
                    return tipologie.indexOf(item) === pos;
                })
                this.setState(
                    {
                        dispositivi: values[0],
                        serviziDaDisabilitare: values[1],
                        licenzeDaDisabilitare: values[2],
                        dispositiviDaResettare,
                        dispositiviDisponibili,
                        tipologie
                    },
                    () => EventBus.dispatch("hideLoader")
                )
            },
            error => EventBus.dispatch("hideLoader")
        );
    }

    render() {
        const { tipologie, dispositivi, dispositiviDisponibili, serviziDaDisabilitare, licenzeDaDisabilitare } = this.state

        const buttons = ['excel', 'pdf', 'print']

        return (
            <div className='custom-container'>
                <div className='card'>
                    <div className='card-body'>
                        <h3 className='pb-3'>Materiale disponibile</h3>
                        <div className="d-flex flex-wrap">
                            {
                                dispositiviDisponibili.length > 0 ? tipologie.map(tipologia => {
                                    return (
                                        <div className='col-lg-3 col-md-6 col-12 pe-md-2 mb-2' key={tipologia.replace(' ', '-').toLowerCase()}>
                                            <div className='card'>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                    <h4 className='m-0'>{tipologia}</h4>
                                                    <p>{dispositiviDisponibili.filter(d => d.tipologia_nome === tipologia).length}/{dispositivi.filter(d => d.tipologia_nome === tipologia).length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <p>Non sono presenti dispositivi</p>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column flex-md-row justify-content-around mt-3'>
                    {
                        serviziDaDisabilitare.length !== 0 && <div className='col-12 col-md-6 py-2 py-md-0 pe-md-1'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h3>Servizi da disabilitare</h3>
                                    <Table id={'table_magazzino_servizi_da_disabilitare'} columns={listServiziDaDisabilitareColumns} columnDefs={getServiziDaDisabilitareColumnDefs()} datas={serviziDaDisabilitare} buttons={buttons} />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        licenzeDaDisabilitare.length !== 0 && <div className='col-12 col-md-6 py-2 py-md-0 ps-md-1'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h3>Licenze da disabilitare</h3>
                                    <Table id={'table_magazzino_servizi_da_disabilitare'} columns={listLicenzeDaDisabilitareColumns} columnDefs={getLicenzeDaDisabilitareColumnDefs()} datas={licenzeDaDisabilitare} buttons={buttons} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default MagazzinoHome