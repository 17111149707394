export const listContrattiColumns = [
    {
        title: 'Azioni',
        data: 'id'
    },
    {
        title: 'Contratto',
        width: 'auto',
        data: 'tipocontratto_nome'
    },
    {
        title: 'Mansione',
        width: 'auto',
        data: 'mansione'
    }, {
        title: 'Assunzione',
        width: 'auto',
        data: 'data_assunzione'
    }, {
        title: 'Fine Rapporto',
        width: 'auto',
        data: 'data_fine_rapporto'
    }, {
        title: 'Fine Prova',
        width: 'auto',
        data: 'data_fine_prova'
    }, {
        title: 'Proroghe',
        width: 'auto',
        data: 'numero_proroghe'
    }, {
        title: 'Sede Operativa',
        width: 'auto',
        data: 'sede_operativa'
    }, {
        title: 'Sede Contrattuale',
        width: 'auto',
        data: 'sede_contrattuale'
    }, {
        title: 'Ufficio',
        width: 'auto',
        data: 'ufficio'
    }, {
        title: 'Tutor',
        width: 'auto',
        data: 'tutorname'
    }, {
        title: 'CCNL',
        width: 'auto',
        data: 'ccnl'
    }, {
        title: 'Protetta',
        width: 'auto',
        data: 'protetta'
    }, {
        title: 'Ticket',
        width: 'auto',
        data: 'ticket'
    }, {
        title: 'Valore Ticket',
        width: 'auto',
        data: 'ticket_value'
    }, {
        title: 'Fine Contratto',
        width: 'auto',
        data: 'fine_contratto_nome'
    }, {
        title: 'Nota',
        width: 'auto',
        data: 'nota'
    }];

export function getListContrattiColumnDefs() {
    return [
        {
            targets: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return `<a class="view_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;
            },
        },
    ];
}