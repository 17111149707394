import { AxiosResponse } from "axios";
import { ITimetableAddRequest, ITimetableAddResponse, ITimetable } from "../../helpers/interfaces/timetable";
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class TimetableService {

  async get(id: number): Promise<ITimetable> {
    return await axios.get(API_URL + 'timetable/get/' + id)
      .then((response: AxiosResponse<ITimetable>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async getAll(): Promise<Array<ITimetable>> {
    return await axios.get(API_URL + 'timetable/list')
      .then((response: AxiosResponse<Array<ITimetable>>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async getExpiring(): Promise<Array<ITimetable>> {
    return await axios.get(API_URL + 'timetable/expiring')
      .then((response: AxiosResponse<Array<ITimetable>>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async getGestite(): Promise<ITimetable[]> {
    return await axios.get(API_URL + 'timetable/listmanaged')
      .then((response: AxiosResponse<ITimetable[]>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async getNonGestite(): Promise<any[]> {
    return await axios.get(API_URL + 'timetable/listunmanaged')
      .then((response: AxiosResponse<any[]>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async add(data: ITimetableAddRequest) {
    return await axios.post(API_URL + 'timetable/add', {
      ...data
    })
      .then((response: AxiosResponse<ITimetableAddRequest | ITimetableAddResponse>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async put(id: number, data: ITimetableAddRequest) {
    return await axios.put(API_URL + 'timetable/update/' + id, data)
      .then((response: AxiosResponse<ITimetableAddRequest | ITimetableAddResponse>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async managed(id: number) {
    return await axios.put(API_URL + 'timetable/managed/' + id)
      .then((response: AxiosResponse<Array<ITimetable>>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async unmanaged(id: number) {
    return await axios.put(API_URL + 'timetable/unmanaged/' + id)
      .then((response: AxiosResponse<Array<ITimetable>>) => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }

  async delete(id: number) {
    return await axios.delete(API_URL + 'timetable/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(error); });
  }
}

export default new TimetableService();