import * as Yup from "yup";
import { FormFields } from "../../../../../helpers/interfaces/generic";

export function getEditLicenzaFormFields(): Array<FormFields> {
  return [
    {
      'label': 'Data Consegna',
      'name': 'data_consegna',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data Restituzione',
      'name': 'data_restituzione',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
    {
      'label': 'Note',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}