import React, { Component } from 'react'
import EventBus from '../../../common/EventBus'
import Table from '../../../common/TableComponent'
import getServiziColumnDefs, { listServiziColumns } from '../../../config/tableColumns/magazzino/magazzino-servizi'
import { IMagazzinoServizio } from '../../../helpers/interfaces/magazzino/magazzino-servizi'
import serviziService from '../../../services/api/servizi.service'

type State = {
    servizi: IMagazzinoServizio[]
}

export default class MagazzinoServizi extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            servizi: []
        }
    }

    async componentDidMount(): Promise<void> {

        EventBus.dispatch("showLoader", { text: 'Caricamento dei servizi in corso...' });

        await serviziService.getAll()
            .then(
                servizi => this.setState(
                    {
                        servizi
                    },
                    () => EventBus.dispatch("hideLoader")
                ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    render() {
        const { servizi } = this.state;

        const buttons = ['excel', 'pdf', 'print']

        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Servizi</h2>
                                <a href="/magazzino/servizi/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi servizio</span>
                                </a>
                            </div>
                            {
                                servizi.length > 0 &&
                                <Table id={'table_magazzino_servizi'} columns={listServiziColumns} columnDefs={getServiziColumnDefs()} datas={servizi} buttons={buttons} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
