// import React, { Component, useCallback, useMemo } from 'react'
import { Component } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate, ReactEditor, } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  BaseEditor,
  EditorMarks
} from 'slate'
import { withHistory, HistoryEditor } from 'slate-history'
import { Button, Icon, Toolbar } from './rich-text-editor-components'
import './rich-text-editor-components/style.css'

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type ParagraphElement = {
  type: 'paragraph'
  align?: any
  children: CustomText[]
}

export type HeadingElement = {
  type: 'heading'
  level: number
  children: CustomText[]
}

export type CodeElement = {
  type: 'code'
  children: CustomText[]
}

export type BQElement = {
  type: 'block-quote'
  children: CustomText[]
}

// export type ColorElement = {
//   type: 'coloured-text'
//   children: CustomText[]
// }

export type CustomElement = ParagraphElement | HeadingElement | CodeElement | BQElement // | ColorElement

export type FormattedText = { text: string; bold?: true; italic?: true; code?: true }

export type CustomText = FormattedText

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
  }
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+s': 'strikethrough',
  'mod+`': 'code',
  'mod+c': 'color'
}
const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

type Props = {
  note: string,
  noteUpdateCallback: CallableFunction
};

// const RichTextEditor  = () => {
//     const renderElement = useCallback(props => <Element {...props} />, [])
//     const renderLeaf = useCallback(props => <Leaf {...props} />, [])
//     const editor = useMemo(() => withHistory(withReact(createEditor())), [])
//
//     return (
//       <Slate editor={editor} initialValue={initialValue}>
//         <Toolbar>
//           <MarkButton format="bold" icon="format_bold" />
//           <MarkButton format="italic" icon="format_italic" />
//           <MarkButton format="underline" icon="format_underlined" />
//           <MarkButton format="code" icon="code" />
//           <BlockButton format="heading-one" icon="looks_one" />
//           <BlockButton format="heading-two" icon="looks_two" />
//           <BlockButton format="block-quote" icon="format_quote" />
//           <BlockButton format="numbered-list" icon="format_list_numbered" />
//           <BlockButton format="bulleted-list" icon="format_list_bulleted" />
//           <BlockButton format="left" icon="format_align_left" />
//           <BlockButton format="center" icon="format_align_center" />
//           <BlockButton format="right" icon="format_align_right" />
//           <BlockButton format="justify" icon="format_align_justify" />
//         </Toolbar>
//         <Editable
//           renderElement={renderElement}
//           renderLeaf={renderLeaf}
//           placeholder="Enter some rich text…"
//           spellCheck
//           autoFocus
//           onKeyDown={event => {
//             for (const hotkey in HOTKEYS) {
//               if (isHotkey(hotkey, event as any)) {
//               event.preventDefault()
//               const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS]
//               toggleMark(editor, mark)
//               }
//             }
//           }}
//         />
//       </Slate>
//     )
// }

class RichTextEditor extends Component<Props> {
  renderElement = (props: any) => <Element {...props} />
  renderLeaf = (props: any) => <Leaf {...props} />
  editor = withHistory(withReact(createEditor()))

  initialValue: Descendant[] = 
  (this.props.note && this.props.note !== "") ? 
    (this.props.note).includes("type") && (this.props.note).includes("children") ?
      JSON.parse(this.props.note) 
      : [{ type: 'paragraph', children: [{ text: this.props.note }] }]
  : [{ type: 'paragraph', children: [{ text: '' }] }];
  
  render () {
    return ( 
      <Slate
        editor={this.editor}
        initialValue={this.initialValue}
        onChange={value => {
          const isAstChange = this.editor.operations.some(
            op => 'set_selection' !== op.type
          )
          if (isAstChange) {
            const content = JSON.stringify(value);
            this.props.noteUpdateCallback(content);
          }
        }}
      >
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="strikethrough" icon="format_strikethrough" />
          <MarkButton format="code" icon="code" />
          <MarkButton format="color" icon="format_color_text" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" />
        </Toolbar>
        <Editable
          renderElement={this.renderElement}
          renderLeaf={this.renderLeaf}
          className='text-area'
          placeholder="Digita qui il testo …"
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS]
              toggleMark(this.editor, mark)
              }
            }
          }}
        />
      </Slate>
    )
  }
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: any, format: any, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType as keyof typeof n] === format,

    })
  )

  return !!match
}

const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format as keyof EditorMarks] === true : false
}

const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }
  
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    // case 'coloured-text':
    //   return (
    //     <span  style={{ color: '#F3110A'  }} {...attributes}>
    //       {children}
    //     </span>
    //   )  
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }
  
  if (leaf.strikethrough) {
    children = <s>{children}</s>
  }

  if (leaf.color) {
    children = <span style={{ color: '#F3110A' }}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

// const initialValue: Descendant[] = [
//   {
//     type: 'paragraph',
//     children: [
//       { text: 'This is editable ' },
//       { text: 'rich', bold: true },
//       { text: ' text, ' },
//       { text: 'much', italic: true },
//       { text: ' better than a ' },
//       { text: '<textarea>', code: true },
//       { text: '!' },
//     ],
//   },
//   {
//     type: 'paragraph',
//     children: [
//       {
//         text: "Since it's rich text, you can do things like turn a selection of text ",
//       },
//       { text: 'bold', bold: true },
//       {
//         text: ', or add a semantically rendered block quote in the middle of the page, like this:',
//       },
//     ],
//   },
//   {
//     type: 'block-quote',
//     children: [{ text: 'A wise quote.' }],
//   },
//   {
//     type: 'paragraph',
//     align: 'center',
//     children: [{ text: 'Try it out for yourself!' }],
//   },
// ]

export default RichTextEditor