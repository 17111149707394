import { IFormField, ISelectOption } from "../../../helpers/interfaces/generic";
import * as Yup from 'yup';

export interface ILicenzaForm {
    tipologia_nome: string;
    prodotto_nome: string;
    identificativo: string;
    data_inizio: Date | string | null;
    data_fine: Date | string | null;
    note: string;
    prezzo: string;
}

export const tipologieProdotti: ISelectOption[] = [
    {
        key: 0,
        value: 'Permanente'
    },
    {
        key: 1,
        value: 'Annuale'
    },
    {
        key: 2,
        value: 'Mensile'
    }
]

const requiredMessage = 'Campo obbligatorio'

export function licenzeFormFields(
    allTypes: ISelectOption[],
    allProducts: ISelectOption[]
): IFormField[] {
    return [
        {
            label: 'Tipologia',
            name: 'tipologia_nome',
            type: 'select',
            values: allTypes,
            class: 'form-select',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Prodotto',
            name: 'prodotto_nome',
            type: 'select',
            values: allProducts,
            class: 'form-select',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Identificativo',
            name: 'identificativo',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Data inizio',
            name: 'data_inizio',
            type: 'date',
            class: 'form-control',
        },
        {
            label: 'Data fine',
            name: 'data_fine',
            type: 'date',
            class: 'form-control',
        },
        {
            label: 'Note',
            name: 'note',
            type: 'text',
            class: 'form-control',
        },
        {
            label: 'Prezzo',
            name: 'prezzo',
            type: 'text',
            class: 'form-control',
        },
    ];
}