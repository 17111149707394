import { Component } from "react";

type Props = {};

type State = {};
export default class NotFound extends Component<Props, State> {
  render() {
    return (
      <div className="custom-container">
        <header className="jumbotron">
          <h3>404</h3>
        </header>
      </div>
    );
  }
}