export const listRendicontazioniColumns = [
    { "title": "Nominativo", "data": "person" },
    {
        "title": "mese", "data": null, render: (data: any, type: any, row: any) => {
            return row.mesenumber + '/' + row.annonumber;
        }
    },
    { "title": "Azioni", "data": null },
];

export function getListRendicontazioniRifiutatoApertoColumnDefs() {
    return [
        {
            targets: 2,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const subData = row.idperson + '-' + row.annonumber + '-' + row.mesenumber;
                const dettaglio = `<a class="action-icon custom-icon btn btn-outline-secondary rounded-circle me-1" href="/organico/rendicontazioni/dettaglio/${subData}">
                    <i style="font-size: 18px" class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>`;
                const mail = `<a class="sollecito_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${subData}">
                    <i style="font-size: 18px" class="fa fa-envelope-o" aria-hidden="true"></i>
                </a>`;
                return '<div class="d-flex">' + dettaglio + mail + '</div>';
            },
        },
    ];
}

export function getListRendicontazioniNonRendicontatoColumnDefs() {
    return [
        {
            targets: 2,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const subData = row.idperson + '-' + row.annonumber + '-' + row.mesenumber;
                return `<div class="d-flex">
                    <a class="sollecito_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${subData}">
                        <i style="font-size: 18px" class="fa fa-envelope-o" aria-hidden="true"></i>
                    </a>
                </div>`;
            },
        },
    ];
}

export function getListRendicontazioniGenericColumnDefs() {
    return [
        {
            targets: 2,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const subData = row.idperson + '-' + row.annonumber + '-' + row.mesenumber;
                const dettaglio = `<a class="action-icon custom-icon btn btn-outline-secondary rounded-circle" href="/organico/rendicontazioni/dettaglio/${subData}">
                    <i style="font-size: 18px" class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>`;
                return '<div class="d-flex">' + dettaglio + '</div>';
            },
        },
    ];
}