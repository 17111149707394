import axios from "axios";
import { IDeadlines } from "../../helpers/interfaces/home-amministrazione";
const API_URL = process.env.REACT_APP_API_URL;

class DeadlinesService {
    getAll(): Promise<IDeadlines> {
        return axios.get<IDeadlines>(API_URL + 'datore/deadlines/all')
            .then(response => { return response.data; })
            .catch((error) => { return Promise.reject(); });;
    }

}
export default new DeadlinesService();