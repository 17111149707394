import React, { Component } from "react";
import ModalForm from "../../../../common/ModalForm";
import Table from "../../../../common/TableComponent";
import { getRefusePermessiFerieFormFields } from "../../../../config/formFields";
import { listPermessiFerieConfermateColumns, getListPermessiFerieConfermateColumnDefs } from "../../../../config/tableColumns";
import { FormFields } from "../../../../helpers/interfaces/generic";
import permessiferieService from "../../../../services/api/permessiferie.service";

const $ = require('jquery');

type Props = {
    data: any[]
};

type State = {
    formFields: Array<FormFields>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    richieste: any,
    formInitialValues: { [key: string]: any }
}
export default class ConfermateComponent extends Component<Props, State> {
    apiSubmit: any;
    buttons: string[];

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            richieste: props.data,
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.apiSubmit = null;
        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
    }

    async componentDidMount() {

        $('.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            const idRichiesta = $(e.currentTarget).data('id');
            this.apiSubmit = permessiferieService.refuse;
            this.setState({ formFields: getRefusePermessiFerieFormFields(), formInitialValues: { id: idRichiesta, note: '' }, showModal: true, modalType: 'edit' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { formFields, showModal, modalType, richieste, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="row mt-3">
                <ModalForm showModal={showModal} title={'Rifiuta'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                <h3 className="card-title">Confermate</h3>
                {richieste.length > 0 ?
                    <Table id={"table_confermate"} columns={listPermessiFerieConfermateColumns} columnDefs={getListPermessiFerieConfermateColumnDefs()} datas={richieste} buttons={this.buttons} />
                    : <p>Non sono presenti RICHIESTE</p>}
            </div>
        </React.Fragment>
    }
}