import React, { Component } from "react";
import { Profilo } from "../../helpers/interfaces/user";
import USerService from "../../services/api/user.service";
import moment from "moment";
import 'moment/locale/it';
import { Tab, TabProp } from "../../common/TabComponent";
import Table from "../../common/TableComponent";
import { listJobordersColumns, listDevicesColumns, listServicesColumns, listLicencesColumns, listPermissionsColumns } from "../../config/tableColumns/dipendente";
import eventBus from "../../common/EventBus";
import permessiferieService from "../../services/api/permessiferie.service";
import Select from "react-select";

type Props = {};

type State = {
  profile: Profilo | null;
  causali: Array<Option>;
  selectCausale: Option;
};

type Option = {
  value: any,
  label: string
};

export default class HomeDipendente extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      profile: null,
      causali: [],
      selectCausale: {value: null, label: 'TUTTE LE CAUSALI'},
    };
  }

  async componentDidMount() {
    eventBus.dispatch("showLoader", { text: 'Caricamento della home page del dipendente in corso...' });

    await USerService.getProfile()
      .then(
        (profile) => {
          if (profile) {
            this.setState({ profile });
          }
        },
      );

    await permessiferieService.getCausalDipendente()
      .then(
        (causaliDipendente) => {
          if (causaliDipendente) {
            let causali: Array<Option> = [{value: null, label: 'TUTTE LE CAUSALI'}];
            causaliDipendente.forEach(causale => {
              causali.push({value: causale.name, label: causale.name})
            })
            this.setState({ causali });
          }
        },
      );

    eventBus.dispatch("hideLoader");
  }

  joborders(): JSX.Element {
    const { profile } = this.state;
    let joborderTabs: Array<TabProp> = [];

    if (profile?.personjoborders.active) {
      joborderTabs.push({
        id: 'active-joborder',
        label: 'Attive',
        content: <>
          {profile?.personjoborders.active.length ? (
            <Table id="table_active_joborder" columns={listJobordersColumns} columnDefs={[]} datas={profile?.personjoborders.active ?? []} buttons={null} />
          ) : (
            <div>Nessuna commessa attiva trovata</div>
          )}
        </>,
        isActive: true,
      })
    }

    if (profile?.personjoborders.expired) {
      joborderTabs.push({
        id: 'expired-joborder',
        label: 'Scadute',
        content: <>
          {profile?.personjoborders.expired.length ? (
            <Table id="table_expired_joborder" columns={listJobordersColumns} columnDefs={[]} datas={profile?.personjoborders.expired ?? []} buttons={null} />
          ) : (
            <div>Nessuna commessa scaduta trovata</div>
          )}
        </>
      })
    }

    return joborderTabs.length ? (
      <Tab tabs={joborderTabs} />
    ) : (
      <div>Nessuna commessa trovata</div>
    )
  }

  devices(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.dispositivi?.length ? (
          <Table id="table_devices" columns={listDevicesColumns} columnDefs={[]} datas={profile?.dispositivi ?? []} buttons={null} />
        ) : (
          <div>Nessun dispositivo trovato</div>
        )}
      </>
    );
  }

  licences(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.licenze?.length ? (
          <Table id="table_licences" columns={listLicencesColumns} columnDefs={[]} datas={profile?.licenze ?? []} buttons={null} />
        ) : (
          <div>Nessuna licenzia trovato</div>
        )}
      </>
    );
  }

  services(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.servizi?.length ? (
          <Table id="table_services" columns={listServicesColumns} columnDefs={[]} datas={profile?.servizi ?? []} buttons={null} />
        ) : (
          <div>Nessun servizio trovato</div>
        )}
      </>
    );
  }

  permissions(): JSX.Element {
    const { profile, selectCausale } = this.state;
    let permissionTabs: Array<TabProp & {isActive: boolean}> = [];
    let defaultTab: 'nuova' | 'confermata' | 'rifiutata' = 'nuova';

    if (profile && !profile.permessiferie.nuova) {
      defaultTab = 'confermata';
    } else if (profile && !profile.permessiferie.nuova && !profile.permessiferie.confermata) {
      defaultTab = 'rifiutata';
    }

    if (profile?.permessiferie?.nuova) {
      let nuova: (object | null)[] = [];
      profile?.permessiferie.nuova?.forEach(value => {
        (!selectCausale.value || value.causale === selectCausale.value) && nuova.push(value);
      });

      permissionTabs.push({
        id: 'new-permission',
        label: 'Nuova',
        content: <>
          {nuova.length ? (
            <Table id="table_active_permission" columns={listPermissionsColumns} columnDefs={[]} datas={nuova} buttons={null} />
          ) : (
            <div>Nessuna nuova richiesta trovata{selectCausale.value && ' con il filtro "'+ selectCausale.label +'"'}.</div>
          )}
        </>,
        isActive: defaultTab === 'nuova',
      })
    }

    if (profile?.permessiferie?.confermata) {
      let confermata: (object | null)[] = [];
      profile?.permessiferie.confermata?.forEach(value => {
        (!selectCausale.value || value.causale === selectCausale.value) && confermata.push(value);
      });

      permissionTabs.push({
        id: 'confirmed-permission',
        label: 'Confermate',
        content: <>
          {confermata.length ? (
            <Table id="table_expired_permission" columns={listPermissionsColumns} columnDefs={[]} datas={confermata} buttons={null} />
          ) : (
            <div>Nessuna richiesta confermata trovata{selectCausale.value && ' con il filtro "'+ selectCausale.label +'"'}.</div>
          )}
        </>,
        isActive: defaultTab === 'confermata',
      })
    }

    if (profile?.permessiferie?.rifiutata) {
      let rifiutata: (object | null)[] = [];
      profile?.permessiferie.rifiutata?.forEach(value => {
        (!selectCausale.value || value.causale === selectCausale.value) && rifiutata.push(value);
      });

      permissionTabs.push({
        id: 'rejected-permission',
        label: 'Rifiutate',
        content: <>
          {rifiutata.length ? (
            <Table id="table_expired_permission" columns={listPermissionsColumns} columnDefs={[]} datas={rifiutata} buttons={null} />
          ) : (
            <div>Nessuna richiesta rifiutata trovata{selectCausale.value && ' con il filtro "'+ selectCausale.label +'"'}.</div>
          )}
        </>,
        isActive: defaultTab === 'rifiutata',
      })
    }

    return permissionTabs.length ? (
      <Tab useState={true} tabs={permissionTabs} />
    ) : (
      <div>Nessuna richiesta di permesso/ferie trovata</div>
    )
  }

  render() {
    const { profile, causali, selectCausale } = this.state;

    return (
      <div className="custom-container">
        <div className='card'>
          <div className='card-body'>
            <h3 className='pb-3'>Home dipendente</h3>
            {profile?.santoPatrono && (
              <>
                <div className="alert alert-warning">
                  <h4>Festività</h4>
                  <span>
                    Il tuo Santo Patrono è quello di <strong>{profile.santoPatrono.comune}</strong>{' '}
                    il <strong>{moment(profile.santoPatrono.data).format('DD/MM')}</strong>
                  </span>
                </div>
              </>
            )}
            <Tab tabs={[
              { id: "commesse", label: "Commesse", content: this.joborders(), isActive: true },
              { id: "dispositivi", label: "Dispositivi", content: this.devices() },
              { id: "licenze", label: "Licenze", content: this.licences() },
              { id: "servizi", label: "Servizi", content: this.services() },
              { id: "permessi-ferie", label: "Permessi/Ferie", content: 
                <>
                  <div className="col-3"><strong>Filtra per causale:</strong></div>
                  <Select 
                    options={causali}
                    value={selectCausale}
                    className="col-3 mb-3"
                    onChange={(option) => {this.setState({selectCausale: {value: option?.value, label: option?.label ?? option?.value}})}}
                  />
                  {this.permissions()}
                </>
              },
            ]} />
          </div>
        </div>
      </div>
    );
  }
}