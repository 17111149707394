import { AxiosResponse } from 'axios';
import { IHomeCards, IHomeTab, ILettereDispositivi, IMaterialeNonRiconsegnato } from '../../helpers/interfaces/home-amministrazione';
import axios from '../api';

const API_URL = process.env.REACT_APP_API_URL;

class HomeAmministrazioneService {

    getAllCardsData(): Promise<IHomeCards> {
        return axios.get<IHomeCards>(API_URL + 'datore/person/home/cards')
            .then((response: AxiosResponse<IHomeCards>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    getAllTabs(): Promise<IHomeTab[]> {
        return axios.get<IHomeTab[]>(API_URL + 'v2/stats/dashboard/administration')
            .then((response: AxiosResponse<IHomeTab[]>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    getAllLetters() {
        return axios.get<ILettereDispositivi>(API_URL + 'magazzino/dispositivi/lettere/pending')
            .then((response: AxiosResponse<ILettereDispositivi>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    getAllMissingMaterials() {
        return axios.get<IMaterialeNonRiconsegnato[]>(API_URL + 'magazzino/person/dispositivi/missing')
            .then((response: AxiosResponse<IMaterialeNonRiconsegnato[]>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }
}

export default new HomeAmministrazioneService()