import axios, { AxiosResponse } from "axios"
import { IContrattoForm } from "../../../config/formFields/magazzino/magazzino-contratti";
import { IMagazzinoContrattoNoleggio } from "../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi";

const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoContrattiNoleggiService {

    getAll(): Promise<IMagazzinoContrattoNoleggio[]> {
        return axios.get<IMagazzinoContrattoNoleggio[]>(API_URL + "magazzino/contratti/noleggio")
            .then(response => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

    getOne(id: string): Promise<IMagazzinoContrattoNoleggio> {
        return axios.get<IMagazzinoContrattoNoleggio>(API_URL + 'magazzino/contratti/noleggio/' + id)
            .then((response: AxiosResponse<IMagazzinoContrattoNoleggio>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

    add(body: IContrattoForm): Promise<IMagazzinoContrattoNoleggio> {
        return axios.post<IContrattoForm, AxiosResponse<IMagazzinoContrattoNoleggio>>(API_URL + 'magazzino/contratti/noleggio', body)
            .then((response: AxiosResponse<IMagazzinoContrattoNoleggio>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

    edit(id: string, body: IContrattoForm): Promise<IMagazzinoContrattoNoleggio> {
        return axios.put<IContrattoForm, AxiosResponse<IMagazzinoContrattoNoleggio>>(API_URL + 'magazzino/contratti/noleggio/' + id, body)
            .then((response: AxiosResponse<IMagazzinoContrattoNoleggio>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

}

export default new MagazzinoContrattiNoleggiService()