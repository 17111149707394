import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import EventBus from "../../common/EventBus";
import GroupsService from "../../services/api/groups.service";
import { getNewGroupFormFields } from "../../config/formFields";
import { FormFields } from "../../helpers/interfaces/generic";
import Swal from "sweetalert2";

type Props = {
  history: {
    push(url: string): void;
  };
};

type State = {
  formFields: Array<FormFields>;
  loading: boolean;
}

export default class NewGroup extends Component<Props, State> {
  innerRefs: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      formFields: [],
      loading: false,
    }

    this.innerRefs = [];
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
    const newGroupFields = getNewGroupFormFields();

    if (newGroupFields.length > 0) {
      this.setState({ formFields: newGroupFields });
    }

    EventBus.dispatch("hideLoader");
  }

  validationSchema() {
    let validations: any = {};
    this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

    return Yup.object().shape(validations);
  }

  handleSubmit(formValue: any) {
    this.setState({
      loading: true
    });

    GroupsService.add(formValue).then(
      () => {
        this.setState({
          loading: false,
        }, () => {
          Swal.fire({
            title: 'Operazione riuscita',
            text: 'Gruppo creato!',
            icon: 'success',
          })
          this.props.history.push('/gruppi/elenco-gruppi');
        });
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          loading: false,
        }, () => {
          Swal.fire({
            title: 'Errore',
            text: resMessage,
            icon: 'error',
          })
        });
      }
    );
  }

  render() {
    const { formFields, loading } = this.state;
    let initialValues: any = {};

    return (formFields.length > 0 && <React.Fragment>
      <div className="custom-container">

        <Formik
          initialValues={initialValues}
          validationSchema={this.validationSchema.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          isInitialValid={false}
        >
          {({
            isValid
          }) => (
            <Form className="card">
              <div className="card-body">
                <h3 className="pb-3">Nuovo gruppo</h3>
                {formFields.map((item: FormFields, key: number) => {
                  return <div className="mb-3" key={key}>
                    <label className="form-label">{item.label}</label>
                    {item.type === 'select' ?
                      <Field innerRef={(el: any) => this.innerRefs[item.name] = el} as={item.type} name={item.name} className={item.class} default={item.value ?? ''}>
                        {item.value === undefined && <option key={''} value={''}>---</option>}
                        {item.values && item.values.map((item: any) => {
                          return <option key={item.key} value={item.key}>{item.value}</option>
                        })}
                      </Field>
                      :
                      <Field innerRef={(el: any) => this.innerRefs[item.name] = el} name={item.name} type={item.type} className={item.class} default={item.value ?? ''} />
                    }
                    <ErrorMessage
                      name={item.name}
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                })}
                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary" disabled={loading || !isValid}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    <span>Salva</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
    );
  }
}