import React, { Component } from 'react';
import personService from '../../../services/api/person.service';
import { Person } from '../../../helpers/interfaces/person';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { resetButtonsStyle } from '../../../helpers/settings/buttons-icons-styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require('jquery')

type Props = {
  person: Person,
};

type State = {
  profileImage: string,
  copied: boolean
}
class InfoDipendenteComponent extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      profileImage: this.props.person.adminimage,
      copied: false
    }
  }

  getContrattoAttivo = (person: Person) => {
    let contratto_attivo: any = [];

    if (person.contratto_attivo && person.contratto_attivo.length > 0) {
      contratto_attivo = person.contratto_attivo[0];
    }

    return contratto_attivo;
  }

  handleEditProfile(event: React.ChangeEvent<HTMLInputElement>) {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file !== null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        const formData = new FormData()
        formData.append('adminimage', file);
        personService.updateProfileImage(this.props.person.id, formData)
          .then(
            response => {
              if (response) {
                this.setState({ profileImage: response.adminImage })
              }
            }
          )
      }
    }
  }

  render() {
    const { person } = this.props;
    const { profileImage } = this.state;
    const contrattoAttivo: any = this.getContrattoAttivo(person);

    return <div className="card">
      <div className="card-body p-4">
        <div className={'badge ' + (person.status === 'E' || person.status === 'N' ? 'bg-success' : (person.status === 'S' ? 'bg-warning' : 'bg-danger'))}>
          {
            person.status === 'E' || person.status === 'N' ? 'Attivo' : (person.status === 'S' ? 'Sospeso' : 'Eliminato')
          }
        </div>
        <div className='d-flex align-items-center justify-content-center' style={{ position: "relative" }}>
          <span className="avatar avatar-xl mb-3 avatar-rounded" style={{ backgroundImage: 'url("data:image/png;base64,' + profileImage + '")', position: 'relative', width: '188px', height: '188px' }}>
            <div style={{ position: 'absolute', bottom: '0', right: '16px' }}>
              <button className="custom-icon btn btn-primary rounded-circle" type="button" onClick={() => {
                $('#profileImage').trigger('click')
              }}>
                <i style={{ fontSize: '18px' }} className="fa fa-pencil" aria-hidden="true"></i>
              </button>
              <input hidden accept=".jpg,.jpeg,.png" onChange={(event) => this.handleEditProfile(event)} type="file" id="profileImage" />
            </div>
          </span>
        </div>
        <h3 className="m-0 mb-1 text-center">{person.lastname} {person.name}</h3>
        <div className="mt-2">
          {
            person.anagrafica.telefono && <CopyToClipboard text={person.anagrafica.telefono}
              onCopy={() => this.setState({ copied: true }, () => toast.success('Numero copiato!', { className: "bg-dark" }))}>
              <button style={resetButtonsStyle} className='nav-link'>{person.anagrafica.telefono}</button>
            </CopyToClipboard>
          }
          {
            person.registrationemail && <CopyToClipboard text={person.registrationemail}
              onCopy={() => this.setState({ copied: true }, () => toast.success('Email copiata!', { className: "bg-dark" }))}>
              <button style={resetButtonsStyle} className='nav-link'>{person.registrationemail}</button>
            </CopyToClipboard>
          }
          {
            this.state.copied ? <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
              theme="dark"
            /> : null
          }
        </div>
        <div className='d-flex justify-content-center mt-2'>
          <img className="logo" width="116px" src={person.businessunit.logourl} alt="businessunit-profile-logo" />
        </div>
      </div>
      <div className="card-body">
        <div className="mb-1 d-flex justify-content-between">
          <label className="h5"><strong>Contratto</strong></label>
          <span>{contrattoAttivo.tipocontratto_nome}</span>
        </div>
        <div className="mb-1 d-flex justify-content-between">
          <label className="h5"><strong>Mansione</strong></label>
          <span>{contrattoAttivo.mansione}</span>
        </div>
        <div className="mb-1 d-flex justify-content-between">
          <label className="h5"><strong>Data assunzione</strong></label>
          <span>{contrattoAttivo.data_assunzione}</span>
        </div>
        <div className="mb-1 d-flex justify-content-between">
          <label className="h5"><strong>Data fine rapporto</strong></label>
          <span>{contrattoAttivo.data_fine_rapporto}</span>
        </div>
      </div>
      <div className="d-flex my-2">
        <a href={'mailto:' + person.registrationemail} className="card-btn">
          <i style={{ fontSize: "18px" }} className="fa fa-envelope-o me-1" aria-hidden="true"></i>
          Invia email
        </a>
      </div>
    </div>
  }
}
export default InfoDipendenteComponent;