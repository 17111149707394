import { ListColumns } from "../../helpers/interfaces/generic";
import { ITipoUtente } from "../../helpers/interfaces/tipi-utente";
import { statusColors } from "../../helpers/settings/status-settings";

// table columns definitions
export const listTipiUtenteColumns: ListColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Tipo", data: "type" },
    { title: "Visibilità", data: "visible" },
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getListTipiUtenteColumnDefs() {
    return [
        {
            targets: 0,
            render: (id: number, type: string, row: ITipoUtente) => {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${id}"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
        {
            targets: listTipiUtenteColumns.length - 1,
            render: (id: number, type: string, row: ITipoUtente) => {
                switch (row.visible) {
                    case false:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;
                    default:
                        return `<div style="width: 16px; height: 16px; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }
            }
        }
    ];
}