import React, { Component, createRef } from "react";
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';

import PermessiFerieService from "../../../services/api/permessiferie.service";
import StatiPermessiFerieService from "../../../services/api/statipermessiferie.service";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import itLocale from '@fullcalendar/core/locales/it';
import { FormFields } from "../../../helpers/interfaces/generic";

type Props = {};

type State = {
    formFields: Array<FormFields>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    richieste: any,
    formInitialValues: { [key: string]: any },
    stati: any
}
export default class Calendario extends Component<Props, State> {
    apiSubmit: any;
    buttons: string[];
    calendarRef = createRef<FullCalendar>()

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            richieste: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
            stati: []
        }
        this.apiSubmit = null;
        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
    }

    async getPermessiFeriePerMese(action?: string) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        let api = this.calendarRef.current?.getApi()
        if (api) {
            if (action && action === 'next') {
                api.next()
            }
            if (action && action === 'prev') {
                api.prev()
            }
            const results = await PermessiFerieService.getAllForMonth(api.getDate().getFullYear(), api.getDate().getMonth() + 1);
            if (results.length > 0) {
                const richieste = results.map((item) => {
                    return {
                        'title': item.dipendente + ' ' + item.causale.nome,
                        'start': item.inizio,
                        'end': item.fine,
                        'backgroundColor': item.stato.colore
                    }
                });
                this.setState({ richieste: richieste });
                EventBus.dispatch("hideLoader")
            }
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        await Promise.all([
            StatiPermessiFerieService.getAll(),
            this.getPermessiFeriePerMese()
        ]).then(
            res => {
                this.setState({ stati: res[0] });
                EventBus.dispatch("hideLoader");
            }
        )
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { formFields, showModal, modalType, richieste, stati, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="row mt-2">
                <ModalForm showModal={showModal} title={'Rifiuta'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                <FullCalendar
                    ref={this.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    initialView="dayGridMonth"
                    events={richieste}
                    locale={itLocale}
                    customButtons={{
                        prev: {
                            click: async () => await this.getPermessiFeriePerMese('prev')
                        },
                        next: {
                            click: async () => await this.getPermessiFeriePerMese('next')
                        }
                    }}
                />
                <div className="d-flex justify-content-center align-items-center my-2">
                    <h4 className="mb-0 me-1">Stato richieste </h4>
                    {
                        stati.map((stato: any, index: number) => {
                            return <div key={index} className="badge badge-pill me-1" style={{ "color": "#fff", "margin": "5px 0px", "backgroundColor": stato.colore }}>{stato.nome}</div>
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    }
}