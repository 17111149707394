import React, { Component } from 'react';
import './css-loader.css';

type Props = {
  active: boolean
  text: string
};

type State = {
  active: boolean
}

class CssLoader extends Component<Props, State> {
    constructor(props:any) {
        super(props);

        this.state = {
            active: props.active
        }
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if (prevProps.active !== this.props.active) {
          this.setState({active:this.props.active});
        }
      }

    render() {
        const {active} = this.state;
        const {text} = this.props;

        return <div className={"cssLoader loader-default "+(active ? 'is-active':'') } data-text={text ? text : "Caricamento in corso.. Attendere"}></div>
    }
}
export default CssLoader;