import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import TutorsService from "../../services/api/tutors.service";
import PersonService from "../../services/api/person.service";
import { listTutorColumns, getListTutorColumnDefs } from "../../config/tableColumns";
import { getEditTutorFormFields, getAddTutorFormFields } from "../../config/formFields";
import Table from '../../common/TableComponent';
import Swal from "sweetalert2";
import { FormFields } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<FormFields>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    tutors: any,
    formInitialValues: { [key: string]: any }
}
export default class Tutor extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[];

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            tutors: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const tutors = await TutorsService.getAll();
        if (tutors.length > 0) {
            this.setState({ tutors });
        }
        EventBus.dispatch("hideLoader");

        $('#table_tutor.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Tutor in corso...' });
            const idTutor = $(e.currentTarget).data('id');
            const tutor: any = await TutorsService.get(idTutor);
            const person_all = await PersonService.getAllForSearch();
            if (tutor && person_all) {
                let persons = person_all.data.map((item) => {
                    return { key: item.id, value: item.nominativo }
                });

                this.setState({ formFields: getEditTutorFormFields(persons), formInitialValues: { id: idTutor, personid: tutor.tutorid } }, () => {
                    this.apiSubmit = TutorsService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    async openAddModale() {
        this.apiSubmit = TutorsService.add;
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        await PersonService.getAllForSearch().then(
            response => {
                if (response) {
                    let persons = response.data.map(item => {
                        return { key: item.id, value: item.nominativo }
                    });

                    this.setState({
                        formFields: getAddTutorFormFields(persons), formInitialValues: {}, showModal: true, modalType: 'add'
                    });
                }
            },
            error => {
                Swal.fire(
                    'Errore.',
                    'Siamo spiacenti, non è stato possibile caricare l\'elenco del personale',
                    'error'
                );
            }
        )
            .catch((error) => {
                Swal.fire(
                    'Errore imprevisto.',
                    'Siamo spiacenti, si è verificato un errore imprevisto durante il caricamento dell\'elenco del personale',
                    'error'
                );
            });

        EventBus.dispatch("hideLoader");
    }

    render() {
        const { formFields, showModal, modalType, tutors, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="card-title">Tutor</h3>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi tutor</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Tutor'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {tutors.length > 0 && <Table id="table_tutor" columns={listTutorColumns} columnDefs={getListTutorColumnDefs()} datas={tutors} buttons={this.buttons} />}
                    </div>
                </div>
            </div>

        </React.Fragment>
    }
}