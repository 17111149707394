import * as Yup from 'yup'
import { ISelectOption, IFormField } from '../../../helpers/interfaces/generic';
import { IMagazzinoContrattoNoleggio, isMagazzinoContrattoNoleggio } from '../../../helpers/interfaces/magazzino/magazzino-contratti-noleggi';
import { IMagazzinoStatoDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo';
import { IMagazzinoTipologiaDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-dispositivi';

export interface IDispositivoForm {
    stato_nome: string;
    tipologia_nome: string;
    marca_modello: string;
    serial_number: string;
    noleggio: string;
    contrattonoleggio: string;
    inMagazzino: string;
    cespiti: string;
    data_acquisto: Date | string | null;
    spec_tecniche: string;
    note: string;
}

const genericOptions: ISelectOption[] = [
    {
        key: 0,
        value: "No"
    },
    {
        key: 1,
        value: "Sì"
    }
]

const requiredMessage = 'Campo obbligatorio'

// function: mapping a generic list to return a new list for the select menus
export function mapListsData(list: IMagazzinoTipologiaDispositivo[] | IMagazzinoStatoDispositivo[] | IMagazzinoContrattoNoleggio[]): { key: number, value: string }[] {
    return list.map(item => { return { key: item.id, value: item.nome + (isMagazzinoContrattoNoleggio(item) ? ' - ' + item.codice : '') } })
}

// function: setting form fields for devices' add/edit page
export function dispositiviFormFields(
    allStates: ISelectOption[],
    allTypes: ISelectOption[],
    allContracts: ISelectOption[]
): IFormField[] {
    return [
        {
            label: 'Stato',
            name: 'stato_nome',
            type: 'select',
            values: allStates,
            class: 'form-select'
        },
        {
            label: 'Tipologia',
            name: 'tipologia_nome',
            type: 'select',
            values: allTypes,
            class: 'form-select',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Marca/Modello',
            name: 'marca_modello',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Serial number',
            name: 'serial_number',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Noleggio',
            name: 'noleggio',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Contratto noleggio',
            name: 'contrattonoleggio',
            type: 'select',
            values: allContracts,
            class: 'form-select',
        },
        {
            label: 'In magazzino',
            name: 'inMagazzino',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Cespiti',
            name: 'cespiti',
            type: 'select',
            values: genericOptions,
            class: 'form-select',
        },
        {
            label: 'Data acquisto',
            name: 'data_acquisto',
            type: 'date',
            class: 'form-control',
        },
        {
            label: 'Spec. tecniche',
            name: 'spec_tecniche',
            type: 'text',
            class: 'form-control',
        },
        {
            label: 'Note',
            name: 'note',
            type: 'text',
            class: 'form-control',
        },
        {
            label: 'File allegati',
            name: 'attachments',
            type: 'file',
            class: 'form-control',
        },
    ];
}