import * as React from 'react';
import Swal from 'sweetalert2';
import { base64Decode } from '../common/Base64';
import eventBus from '../common/EventBus';
import SignDocumentService from '../services/api/signDocument.service';
import $ from 'jquery';
// import { decodeChars } from '../helpers/charConverter';

type Props = {
    history: {
        push(url: string): void;
    };
    match: {
        params: {
            id: string
        };
    }
};
type State = {
    channels: Array<string>;
    sendOtp: boolean;
    otp: string;
    type: string;
}

export default class SignDocument extends React.Component<Props, State> {
    token: string = '';
    channel: string = '';

    constructor(props: Props) {
        super(props);
        this.state = {
            channels: [],
            sendOtp: false,
            otp: '',
            type: 'assegnazione',
        };
    }

    async componentDidMount() {
        eventBus.dispatch("showLoader", { text: 'Caricamento documento in corso...' });
        this.token = this.props.match.params.id;

        if (this.token.substring(0, 3) === 'PYA') {
            this.setState({ type: 'nomina' });
        }
        if (this.token.substring(0, 3) === 'HRD') {
            this.setState({ type: 'hrdocuments' });
        }

        this.token !== '' && await SignDocumentService.getDocument(this.token).then(
            (response) => {
                const document = base64Decode(response.html);
                this.setState({ channels: response.channels });
                this.channel = response.channels[0];
                $('#document').html(document);
                $('div.getdocumentsign').on('change', 'select#channel', (e) => {
                    this.channel = e.target.value;
                });

                $('div.getdocumentsign').on('click', 'button#getdocumentsign', (e) => {
                    SignDocumentService.sendOTP(this.token, this.channel).then(
                        (response) => {
                            if (response && typeof response.error !== 'undefined') {
                                this.setState({ sendOtp: false });
                                return Swal.fire(
                                    "Errore",
                                    response.error,
                                    'error'
                                )
                            }

                            Swal.fire(
                                'OTP richiesto',
                                'Abbiamo inviato il codice OTP su ' + this.channel,
                                'success'
                            );

                            this.setState({ sendOtp: true });

                            /** */
                            $('div.getdocumentsign').on('change', 'input#otp', (e) => {
                                this.setState({ otp: e.target.value });
                            });

                            $('div.getdocumentsign').on('click', 'button#sendotp', () => {
                                if (!this.state.otp.length) {
                                    return Swal.fire(
                                        "Codice OTP assente",
                                        "Inserire il codice OTP ricevuto per continuare.",
                                        'warning'
                                    )
                                }

                                let fields: object = {};
                                // $('div#document input,div#document select,div#document textarea').each(function (index) {
                                $('div#document input[name=sede_prevalente]').each(function (index) {
                                    const input = $(this);
                                    Object.assign(fields, { sede_prevalente: input.val()?.toString() });
                                });

                                SignDocumentService.checkOTP(this.state.otp, this.token, JSON.stringify(fields)).then(
                                    (response) => {
                                        if (response && typeof response.error !== 'undefined') {
                                            return Swal.fire(
                                                "Errore",
                                                response.error,
                                                'error'
                                            )
                                        }

                                        Swal.fire({
                                            title: 'Procedura completata',
                                            html: 'Il documento è stato firmato con successo tramite codice OTP.<br /><br /> Riceverai una copia del tuo documento firmato sulla tua casella di posta elettronica',
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#3085d6',
                                            confirmButtonText: 'OK',
                                        }).then(async (result) => {
                                            if (result.isConfirmed || result.isDismissed) {
                                                this.props.history.push('/');
                                            }
                                        });
                                    },
                                    error => {
                                        Swal.fire(
                                            "Errore",
                                            "Si è verificato un errore durante l'invio del codice OTP.",
                                            'error'
                                        )
                                    })
                                    .catch(error => {
                                        Swal.fire(
                                            "Errore imprevisto",
                                            "Si è verificato un errore imprevisto durante l'invio del codice OTP.",
                                            'error'
                                        )
                                    });
                            });
                            /** */
                        },
                        error => {
                            Swal.fire(
                                "Errore",
                                "Si è verificato un errore durante la richiesta di invio del codice OTP.",
                                'error'
                            )
                        })
                        .catch(error => {
                            Swal.fire(
                                "Errore imprevisto",
                                "Si è verificato un errore imprevisto durante la richiesta di invio del codice OTP.",
                                'error'
                            )
                        });
                });
            },
            error => {
                Swal.fire(
                    "Errore",
                    "Si è verificato un errore durante il caricamento del tuo documento.",
                    'error'
                )
            }
        )
            .catch(error => {
                Swal.fire(
                    "Errore imprevisto",
                    "Si è verificato un errore imprevisto durante il caricamento del tuo documento.",
                    'error'
                )
            });

        eventBus.dispatch("hideLoader");
    }

    render() {
        const { channels, sendOtp, type } = this.state;

        return <div className="signature-box">
            <div id="document"></div>
            {channels.length ? (
                <div className={"otp " + type}>
                    <div className="container getdocumentsign">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="card-title">
                                    <strong>
                                        {!sendOtp ? 'RICEVI CODICE OTP SU' : 'INSERISCI IL CODICE OTP'}
                                    </strong>
                                </h3>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                {!sendOtp ? <select className="select2-hidden-accessible" id="channel" name="channel" data-select2-id="1" tabIndex={-1} aria-hidden="true">
                                    {channels.map((channel, index) => {
                                        return <option key={index} value={channel}>{channel}</option>
                                    })}
                                </select> : <input type="text" id="otp" name="otp" />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" id={!sendOtp ? 'getdocumentsign' : 'sendotp'} name={!sendOtp ? 'getdocumentsign' : 'sendotp'} className="btn btn-info">{!sendOtp ? 'Richiedi' : 'Invia'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <br />
        </div>
    }
}
