import { AxiosResponse } from 'axios';
import { IDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-dispositivi';
import axios from '../../api';
const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoDispositiviService {

    //* se ci sono chiamate che restituiscono 200 ma non hanno dati, metti controllo su error

    getAll(): Promise<IDispositivo[]> {
        return axios.get<IDispositivo[]>(API_URL + 'magazzino/dispositivi')
            .then((response: AxiosResponse<IDispositivo[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .catch((error) => { return Promise.reject(); });
    }

    getOne(id: string): Promise<IDispositivo> {
        return axios.get<IDispositivo>(API_URL + 'magazzino/dispositivi/' + id)
            .then((response: AxiosResponse<IDispositivo>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    add(body: FormData): Promise<IDispositivo> {
        return axios.post<FormData, AxiosResponse<IDispositivo>>(API_URL + 'magazzino/dispositivi', body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<IDispositivo>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

    edit(id: string, body: FormData): Promise<IDispositivo> {
        return axios.put<FormData, AxiosResponse<IDispositivo>>(API_URL + 'magazzino/dispositivi/' + id, body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<IDispositivo>) => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

}

export default new MagazzinoDispositiviService();